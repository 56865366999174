import { type Season } from 'types/sportsdata';

export const WNBA_SEASONS: Season[] = [];

export const CURRENT_WNBA_FUTURES_SEASON: Season = {
  seasonId: '2024',
  seasonYear: '2024',
  seasonEndDate: '2024-10-21T00:00:00Z',
  label: 'WNBA 2024 Season',
  league: 'wnba',
};
