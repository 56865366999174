import React, { useEffect, useState } from 'react';

import { Input } from 'components/Input';
import { Spinner } from 'components/Spinner';

interface DropdownProps<T> {
  accessibilityLabel?: string;
  placeholder: string;
  items: T[];
  value?: T;
  renderItem: (item: T) => JSX.Element;
  onItemSelect: (item?: T) => void;
  // Settings
  clearDisabled?: boolean;
  loading?: boolean;
  showItemsOnClick?: boolean;
  // Custom Behavior
  filterFn?: (items: T[], search: string) => T[];
  setItemLabel?: (item: T) => string;
  unlimitedList?: boolean;
  onFocus?: () => void;
}

const LIST_MAX = 30;

const startsWithFilter = <T,>(items: T[], search: string) => {
  const filter = items?.filter((item) => {
    const lowerCaseStringList = JSON.stringify(item).toLowerCase().split(' ');
    return lowerCaseStringList.some((lowerWord) => {
      lowerWord.startsWith(search.toLowerCase());
    });
  });
  return filter;
};

const genericLabel = <T,>(item: T) => {
  return JSON.stringify(item);
};

// <T,> required due to JSX syntax
export const Dropdown = <T,>({
  accessibilityLabel,
  placeholder,
  items,
  value,
  renderItem,
  onItemSelect,
  loading,
  showItemsOnClick,
  filterFn = startsWithFilter,
  setItemLabel = genericLabel,
  unlimitedList = false,
}: DropdownProps<T>) => {
  const [editable, setEditable] = useState(true);
  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState<T[]>([]);
  const [dontBlur, setDontBlur] = useState(false);

  useEffect(() => {
    if (search) {
      const filter = filterFn(items, search);
      setFilteredItems(filter);
    } else {
      if (showItemsOnClick) {
        setFilteredItems(unlimitedList ? items : items.slice(0, LIST_MAX));
      } else {
        setFilteredItems([]);
      }
    }
  }, [search, items]);

  useEffect(() => {
    if (value) {
      setEditable(false);
      setSearch(setItemLabel(value));
    } else {
      setEditable(true);
      setSearch('');
    }
  }, [value]);

  const onClear = () => {
    setSearch('');
    onItemSelect(undefined);
  };

  return (
    <div>
      <div>
        <Input
          id="dropdown-input"
          accessibilityLabel={accessibilityLabel || ''}
          value={search}
          handleOnChange={setSearch}
          handleOnFocus={() => setFocused(true)}
          disabled={!editable}
          handleOnBlur={() => !dontBlur && setFocused(false)}
          clearable={true}
          onClear={onClear}
          placeholder={placeholder}
        />
        {loading ? (
          <Spinner />
        ) : (
          focused &&
          editable && (
            <div
              style={{ overflowY: 'scroll' }}
              // Necessary so that we can hide drop down by clicking away but still click the options
              onMouseEnter={() => setDontBlur(true)}
              onMouseLeave={() => setDontBlur(false)}
            >
              {filteredItems.map((item, index) => (
                <div key={index} onClick={() => onItemSelect(item)}>
                  {renderItem(item)}
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};
