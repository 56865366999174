import { type PoolStatusOption } from 'types/pools';

export const POOL_STATUS_OPTIONS: PoolStatusOption[] = [
  {
    label: 'Open',
    value: { id: 'OPEN' },
  },
  {
    label: 'Inactive',
    value: { id: 'INACTIVE' },
  },
  {
    label: 'Closed',
    value: { id: 'CLOSED' },
  },
  {
    label: 'Finished',
    value: { id: 'FINISHED' },
  },
  {
    label: 'Hidden Open',
    value: { id: 'HIDDENOPEN' },
  },
];
