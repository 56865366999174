import { type Dispatch, type SetStateAction, type SyntheticEvent } from 'react';

import '../../style.css';
import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { formatPhoneNumber } from 'utils/phone';

type PhoneInputFormProps = {
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  handleSubmit: (event: SyntheticEvent) => Promise<void>;
};

export const PhoneInputForm = ({
  phoneNumber,
  setPhoneNumber,
  handleSubmit,
}: PhoneInputFormProps) => {
  return (
    <VStack w="100%" gap="30px">
      <form id="phone-number-input-form" onSubmit={handleSubmit}>
        <label>
          <Text variant="eyebrow">Phone Number:</Text>
          <InputGroup>
            <InputLeftAddon bg="neutral.500" color="text.primary">
              {`+1`}
            </InputLeftAddon>
            <Input
              id="phone-number-input"
              value={phoneNumber}
              onChange={(event) =>
                setPhoneNumber(formatPhoneNumber(event.target.value))
              }
              maxLength={12}
            />
          </InputGroup>
        </label>
      </form>
      <Button
        type="submit"
        form="phone-number-input-form"
        isDisabled={!isPossiblePhoneNumber(phoneNumber, 'US')}
        id="sign-in-button"
        variant="primary"
      >
        Send Code
      </Button>
    </VStack>
  );
};
