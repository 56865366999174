import { useQuery } from '@tanstack/react-query';
import { getPoolParticipantAvg } from 'api/pools';

type PoolParticipantAvgResponse = {
  avgParticipants: number;
};

export default function usePoolParticipantAvg(userId?: string) {
  const queryResponse = useQuery<PoolParticipantAvgResponse>({
    queryKey: ['poolParticipantAvg', userId],
    queryFn: async () => await getPoolParticipantAvg(userId || ''),
    enabled: !!userId,
  });

  if (queryResponse.isError) {
    console.error('Error getting pool participant avg: ', queryResponse.error);
  }

  return {
    participantAvg: queryResponse.data,
    participantAvgLoading: queryResponse.isLoading,
    participantAvgError: queryResponse.isError,
    refetchParticipantAvg: queryResponse.refetch,
    isFetchingParticipantAvg: queryResponse.isFetching,
  };
}
