import { useQuery } from '@tanstack/react-query';
import { getTournamentDetails } from 'api/tournaments';
import { type TournamentDetailsSummary } from 'types/tournaments';

export default function useTournamentDetails(tournamentIdentifier: string) {
  const queryResponse = useQuery<TournamentDetailsSummary>({
    queryKey: ['tournament', tournamentIdentifier],
    queryFn: async () => await getTournamentDetails(tournamentIdentifier),
  });

  if (queryResponse.isError) {
    console.error('Error getting tournament details: ', queryResponse.error);
  }

  return {
    tournamentResponse: queryResponse.data,
    tournamentLoading: queryResponse.isLoading,
    tournamentError: queryResponse.isError,
    refetchTournament: queryResponse.refetch,
    isFetchingTournament: queryResponse.isFetching,
  };
}
