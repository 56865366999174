import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getChallengeAllStats } from 'api/challenge';
import { type ChallengeAllStatsResponse } from 'types/challengeAll';

export default function useChallengeAllStats(userId?: string) {
  const queryResponse = useQuery<ChallengeAllStatsResponse>({
    queryKey: ['challengeAllStats'],
    queryFn: async () => await getChallengeAllStats(userId),
    placeholderData: keepPreviousData,
  });

  if (queryResponse.isError) {
    console.error('Error getting challenge all stats: ', queryResponse.error);
  }

  return {
    stats: queryResponse.data,
    statsLoading: queryResponse.isLoading,
    statsError: queryResponse.isError,
    refetchStats: queryResponse.refetch,
    isFetching: queryResponse.isFetching,
  };
}
