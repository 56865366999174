import React from 'react';

import { Button } from 'components/Button';
import { type HotTake } from 'types/hotTakes';

type ConfirmDeactivateProps = {
  setHotTakeToDeactivate: React.Dispatch<
    React.SetStateAction<HotTake | undefined>
  >;
  deleteHotTake: () => void;
  setDeactivatingHotTake: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ConfirmDeactivate = ({
  setHotTakeToDeactivate,
  deleteHotTake,
  setDeactivatingHotTake,
}: ConfirmDeactivateProps) => {
  return (
    <div className="container">
      <div className="modal">
        <h2>Are you sure you want to deactivate this hot take?</h2>
        <Button
          accessibilityLabel="deactivate-hot-take-confirm-button"
          type="primary"
          text="Deactivate Hot Take"
          handleOnClick={() => {
            deleteHotTake();
            setHotTakeToDeactivate(undefined);
            setDeactivatingHotTake(false);
          }}
        />
        <Button
          accessibilityLabel="deactivate-hot-take-cancel-button"
          type="secondary"
          handleOnClick={() => {
            setHotTakeToDeactivate(undefined);
            setDeactivatingHotTake(false);
          }}
          text="Cancel"
        />
      </div>
    </div>
  );
};
