import React, { useEffect, useState } from 'react';

import { Input } from 'components/Input';
import { type WizardStepProps } from 'screens/AddPrediction/types';

export const ValueSelector = ({
  predContent,
  setPredContent,
}: WizardStepProps) => {
  // Manage Prediction Content State
  const [value, setValue] = useState<string>(predContent.value || '');

  useEffect(() => {
    setPredContent({
      ...predContent,
      value,
    });
  }, [value]);

  return (
    <div className="form-dropdown-container">
      <p>Enter Value</p>
      <Input
        id="Value Field"
        value={value == '.' ? '0.' : value}
        placeholder={'Enter value amount (should end in .5)'}
        handleOnChange={setValue}
        accessibilityLabel="Value Field"
      />
    </div>
  );
};
