import React, { useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  Input,
  List,
  ListItem,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import useDebounce from 'hooks/useDebounce';
import useSearchUsers from 'hooks/useUsernames';
import { type UserSummary } from 'types/user';

export type UserSearchProps = {
  multiSelect?: boolean;
  handleOnClick?: (users: UserSummary[]) => void;
};

export const UserSearch: React.FC<UserSearchProps> = ({
  multiSelect,
  handleOnClick,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<UserSummary[]>([]);
  const [query, setQuery] = useState<string>('');

  const debounceDelay = 500;
  const debouncedSearchQuery = useDebounce(query, debounceDelay);

  useEffect(() => {
    if (handleOnClick && selectedUsers.length > 0) {
      handleOnClick(selectedUsers);
    }
  }, [selectedUsers, handleOnClick]);

  const handleSelectUser = (user: UserSummary) => {
    const previouslySelectedUsers = selectedUsers;
    const userExists = previouslySelectedUsers.find(
      (u) => u.userId === user.userId
    );
    let newSelectedUsers;
    if (multiSelect) {
      newSelectedUsers = userExists
        ? previouslySelectedUsers.filter((u) => u.userId !== user.userId)
        : [...previouslySelectedUsers, user];
    } else {
      newSelectedUsers = userExists ? [] : [user];
    }
    setSelectedUsers(newSelectedUsers);
  };

  const { searchUserResults, searchUsersLoading } =
    useSearchUsers(debouncedSearchQuery);

  return (
    <Box width="100%" mx="auto" mt="10">
      <VStack spacing={4}>
        <Input
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          width={'100%'}
        />
        {searchUsersLoading && query && <Spinner size="xl" mt="4" />}
        <List spacing={3} mt="4" overflowY={'scroll'}>
          {searchUserResults &&
            searchUserResults.map((group) =>
              (group.users || []).map((user) => (
                <ListItem
                  key={user.userId}
                  borderWidth="1px"
                  borderRadius="md"
                  p="4"
                  textAlign="left"
                >
                  <Checkbox
                    isChecked={
                      selectedUsers.find((u) => u.userId === user.userId)
                        ? true
                        : false
                    }
                    onChange={() => handleSelectUser(user)}
                  >
                    <p>@({user.username})</p>
                  </Checkbox>
                </ListItem>
              ))
            )}
        </List>
      </VStack>
    </Box>
  );
};

export default UserSearch;
