import { type MetricOption } from 'types/addPrediction';

export const CBB_PLAYER_FUTURES: MetricOption[] = [
  // REGULAR SEASON VALUES
  {
    value: 'ApPlayerOfTheYear',
    label: 'AP Player of the Year',
  },
  {
    value: 'ConferencePlayerOfTheYear',
    label: 'Conference Player of the Year',
  },
  {
    value: 'FirstTeamAllConferenceSelection',
    label: 'First Team All-Conference selection',
  },
  {
    value: 'SecondTeamAllConferenceSelection',
    label: 'Second Team All-Conference selection',
  },
  {
    value: 'FirstTeamAllAmericanSelection',
    label: 'First Team All-American selection',
  },
  {
    value: 'SecondTeamAllAmericanSelection',
    label: 'Second Team All American selection',
  },
  // NCAA TOURNAMENT VALUES
  {
    value: 'MostPointsNcaaTournament',
    label: 'Most Points Scored in the NCAA Tournament',
  },
  {
    value: 'MostAssistsNcaaTournament',
    label: 'Most Assists in the NCAA Tournament',
  },
  {
    value: 'MostReboundsNcaaTournament',
    label: 'Most Rebounds in the NCAA Tournament',
  },
  {
    value: 'MostBlocksNcaaTournament',
    label: 'Most Blocks in the NCAA Tournament',
  },
  {
    value: 'MostStealsNcaaTournament',
    label: 'Most Steals in the NCAA Tournament',
  },
];
