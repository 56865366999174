import { useQuery } from '@tanstack/react-query';

import { getUserMetrics } from 'api/userMetrics';
import { type UserMetrics } from 'types/userMetrics';

export default function useUserMetrics() {
  const queryResponse = useQuery<UserMetrics>({
    queryKey: ['userMetrics'],
    queryFn: async () => await getUserMetrics(),
  });

  if (queryResponse.isError) {
    console.error('Error getting user metrics: ', queryResponse.error);
  }

  return {
    userMetrics: queryResponse.data,
    userMetricsLoading: queryResponse.isLoading,
    userMetricsError: queryResponse.isError,
    refetchUserMetrics: queryResponse.refetch,
    isFetchingUserMetrics: queryResponse.isFetching,
  };
}
