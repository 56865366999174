import React, { useCallback, useEffect, useState } from 'react';

import { Dropdown } from 'components/AddPredictionForm/components/Dropdown';
import { renderEntityItem } from 'components/AddPredictionForm/components/RenderItems';
import usePlayers from 'hooks/usePlayers';
import useTeams from 'hooks/useTeams';
import {
  type PredInfo,
  type WizardStepProps,
} from 'screens/AddPrediction/types';
import { type Entity, type Player, type Team } from 'types/sportsdata';
import { filterEntities, setEntityLabel } from 'utils/addPred';

// TODO [#79]: Refactor AddPredictionForm to use Wizard

export const EntitySelector = ({
  predContent,
  setPredContent,
}: WizardStepProps) => {
  // Manage Prediction Content State
  const [entity, setEntity] = useState<Entity | undefined>(predContent.entity);
  const [teams, setTeams] = useState<Team[]>([]);

  useEffect(() => {
    const newPred: PredInfo = {
      ...predContent,
    };

    // Clear potentially unmatched attributes
    if (entity !== predContent.entity) {
      delete newPred['metric'];
      delete newPred['value'];
      delete newPred['game'];
      delete newPred['season'];
    }

    setPredContent({
      ...newPred,
      entity,
      leagueTeams: teams,
    });
  }, [entity, teams]);

  // Manage Selector Options
  // Load Players for each league
  const { players: nbaPlayers, playersLoading: nbaPlayersLoading } =
    usePlayers('nba');
  const { players: cbbPlayers, playersLoading: cbbPlayersLoading } =
    usePlayers('cbb');
  const { players: mlbPlayers, playersLoading: mlbPlayersLoading } =
    usePlayers('mlb');
  const { players: nflPlayers, playersLoading: nflPlayersLoading } =
    usePlayers('nfl');
  const { players: nhlPlayers, playersLoading: nhlPlayersLoading } =
    usePlayers('nhl');
  // const { players: cfbPlayers, playersLoading: cfbPlayersLoading } = usePlayers('cfb');

  // Load Teams for each league
  const { teams: nbaTeams, teamsLoading: nbaTeamsLoading } = useTeams('nba');
  const { teams: cbbTeams, teamsLoading: cbbTeamsLoading } = useTeams('cbb');
  const { teams: mlbTeams, teamsLoading: mlbTeamsLoading } = useTeams('mlb');
  const { teams: nflTeams, teamsLoading: nflTeamsLoading } = useTeams('nfl');
  const { teams: cfbTeams, teamsLoading: cfbTeamsLoading } = useTeams('cfb');
  const { teams: wnbaTeams, teamsLoading: wnbaTeamsLoading } = useTeams('wnba');
  const { teams: cwbbTeams, teamsLoading: cwbbTeamsLoading } = useTeams('cwbb');
  const { teams: nhlTeams, teamsLoading: nhlTeamsLoading } = useTeams('nhl');

  const [entityArray, setEntityArray] = useState<Entity[]>([]);

  const entitiesLoading = useCallback(() => {
    const playersLoading =
      nbaPlayersLoading ||
      cbbPlayersLoading ||
      mlbPlayersLoading ||
      nflPlayersLoading ||
      nhlPlayersLoading;
    const teamsLoading =
      nbaTeamsLoading ||
      mlbTeamsLoading ||
      nflTeamsLoading ||
      cbbTeamsLoading ||
      cfbTeamsLoading ||
      wnbaTeamsLoading ||
      cwbbTeamsLoading ||
      nhlTeamsLoading;
    return playersLoading || teamsLoading;
  }, [
    nbaPlayersLoading,
    cbbPlayersLoading,
    mlbPlayersLoading,
    nflPlayersLoading,
    // cfbPlayersLoading,
    nhlPlayersLoading,
    nbaTeamsLoading,
    mlbTeamsLoading,
    nflTeamsLoading,
    cbbTeamsLoading,
    cfbTeamsLoading,
    cwbbTeamsLoading,
    wnbaTeamsLoading,
    nhlTeamsLoading,
  ]);

  useEffect(() => {
    let playersArray: Player[] = [];
    let teamsArray: Team[] = [];

    switch (predContent.league) {
      case 'mlb':
        playersArray = mlbPlayers || [];
        teamsArray = mlbTeams || [];
        break;
      case 'nba':
        playersArray = nbaPlayers || [];
        teamsArray = nbaTeams || [];
        break;
      case 'nfl':
        playersArray = nflPlayers || [];
        teamsArray = nflTeams || [];
        break;
      case 'cbb':
        playersArray = cbbPlayers || [];
        teamsArray = cbbTeams || [];
        break;
      case 'cfb':
        teamsArray = cfbTeams || [];
        // playersArray = cfbPlayers || [];
        break;
      case 'wnba':
        teamsArray = wnbaTeams || [];
        break;
      case 'cwbb':
        teamsArray = cwbbTeams || [];
        break;
      case 'nhl':
        playersArray = nhlPlayers || [];
        teamsArray = nhlTeams || [];
        break;
    }
    setTeams(teamsArray);
    setEntityArray(() => [...playersArray, ...teamsArray]);
  }, [
    nflPlayers,
    nbaPlayers,
    cbbPlayers,
    mlbPlayers,
    // cfbPlayers,
    nhlPlayers,
    nflTeams,
    nbaTeams,
    mlbTeams,
    cbbTeams,
    cfbTeams,
    wnbaTeams,
    cwbbTeams,
    nhlTeams,
    predContent.league,
  ]);

  return (
    <div className="form-dropdown-container">
      <p>Select Player or Team</p>
      <Dropdown
        accessibilityLabel="Entity Picker"
        placeholder="Select Player or Team"
        items={entityArray}
        value={entity}
        renderItem={renderEntityItem}
        onItemSelect={setEntity}
        filterFn={filterEntities}
        setItemLabel={setEntityLabel}
        clearDisabled={false}
        loading={entitiesLoading()}
        showItemsOnClick={false}
      />
    </div>
  );
};
