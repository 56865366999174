import { type Season } from 'types/sportsdata';

export const MLB_SEASONS: Season[] = [
  {
    seasonId: '2024REG',
    seasonYear: '2024',
    seasonEndDate: '2024-09-30T00:00:00Z',
    label: '2024 Regular Season',
    league: 'mlb',
  },
  {
    seasonId: '2024POST',
    seasonYear: '2024',
    seasonEndDate: '2024-11-04T00:00:00Z',
    label: '2024 Post Season',
    league: 'mlb',
  },
];

export const CURRENT_MLB_FUTURES_SEASON: Season = {
  seasonId: '2024',
  seasonYear: '2024',
  seasonEndDate: '2024-11-04T00:00:00Z',
  label: 'MLB 2024 Season',
  league: 'mlb',
};
