import React from 'react';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

type DateInputProps = {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  disabled?: boolean;
  showTime?: boolean;
};

// TODO[#10]: fix timezone issues
export const DateInput = ({
  date,
  setDate,
  disabled = false,
  showTime = false,
}: DateInputProps) => {
  return (
    <div>
      <DatePicker
        showTimeInput={showTime}
        selected={date}
        onChange={(date: Date | null) => {
          if (date) {
            setDate(date);
          }
        }}
        disabled={disabled}
      />
    </div>
  );
};
