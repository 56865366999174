import { useEffect, useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { TournamentPreviewCard } from './components/TournamentPreviewCard';
import { TOURNAMENT_STATUS_OPTIONS } from './constants/options';
import { Button } from 'components/Button';
import { FullScreenSpinner } from 'components/Spinner';
import useTournaments from 'hooks/useTournaments';

export const Tournaments = () => {
  // defaults to show current Open tournaments first
  const [tournamentStatus, setTournamentStatus] = useState(
    TOURNAMENT_STATUS_OPTIONS[0]
  );

  const navigate = useNavigate();
  const {
    tournaments,
    tournamentsLoading,
    tournamentsError,
    refetchTournaments,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useTournaments(tournamentStatus.value.id);

  useEffect(() => {
    refetchTournaments();
  }, [tournamentStatus.value.id]);

  if (tournamentsLoading || isFetching || isFetchingNextPage) {
    return <FullScreenSpinner />;
  }

  if (!tournamentsError) {
    return (
      <div className="view-tournaments-container">
        <div style={{ display: 'flex' }}>
          <Button
            accessibilityLabel="add-tournament-button"
            type="primary"
            text="Add Tournament"
            handleOnClick={() => navigate('/addTournament')}
          />
        </div>
        <h2 className="view-tournaments-text">View Tournaments</h2>
        <div>
          <label>
            <input
              type="radio"
              value={TOURNAMENT_STATUS_OPTIONS[0].label}
              checked={tournamentStatus == TOURNAMENT_STATUS_OPTIONS[0]}
              onChange={() => {
                setTournamentStatus(TOURNAMENT_STATUS_OPTIONS[0]);
              }}
            />
            Open Tournaments
          </label>
          <label>
            <input
              type="radio"
              value={TOURNAMENT_STATUS_OPTIONS[1].label}
              checked={tournamentStatus == TOURNAMENT_STATUS_OPTIONS[1]}
              onChange={() => {
                setTournamentStatus(TOURNAMENT_STATUS_OPTIONS[1]);
              }}
            />
            Upcoming Tournaments
          </label>
          <label>
            <input
              type="radio"
              value={TOURNAMENT_STATUS_OPTIONS[2].label}
              checked={tournamentStatus == TOURNAMENT_STATUS_OPTIONS[2]}
              onChange={() => {
                setTournamentStatus(TOURNAMENT_STATUS_OPTIONS[2]);
              }}
            />
            Closed Tournaments
          </label>
          <label>
            <input
              type="radio"
              value={TOURNAMENT_STATUS_OPTIONS[3].label}
              checked={tournamentStatus == TOURNAMENT_STATUS_OPTIONS[3]}
              onChange={() => {
                setTournamentStatus(TOURNAMENT_STATUS_OPTIONS[3]);
              }}
            />
            Finished Tournaments
          </label>
          <label>
            <input
              type="radio"
              value={TOURNAMENT_STATUS_OPTIONS[4].label}
              checked={tournamentStatus == TOURNAMENT_STATUS_OPTIONS[4]}
              onChange={() => {
                setTournamentStatus(TOURNAMENT_STATUS_OPTIONS[4]);
              }}
            />
            Hidden Open Tournaments
          </label>
        </div>
        {tournaments?.map((group, index) => (
          <div key={index}>
            {group?.tournaments.length == 0 ? (
              <div> No Tournaments to display.</div>
            ) : (
              group?.tournaments.map((tournament) => (
                <TournamentPreviewCard
                  key={tournament.tournamentId}
                  tournament={tournament}
                />
              ))
            )}
          </div>
        ))}
        {hasNextPage && (
          <Button
            accessibilityLabel="load more tournaments"
            type="primary"
            text="Load More"
            handleOnClick={fetchNextPage}
          />
        )}
      </div>
    );
  } else {
    return <p>Error Loading Tournaments.</p>;
  }
};

export default Tournaments;
