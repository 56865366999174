import axios, { type AxiosError } from 'axios';
import { getApiUrls } from 'config/api';

const { ADMIN_API_URL } = getApiUrls();

export const checkAdmin = async (userId: string) => {
  try {
    const { data } = await axios.get(`${ADMIN_API_URL}/admin/user/${userId}`);
    return data.object;
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response?.status == 403) {
      return axiosErr.response.data;
    }
    console.error('Unexpected error checking admin: ', err);
  }
};
