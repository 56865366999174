import {
  Spinner as ChakraSpinner,
  Flex,
  type SpinnerProps,
} from '@chakra-ui/react';

export const Spinner: React.FC<SpinnerProps> = (props) => (
  <ChakraSpinner
    size="xl"
    thickness="5px"
    speed="0.65s"
    color="accent.100"
    {...props}
  />
);

export const FullScreenSpinner: React.FC<SpinnerProps> = (props) => (
  <Flex h="full" w="full" align="center" justify="center">
    <Spinner {...props} />
  </Flex>
);
