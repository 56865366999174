import React, { useCallback, useEffect, useState } from 'react';

import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  Spacer,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { createCommunity } from 'api/community';
import { ImageUploadType } from 'api/image';
import { UserSearch } from 'chakraComponents/UserSearch';
import { Dropdown } from 'components/AddPredictionForm/components/Dropdown';
import { renderEntityItem } from 'components/AddPredictionForm/components/RenderItems';
import { Button } from 'components/Button';
import { LEAGUE_OPTIONS } from 'constants/options';
import { useAuth } from 'contexts/AuthContext';
import { useUploadImageToS3 } from 'hooks/useImageUrl';
import useTeams from 'hooks/useTeams';
import { LeagueSelector } from 'screens/AddPrediction/components/steps/LeagueSelector';
import { type LeagueOption } from 'types/addPrediction';
import {
  type CommunityPrivacy,
  type CommunityTagTeamInfo,
  type CreateCommunityBody,
} from 'types/community';
import { type Team } from 'types/sportsdata';
import { type UserSummary } from 'types/user';
import { filterTeams, setEntityLabel } from 'utils/addPred';
import { isCollegeTeam } from 'utils/teams';

import './style.css';

const CreateCommunity: React.FC = () => {
  const [formData, setFormData] = useState<CreateCommunityBody>({
    authorUserId: '',
    adminUserIds: [''],
    title: '',
    description: '',
    privacy: 'public',
    tags: [],
    invitedUsers: [],
    communityLogo: '',
    verifiedCommunity: false,
  });
  const [teams, setTeams] = useState<Team[]>([]);
  const [league, setLeauge] = useState<LeagueOption>(LEAGUE_OPTIONS[0]);
  const [currentTeam, setCurrentTeam] = useState<Team | undefined>(undefined);
  const [invitedUsers, setInvitedUsers] = useState<UserSummary[] | undefined>(
    undefined
  );
  const [adminUsers, setAdminUsers] = useState<UserSummary[] | undefined>(
    undefined
  );
  const [createCommunityLoading, setCreateCommunityLoading] = useState(false);
  const [communityLogo, setCommunityLogo] = useState<string | undefined>(
    undefined
  );
  const queryClient = useQueryClient();

  // Load Teams for each league
  const { teams: nbaTeams, teamsLoading: nbaTeamsLoading } = useTeams('nba');
  const { teams: cbbTeams, teamsLoading: cbbTeamsLoading } = useTeams('cbb');
  const { teams: mlbTeams, teamsLoading: mlbTeamsLoading } = useTeams('mlb');
  const { teams: nflTeams, teamsLoading: nflTeamsLoading } = useTeams('nfl');
  const { teams: cfbTeams, teamsLoading: cfbTeamsLoading } = useTeams('cfb');
  const { teams: wnbaTeams, teamsLoading: wnbaTeamsLoading } = useTeams('wnba');
  const { teams: cwbbTeams, teamsLoading: cwbbTeamsLoading } = useTeams('cwbb');
  const { teams: nhlTeams, teamsLoading: nhlTeamsLoading } = useTeams('nhl');

  const navigate = useNavigate();
  const signedInUser = useAuth();

  const { handleUploadImage } = useUploadImageToS3({
    userId: signedInUser?.firebaseUser?.uid || '',
    imageUploadType: ImageUploadType.CommunityLogo,
  });

  // Use to indicate if teams are still loading
  const entitiesLoading = useCallback(() => {
    const teamsLoading =
      nbaTeamsLoading ||
      mlbTeamsLoading ||
      nflTeamsLoading ||
      cbbTeamsLoading ||
      cfbTeamsLoading ||
      wnbaTeamsLoading ||
      cwbbTeamsLoading ||
      nhlTeamsLoading;
    return teamsLoading;
  }, [
    nbaTeamsLoading,
    mlbTeamsLoading,
    nflTeamsLoading,
    cbbTeamsLoading,
    cfbTeamsLoading,
    cwbbTeamsLoading,
    wnbaTeamsLoading,
    nhlTeamsLoading,
  ]);

  useEffect(() => {
    let teamsArray: Team[] = [];

    switch (league.value.id) {
      case 'mlb':
        teamsArray = mlbTeams || [];
        break;
      case 'nba':
        teamsArray = nbaTeams || [];
        break;
      case 'nfl':
        teamsArray = nflTeams || [];
        break;
      case 'cbb':
        teamsArray = cbbTeams || [];
        break;
      case 'cfb':
        teamsArray = cfbTeams || [];
        break;
      case 'wnba':
        teamsArray = wnbaTeams || [];
        break;
      case 'cwbb':
        teamsArray = cwbbTeams || [];
        break;
      case 'nhl':
        teamsArray = nhlTeams || [];
        break;
    }
    setTeams(teamsArray);
  }, [
    nflTeams,
    nbaTeams,
    mlbTeams,
    cbbTeams,
    cfbTeams,
    wnbaTeams,
    cwbbTeams,
    nhlTeams,
    league,
  ]);

  const submitEnabled = () => {
    return (
      formData.authorUserId &&
      formData.title &&
      formData.adminUserIds &&
      formData.description &&
      formData.privacy &&
      formData.tags
    );
  };

  const handleSubmit = async () => {
    setCreateCommunityLoading(true);
    let logoKey = '';
    if (communityLogo) {
      const uploadResponse = await handleUploadImage(communityLogo);
      if (!uploadResponse) {
        return; //Error is handled in the hook
      }
      logoKey = uploadResponse.imageKey;
    }
    try {
      await createCommunity({
        ...formData,
        communityLogo: logoKey,
      });
    } catch (error) {
      console.error('Error:', error);
    }
    setCreateCommunityLoading(false);
    queryClient.invalidateQueries({ queryKey: ['getVltedCommunities'] });
    navigate('/communities');
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCommunityLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Flex
      width={'100vw'}
      height={'100vh'}
      alignContent={'center'}
      verticalAlign={'center'}
      justifyContent={'center'}
      overflowY={'scroll'}
    >
      <Stack>
        <Heading>Create Community</Heading>
        <Text>Author User ID:</Text>
        <UserSearch
          handleOnClick={(users) =>
            setFormData({ ...formData, authorUserId: users[0].userId })
          }
        />
        <Text>
          {/* Fix bug of unselect last */}
          Admin User IDs:
        </Text>
        <UserSearch
          multiSelect={true}
          handleOnClick={(users) => {
            setAdminUsers(users);
            setFormData({
              ...formData,
              adminUserIds: users.map((user) => user.userId),
            });
          }}
        />
        <Text>Title</Text>
        <Input
          placeholder="Put title here"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          width={'100%'}
        />
        <Text>Description</Text>
        <Input
          placeholder="Put description here"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          width={'100%'}
        />
        <Text>Select Community Type</Text>
        <Select
          placeholder="Select community type"
          size={'lg'}
          onChange={(e) =>
            setFormData({
              ...formData,
              privacy: e.target.value as CommunityPrivacy,
            })
          }
        >
          <option value="public">Public</option>
          <option value="exclusive">Exclusive</option>
        </Select>
        <Text>Add a team to tag:</Text>
        <LeagueSelector league={league} setLeague={setLeauge} />
        <p>Select a Team to Tag</p>
        <Dropdown
          accessibilityLabel="Entity Picker"
          placeholder="Select Team"
          items={teams}
          value={currentTeam}
          renderItem={renderEntityItem}
          onItemSelect={(team) => {
            setCurrentTeam(team);
          }}
          filterFn={filterTeams}
          setItemLabel={setEntityLabel}
          clearDisabled={false}
          loading={entitiesLoading()}
          showItemsOnClick={false}
        />
        <Button
          accessibilityLabel="add-team-tag-button"
          type="primary"
          text="Add Team Tag"
          handleOnClick={() => {
            if (currentTeam) {
              const communityTag: CommunityTagTeamInfo = {
                league: league.value.id,
                globalTeamID: currentTeam.GlobalTeamID.toString(),
                name: currentTeam.Name,
              };
              if (isCollegeTeam(currentTeam)) {
                communityTag.school = currentTeam.School;
                communityTag.shortDisplayName = currentTeam.ShortDisplayName;
              } else {
                communityTag.city = currentTeam.City;
              }
              setFormData({
                ...formData,
                tags: [...formData.tags, communityTag],
              });
              setCurrentTeam(undefined);
            }
          }}
        />
        <Text>Invite Users:</Text>
        <UserSearch
          multiSelect={true}
          handleOnClick={(users) => {
            setInvitedUsers(users);
            setFormData({
              ...formData,
              invitedUsers: users.map((user) => user.userId),
            });
          }}
        />
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="verifiedCommunity" mb="0">
            Verified Community:
          </FormLabel>
          <Switch
            id="verifiedCommunity"
            isChecked={formData.verifiedCommunity}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, verifiedCommunity: e.target.checked });
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Community Logo</FormLabel>
          <Input type="file" accept="image/*" onChange={handleImageUpload} />
          {communityLogo && (
            <Image src={communityLogo} alt="Community Logo" mt={4} />
          )}
        </FormControl>
        <Spacer />
        <Heading>Community Preview</Heading>
        <Stack>
          <Text>Author User ID: {formData.authorUserId}</Text>
          <Text>
            Admin User IDs:{' '}
            {adminUsers?.map((user) => user.username).join(', ')}
          </Text>
          <Text>Title: {formData.title}</Text>
          <Text>Description: {formData.description}</Text>
          <Text>Privacy: {formData.privacy}</Text>
          <Text>Tags: {formData.tags.map((tag) => tag.name).join(', ')}</Text>
          <Text>
            Invited Users:{' '}
            {invitedUsers?.map((user) => user.username).join(', ')}
          </Text>
          <Text>
            Verified Community: {formData.verifiedCommunity ? 'Yes' : 'No'}
          </Text>
        </Stack>
        <Spacer />
        <Button
          accessibilityLabel="submit-community-button"
          type="primary"
          text="Submit"
          handleOnClick={handleSubmit}
          disabled={!submitEnabled()}
          loading={createCommunityLoading}
        />
      </Stack>
    </Flex>
  );
};

export default CreateCommunity;
