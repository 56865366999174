import { type MetricOption } from 'types/addPrediction';

export const CWBB_TEAM_FUTURES: MetricOption[] = [
  {
    value: 'NcaaTournamentRound64',
    label: 'Reach the Round of 64',
  },
  {
    value: 'NcaaTournamentRound32',
    label: 'Reach the Round of 32',
  },
  {
    value: 'NcaaTournamentRound16',
    label: 'Reach the Sweet 16',
  },
  {
    value: 'NcaaTournamentRound8',
    label: 'Reach the Elite 8',
  },
  {
    value: 'NcaaTournamentRound4',
    label: 'Reach the Final 4',
  },
  {
    value: 'NcaaTournamentFinals',
    label: 'Reach the NCAA Finals',
  },
  {
    value: 'NcaaTournamentChampion',
    label: 'NCAA Tournament Champion',
  },
];
