import React, { useRef } from 'react';

import {
  type CreateComparisonPrediction,
  type Future,
} from 'components/AddPredictionForm/types';
import { Button } from 'components/Button';
import { PredictionString } from 'components/PredictionString';
import { type PredictionGrouping, type SeasonDetails } from 'types/prediction';
import { type League } from 'types/sportsdata';

type ModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  submitPrediction: () => void;
  setPlacingPrediction: React.Dispatch<React.SetStateAction<boolean>>;
  grouping: PredictionGrouping;
  entity?: string;
  metric?: string;
  comparison?: string;
  value?: string;
  date?: Date;
  season?: SeasonDetails;
  league: League;
  future?: Future;
  createComparisonPrediction?: CreateComparisonPrediction;
  // comparisonPrediction?: ComparisonPrediction;
};

export const Modal = ({
  setShowModal,
  submitPrediction,
  setPlacingPrediction,
  grouping,
  entity,
  metric,
  comparison,
  value,
  date,
  season,
  league,
  future,
  createComparisonPrediction,
}: ModalProps) => {
  // close the modal when clicking outside the modal.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const closeModal = (e: any) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  return (
    <div className="container" ref={modalRef} onClick={closeModal}>
      <div className="modal">
        <h2>Hot Take Preview</h2>
        <div>
          <PredictionString
            grouping={grouping}
            entity={entity}
            metric={metric}
            comparison={comparison}
            value={value}
            date={date}
            season={season}
            league={league}
            future={future}
            createComparisonPrediction={createComparisonPrediction}
          />
        </div>
        <Button
          accessibilityLabel="place hot take button"
          type="primary"
          text="Place Hot Take"
          handleOnClick={() => {
            submitPrediction();
            setShowModal(false);
            setPlacingPrediction(false);
          }}
        />
        <Button
          accessibilityLabel="cancel hot take button"
          type="secondary"
          handleOnClick={() => {
            setShowModal(false);
            setPlacingPrediction(false);
          }}
          text="Cancel"
        />
      </div>
    </div>
  );
};
