import { extendTheme } from '@chakra-ui/react';
import { themeColors } from 'chakra/colors';
import '@fontsource-variable/inter';
import { ButtonTheme } from 'chakra/components/button-theme';
import { InputTheme } from 'chakra/components/input-theme';
import { TableTheme } from 'chakra/components/table-theme';
import { TextTheme } from 'chakra/components/text-theme';

export const chakraTheme = extendTheme({
  colors: themeColors,
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  components: {
    Button: ButtonTheme,
    Input: InputTheme,
    Text: TextTheme,
    Table: TableTheme,
  },
  styles: {
    global: {
      p: {
        color: 'text.primary',
      },
      label: {
        color: 'text.primary',
      },
      div: {
        color: 'text.primary',
      },
    },
  },
});
