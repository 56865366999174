import { type Season } from 'types/sportsdata';

export const CBB_SEASONS: Season[] = [
  {
    seasonId: '2025PRE',
    seasonYear: '2025',
    seasonEndDate: '2024-11-04T00:00:00Z',
    label: '2024-2025 Pre Season',
    league: 'cbb',
  },
  {
    seasonId: '2025REG',
    seasonYear: '2025',
    seasonEndDate: '2025-03-18T00:00:00Z',
    label: '2024-2025 Regular Season',
    league: 'cbb',
  },
  {
    seasonId: '2025POST',
    seasonYear: '2025',
    seasonEndDate: '2025-04-09T00:00:00Z',
    label: '2024-2025 Post Season',
    league: 'cbb',
  },
];

export const CURRENT_CBB_FUTURES_SEASON: Season = {
  seasonId: '2025',
  seasonYear: '2025',
  seasonEndDate: '2025-04-09T00:00:00Z',
  label: "NCAA Men's Basketball 2024-2025 Season",
  league: 'cbb',
};
