export const formatPhoneNumber = (number: string) => {
  // Remove all non-digit characters
  const cleaned = ('' + number).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    const formattedNumber = [
      match[1],
      match[2] ? '-' + match[2] : '',
      match[3] ? '-' + match[3] : '',
    ].join('');
    return formattedNumber;
  }

  return number;
};
