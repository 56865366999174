import { type MetricOption } from 'types/addPrediction';

export const CFB_PLAYER_FUTURES: MetricOption[] = [
  {
    value: 'HeismanTrophyWinner',
    label: 'Heisman Trophy Winner',
  },
  {
    value: 'HeismanTrophyFinalist',
    label: 'Heisman Trophy Finalist',
  },
];
