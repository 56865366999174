import { type Dispatch, type SetStateAction } from 'react';

import { type PredInfo } from '../types';
import { getPlaceholderStrings } from '../utils';
import { getPredictionGrouping, standardizeUTCDate } from 'utils/addPred';
import { getEntityString, getSeasonString } from 'utils/prediction';

type WizardStringProps = {
  predictionInfo: PredInfo;
  setStep: Dispatch<SetStateAction<number>>;
  lhsPredInfo: PredInfo;
  rhsPredInfo: PredInfo;
};

export const WizardString = ({
  predictionInfo,
  setStep,
  lhsPredInfo,
  rhsPredInfo,
}: WizardStringProps) => {
  const grouping = getPredictionGrouping(
    predictionInfo.category,
    predictionInfo.duration
  );
  const {
    entityPlaceholder,
    comparisonPlaceholder,
    valuePlaceholder,
    metricPlaceholder,
    durationPlaceholder,
  } = getPlaceholderStrings(grouping);

  // Main Prediction Stirngs
  const entityString = predictionInfo.entity
    ? getEntityString(predictionInfo.entity)
    : entityPlaceholder;
  const comparisonString =
    predictionInfo.comparison?.label || comparisonPlaceholder;
  const valueString = predictionInfo.value || valuePlaceholder;
  const metricString = predictionInfo.metric?.label || metricPlaceholder;
  const gameString = predictionInfo.game
    ? standardizeUTCDate(predictionInfo.game.DateTimeUTC).toLocaleDateString()
    : durationPlaceholder;
  const seasonString = predictionInfo.season
    ? getSeasonString(predictionInfo.season, predictionInfo.league)
    : durationPlaceholder;
  const durationString =
    predictionInfo.duration === 'game' ? gameString : seasonString;

  // LHS Strings
  const lhsGrouping = getPredictionGrouping(
    lhsPredInfo.category,
    lhsPredInfo.duration
  );
  const lhs = getPlaceholderStrings(lhsGrouping);
  const lhsEntityString = lhsPredInfo.entity
    ? getEntityString(lhsPredInfo.entity)
    : lhs.entityPlaceholder;
  const lhsMetricString = lhsPredInfo.metric?.label || lhs.metricPlaceholder;
  const lhsGameString = lhsPredInfo.game
    ? standardizeUTCDate(lhsPredInfo.game.DateTimeUTC).toLocaleDateString()
    : lhs.durationPlaceholder;
  const lhsSeasonString = lhsPredInfo.season
    ? getSeasonString(lhsPredInfo.season, lhsPredInfo.league)
    : lhs.durationPlaceholder;
  const lhsDurationString =
    lhsPredInfo.duration === 'game' ? lhsGameString : lhsSeasonString;

  // RHS Strings
  const rhsGrouping = getPredictionGrouping(
    rhsPredInfo.category,
    rhsPredInfo.duration
  );
  const rhs = getPlaceholderStrings(rhsGrouping);
  const rhsEntityString = rhsPredInfo.entity
    ? getEntityString(rhsPredInfo.entity)
    : rhs.entityPlaceholder;
  const rhsMetricString = rhsPredInfo.metric?.label || rhs.metricPlaceholder;
  const rhsGameString = rhsPredInfo.game
    ? standardizeUTCDate(rhsPredInfo.game.DateTimeUTC).toLocaleDateString()
    : rhs.durationPlaceholder;
  const rhsSeasonString = rhsPredInfo.season
    ? getSeasonString(rhsPredInfo.season, rhsPredInfo.league)
    : rhs.durationPlaceholder;
  const rhsDurationString =
    rhsPredInfo.duration === 'game' ? rhsGameString : rhsSeasonString;

  switch (grouping) {
    case 'GameStatistic':
      return (
        <div className="wizard-string-container">
          <p
            className={
              predictionInfo.entity ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(0)}
          >
            {entityString}
          </p>
          <p
            className={
              predictionInfo.comparison
                ? 'provided-wiz-str'
                : 'undefined-wiz-str'
            }
            onClick={() => setStep(2)}
          >
            {comparisonString}
          </p>
          <p
            className={
              predictionInfo.value ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(3)}
          >
            {valueString}
          </p>
          <p
            className={
              predictionInfo.metric ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(1)}
          >
            {metricString}
          </p>
          <p>during the game taking place on</p>
          <p
            className={
              predictionInfo.game ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(4)}
          >
            {durationString}.
          </p>
        </div>
      );
    case 'SeasonStatistic':
      return (
        <div className="wizard-string-container">
          <p
            className={
              predictionInfo.entity ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(0)}
          >
            {entityString}
          </p>
          <p
            className={
              predictionInfo.comparison
                ? 'provided-wiz-str'
                : 'undefined-wiz-str'
            }
            onClick={() => setStep(2)}
          >
            {comparisonString}
          </p>
          <p
            className={
              predictionInfo.value ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(3)}
          >
            {valueString}
          </p>
          <p
            className={
              predictionInfo.metric ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(1)}
          >
            {metricString}
          </p>
          <p>during the</p>
          <p
            className={
              predictionInfo.season ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(4)}
          >
            {durationString}.
          </p>
        </div>
      );
    case 'GameOutcome':
      return (
        <div className="wizard-string-container">
          <p
            className={
              predictionInfo.entity ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(0)}
          >
            {entityString}
          </p>
          <p>will</p>
          <p
            className={
              predictionInfo.metric ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(1)}
          >
            {metricString}
          </p>
          {metricString == 'Cover' || metricString == 'not Cover' ? (
            <>
              <p
                className={
                  predictionInfo.value
                    ? 'provided-wiz-str'
                    : 'undefined-wiz-str'
                }
                onClick={() => setStep(2)}
              >
                {valueString}
              </p>
              <p>during the game taking place on</p>
              <p
                className={
                  predictionInfo.game ? 'provided-wiz-str' : 'undefined-wiz-str'
                }
                onClick={() => setStep(3)}
              >
                {durationString}.
              </p>
            </>
          ) : (
            <>
              <p>the game taking place on</p>
              <p
                className={
                  predictionInfo.game ? 'provided-wiz-str' : 'undefined-wiz-str'
                }
                onClick={() => setStep(2)}
              >
                {durationString}.
              </p>
            </>
          )}
        </div>
      );
    case 'SeasonOutcome':
      return (
        <div className="wizard-string-container">
          <p
            className={
              predictionInfo.entity ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(0)}
          >
            {entityString}
          </p>
          <p
            className={
              predictionInfo.comparison
                ? 'provided-wiz-str'
                : 'undefined-wiz-str'
            }
            onClick={() => setStep(2)}
          >
            {comparisonString}
          </p>
          <p
            className={
              predictionInfo.value ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(3)}
          >
            {valueString}
          </p>
          <p
            className={
              predictionInfo.metric ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(1)}
          >
            {metricString}s
          </p>
          <p>during the</p>
          <p
            className={
              predictionInfo.season ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(4)}
          >
            {durationString}.
          </p>
        </div>
      );
    case 'SeasonFuture':
      return (
        <div className="wizard-string-container">
          <p
            className={
              predictionInfo.entity ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(0)}
          >
            {entityString}
          </p>
          <p>will achieve</p>
          <p
            className={
              predictionInfo.metric ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(1)}
          >
            {metricString}s
          </p>
          <p>for the</p>
          <p
            className={
              predictionInfo.season ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(2)}
          >
            {durationString}.
          </p>
        </div>
      );
    case 'Comparison':
      return (
        <div className="wizard-string-container">
          <p
            className={
              lhsPredInfo.entity ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(0)}
          >
            {lhsEntityString}
          </p>
          <p
            className={
              lhsPredInfo.metric ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(1)}
          >
            {lhsMetricString}
          </p>
          {lhsPredInfo.duration === 'game' ? (
            <>
              <p>during the game taking place on</p>
              <p
                className={
                  lhsPredInfo.game ? 'provided-wiz-str' : 'undefined-wiz-str'
                }
                onClick={() => setStep(2)}
              >
                {lhsDurationString}
              </p>
            </>
          ) : (
            <>
              <p>during the</p>
              <p
                className={
                  lhsPredInfo.season ? 'provided-wiz-str' : 'undefined-wiz-str'
                }
                onClick={() => setStep(2)}
              >
                {lhsDurationString}
              </p>
            </>
          )}
          <p>will be</p>
          <p
            className={
              predictionInfo.comparison
                ? 'provided-wiz-str'
                : 'undefined-wiz-str'
            }
            onClick={() => setStep(3)}
          >
            {comparisonString}
          </p>
          <p
            className={
              rhsPredInfo.entity ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(4)}
          >
            {rhsEntityString}
          </p>
          <p
            className={
              rhsPredInfo.metric ? 'provided-wiz-str' : 'undefined-wiz-str'
            }
            onClick={() => setStep(5)}
          >
            {rhsMetricString}
          </p>
          {rhsPredInfo.duration === 'game' ? (
            <>
              <p>during the game taking place on</p>
              <p
                className={
                  rhsPredInfo.game ? 'provided-wiz-str' : 'undefined-wiz-str'
                }
                onClick={() => setStep(6)}
              >
                {rhsDurationString}.
              </p>
            </>
          ) : (
            <>
              <p>during the</p>
              <p
                className={
                  rhsPredInfo.season ? 'provided-wiz-str' : 'undefined-wiz-str'
                }
                onClick={() => setStep(6)}
              >
                {rhsDurationString}.
              </p>
            </>
          )}
        </div>
      );
    default:
      return <p>Wizard string not supported. Ask Peter why not.</p>;
  }
};
