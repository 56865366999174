import {
  ComparisonPredictionPreviewString,
  ComparisonPredictionString,
} from './components/ComparisonPredictionStrings';
import {
  type CreateComparisonPrediction,
  type Future,
} from 'components/AddPredictionForm/types';
import {
  type PredictionContent,
  type PredictionGrouping,
  type PredictionType,
  type SeasonDetails,
} from 'types/prediction';
import { type League } from 'types/sportsdata';
import { dateToDateStringLong } from 'utils/date';
import { getSeasonString } from 'utils/prediction';

type PredictionStringProps = {
  grouping: PredictionGrouping;
  entity?: string;
  metric?: string;
  comparison?: string;
  value?: string;
  date?: Date;
  season?: SeasonDetails;
  league: League;
  future?: Future;
  createComparisonPrediction?: CreateComparisonPrediction;
  comparisonPredictionContent?: PredictionContent;
  comparisonPredictionType?: PredictionType;
  negatedPrediction?: boolean;
};

const getSpreadString = (value: string): string => {
  try {
    const numVal = Number(value);
    if (numVal >= 0) {
      return `+${value}`;
    }
  } catch {
    return value;
  }
  return value;
};

// TODO [#464]: Refactor prediction string to take prediction instead of each part of a prediction.
export const PredictionString = ({
  grouping,
  entity,
  metric,
  comparison,
  value,
  date,
  season,
  league,
  negatedPrediction,
  createComparisonPrediction,
  comparisonPredictionContent,
  comparisonPredictionType,
}: PredictionStringProps) => {
  // Grab placeholder values
  let entityString;
  let comparisonString;
  let valueString;
  let metricString;
  let durationString;
  switch (grouping) {
    case 'GameStatistic':
      entityString = 'Player/Team';
      comparisonString = 'comparison';
      valueString = 'value';
      metricString = 'stat';
      durationString = 'date of game';
      break;
    case 'SeasonStatistic':
      entityString = 'Player/Team';
      comparisonString = 'comparison';
      valueString = 'value';
      metricString = 'stat';
      durationString = 'season';
      break;
    case 'GameOutcome':
      entityString = 'Team';
      comparisonString = 'comparison';
      valueString = 'value';
      metricString = 'outcome';
      durationString = 'date of game';
      break;
    case 'SeasonOutcome':
      entityString = 'Team';
      comparisonString = 'comparison';
      valueString = 'value';
      metricString = 'outcome';
      durationString = 'season';
      break;
    case 'SeasonFuture':
      entityString = 'Player/Team';
      metricString = 'future';
      durationString = 'season';
      break;
  }

  // Set provided value styling
  if (entity) {
    entityString = entity;
  }
  if (typeof comparison === 'string') {
    comparisonString = comparison;
  }
  if (value) {
    valueString =
      grouping.includes('Statistic') || grouping == 'SeasonOutcome'
        ? value
        : getSpreadString(value);
  }
  if (metric) {
    metricString = grouping.includes('Future') ? metric : metric.toLowerCase();
  }
  if (date && grouping.includes('Game')) {
    durationString = dateToDateStringLong(date);
  }
  if (season && grouping.includes('Season')) {
    durationString = getSeasonString(season, league);
  }

  let returnElement;
  switch (grouping) {
    case 'GameStatistic':
      returnElement = (
        <div>
          <p>{`${entityString} 
            ${comparisonString}
            ${valueString} 
            ${metricString} 
            during the game taking place on 
            ${durationString}.`}</p>
        </div>
      );
      break;
    case 'SeasonStatistic':
      returnElement = (
        <div>
          <p>
            {`${entityString} 
            ${comparisonString}
            ${valueString}
            ${metricString}
            during the
            ${durationString}.`}
          </p>
        </div>
      );
      break;
    case 'GameOutcome':
      returnElement = (
        <div>
          <p>
            {`${entityString} will
            ${metricString}`}

            {(metricString == 'cover' || metricString == 'not cover') && (
              <>
                {` (${valueString}) `}
                during
              </>
            )}
            {' the game taking place on'}
            {` ${durationString}.`}
          </p>
        </div>
      );
      break;
    case 'SeasonOutcome':
      returnElement = (
        <div>
          <p>
            {`${entityString} 
            ${comparisonString}
            ${valueString}
            ${metricString}
            during the
            ${durationString}.`}
          </p>
        </div>
      );
      break;
    case 'SeasonFuture':
      returnElement = (
        <div>
          <p>
            {`${entityString} `}
            {negatedPrediction ? "won't achieve" : 'will achieve'}
            {` ${metricString} `}
            for the
            {` ${durationString}.`}
          </p>
        </div>
      );
      break;
    case 'Comparison':
      if (createComparisonPrediction) {
        return (
          <ComparisonPredictionPreviewString
            comparisonPrediction={createComparisonPrediction}
            comparison={comparison}
            league={league}
          />
        );
      }
      if (comparisonPredictionContent && comparisonPredictionType) {
        return (
          <ComparisonPredictionString
            comparisonContent={comparisonPredictionContent}
            comparisonType={comparisonPredictionType}
            comparison={comparison}
            league={league}
          />
        );
      }
      // Unsupported comparison type
      return null;
  }
  return returnElement;
};
