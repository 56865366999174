import React, { type CSSProperties, useState } from 'react';

import './style.css';

import {
  MdChatBubble,
  MdLocationCity,
  MdMailOutline,
  MdOutlineCake,
  MdPersonOutline,
  MdPhone,
  MdSearch,
} from 'react-icons/md';
import { Button } from 'components/Button';
import { SurfaceSolid, SurfaceVariant } from 'styles';

type IconOption =
  | 'phone'
  | 'comment'
  | 'email'
  | 'username'
  | 'phone-number'
  | 'zip'
  | 'birthday'
  | 'search';

export type InputProps = {
  id: string;
  accessibilityLabel: string;
  value: string | number;
  handleOnChange?: (input: string) => void;
  handleOnFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  handleOnBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  disabled?: boolean;
  error?: string;
  icon?: IconOption;
  label?: string;
  onClick?: () => void;
  clearable?: boolean;
  onClear?: () => void;
  placeholder?: string;
  containerStyle?: CSSProperties;
};

export const Input = ({
  id,
  accessibilityLabel,
  value,
  handleOnChange,
  handleOnFocus,
  handleOnBlur,
  disabled,
  error,
  icon,
  label,
  onClick,
  clearable = false,
  onClear,
  placeholder = '',
  containerStyle,
}: InputProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <div style={containerStyle ? containerStyle : {}}>
      {/* TODO [#42]: Update CSS approach to avoid inline styling like below */}
      {label && <p className="input-label-text">{label}</p>}
      {/* TODO: add class for this container */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className="input-container"
          style={{
            backgroundColor: focused ? SurfaceVariant.x80 : SurfaceSolid.x50,
            border: error
              ? '1px solid var( --system-error)'
              : '1px solid var( --primary-secondary)',
          }}
        >
          <InputIcon icon={icon} />
          <input
            aria-label={accessibilityLabel}
            autoComplete="off"
            id={id}
            className="input-field"
            type="text"
            value={value}
            onChange={(e) => {
              handleOnChange?.(e.target.value);
            }}
            disabled={disabled}
            onFocus={(event) => {
              setFocused(true);
              handleOnFocus?.(event);
            }}
            onBlur={(event) => {
              setFocused(false);
              handleOnBlur?.(event);
            }}
            onClick={onClick}
            placeholder={placeholder}
          />
        </div>
        {clearable && value != undefined && value != '' ? (
          <div style={{ marginLeft: 8 }}>
            {/* <input type= "button" value= "clear" onClick={() => onClear && onClear()}/> */}
            <Button
              type="secondary"
              accessibilityLabel="clear input"
              text="clear"
              handleOnClick={() => onClear && onClear()}
            />
          </div>
        ) : null}
      </div>
      {error && <p className="input-error-text">{error}</p>}
    </div>
  );
};

type InputIconProps = {
  icon?: IconOption;
};

const InputIcon = ({ icon }: InputIconProps) => {
  switch (icon) {
    case 'phone':
      return (
        <MdPhone
          className="input-icon"
          aria-label="phone-icon"
          size={24}
          color={'#B0A0FF'}
        />
      );
    case 'comment':
      return (
        <MdChatBubble
          className="input-icon"
          aria-label="chat-bubble-icon"
          size={24}
          color={'#B0A0FF'}
        />
      );
    case 'email':
      return (
        <MdMailOutline
          className="input-icon"
          aria-label="mail-outline-icon"
          size={24}
          color={'#B0A0FF'}
        />
      );
    case 'zip':
      return (
        <MdLocationCity
          className="input-icon"
          aria-label="zip-outline-icon"
          size={24}
          color={'#B0A0FF'}
        />
      );
    case 'username':
      return (
        <MdPersonOutline
          className="input-icon"
          aria-label="person-outline-icon"
          size={24}
          color={'#B0A0FF'}
        />
      );
    case 'birthday':
      return (
        <MdOutlineCake
          className="input-icon"
          aria-label="birthday-outline-icon"
          size={24}
          color={'#B0A0FF'}
        />
      );
    case 'search':
      return (
        <MdSearch
          className="input-icon"
          aria-label="search-icon"
          size={24}
          color={'#B0A0FF'}
        />
      );
    default:
      return null;
  }
};
