import {
  keepPreviousData,
  useInfiniteQuery,
  type InfiniteData,
  type QueryKey,
} from '@tanstack/react-query';
import { getTournaments } from 'api/tournaments';
import {
  type TournamentsResponse,
  type TournamentStatus,
} from 'types/tournaments';

export default function useTournaments(tournamentStatus?: TournamentStatus) {
  const queryResponse = useInfiniteQuery<
    TournamentsResponse,
    Error,
    InfiniteData<TournamentsResponse>,
    QueryKey,
    string | undefined
  >({
    queryKey: ['tournaments'],
    queryFn: async ({ pageParam }) =>
      await getTournaments(tournamentStatus, pageParam),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage?.cursor;
    },
    placeholderData: keepPreviousData,
  });

  return {
    tournaments: queryResponse.data?.pages,
    tournamentsLoading: queryResponse.isLoading,
    tournamentsError: queryResponse.isError,
    refetchTournaments: queryResponse.refetch,
    hasNextPage: queryResponse.hasNextPage,
    fetchNextPage: queryResponse.fetchNextPage,
    isFetching: queryResponse.isFetching,
    isFetchingNextPage: queryResponse.isFetchingNextPage,
  };
}
