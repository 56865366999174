import {
  type CollegePlayer,
  type MlbPlayer,
  type NbaPlayer,
  type NflPlayer,
  type NhlPlayer,
  type Player,
  type Team,
} from 'types/sportsdata';

export const isNbaPlayer = (entity: Player): entity is NbaPlayer => {
  return (entity as NbaPlayer).Jersey !== undefined;
};

export const isMlbPlayer = (entity: Player): entity is MlbPlayer => {
  return (entity as MlbPlayer).Jersey !== undefined;
};

export const isNflPlayer = (entity: Player): entity is NflPlayer => {
  return (entity as NflPlayer).Number !== undefined;
};

export const isCollegePlayer = (entity: Player): entity is CollegePlayer => {
  return (entity as CollegePlayer).Class !== undefined;
};

export const isNhlPlayer = (entity: Player): entity is NhlPlayer => {
  return (entity as NhlPlayer).Jersey !== undefined;
};

export const isPlayer = (
  entity: Player | Team | undefined
): entity is Player => {
  if (entity) {
    return (entity as Player).FirstName !== undefined;
  }
  return false;
};
