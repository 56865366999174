import { useQuery } from '@tanstack/react-query';

import { getPlayers } from 'api/sportsdata';
import { type League, type Player } from 'types/sportsdata';

export default function usePlayers(league: League) {
  const queryResponse = useQuery<Player[]>({
    queryKey: ['players', league],
    queryFn: async () => await getPlayers(league),
    // TODO: Add option for timeout cache invalidation
  });

  return {
    players: queryResponse.data,
    playersLoading: queryResponse.isLoading,
    playersError: queryResponse.isError,
  };
}
