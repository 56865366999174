import { type MetricOption } from 'types/addPrediction';

export const CFB_TEAM_FUTURES: MetricOption[] = [
  {
    value: 'PlayoffInvitation',
    label: 'Playoff Invitation',
  },
  {
    value: 'NationalChampion',
    label: 'National Champion',
  },
  {
    value: 'MakeBowlGame',
    label: 'Make Bowl Game',
  },
  {
    value: 'MakeQuarterfinals',
    label: 'Reach the Quarterfinals',
  },
  {
    value: 'MakeSemiFinals',
    label: 'Reach the Semi-Finals',
  },
  {
    value: 'MakeChampionshipGame',
    label: 'Reach the Championship Game',
  },
];
