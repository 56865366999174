import { useQuery } from '@tanstack/react-query';
import { getUsersByTrackingCode } from 'api/user';
import { type UsersWithCodeResults } from 'types/user';

export default function useUsersWithCode(trackingCode: string) {
  const queryResponse = useQuery<UsersWithCodeResults>({
    queryKey: ['usersWithCode', trackingCode],
    queryFn: async () => await getUsersByTrackingCode(trackingCode),
  });

  if (queryResponse.isError) {
    console.error('Error getting users with code: ', queryResponse.error);
  }

  return {
    users: queryResponse.data?.users,
    usersLoading: queryResponse.isLoading,
    usersError: queryResponse.isError,
    fetching: queryResponse.isFetching,
    refetchUserResults: queryResponse.refetch,
    isFetching: queryResponse.isFetching,
  };
}
