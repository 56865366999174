import { useEffect, useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import PoolTemplatePreviewCard from './components/PoolTemplatePreviewCard';
import { POOL_TEMPLATE_STATUS_OPTIONS } from './constants/options';
import { Button } from 'components/Button';
import { FullScreenSpinner } from 'components/Spinner';
import usePoolTemplates from 'hooks/usePoolTemplates';

export const PoolTemplates = () => {
  // defaults to show current Open pools first
  const [poolTemplatesStatus, setPoolTemplatesStatus] = useState(
    POOL_TEMPLATE_STATUS_OPTIONS[0]
  );

  const navigate = useNavigate();
  const {
    poolTemplates,
    poolTemplatesLoading,
    poolTemplatesError,
    refetchPoolTemplates,
    isFetchingPoolTemplates,
  } = usePoolTemplates(poolTemplatesStatus.value.id);

  useEffect(() => {
    refetchPoolTemplates();
  }, [poolTemplatesStatus.value.id]);

  if (poolTemplatesLoading || isFetchingPoolTemplates) {
    return <FullScreenSpinner />;
  }

  const allPoolTemplates = poolTemplates?.all;

  if (!poolTemplatesError) {
    return (
      <div className="view-pool-templates-container">
        <div style={{ display: 'flex' }}>
          <Button
            accessibilityLabel="add-pool-button"
            type="primary"
            text="Add Pool"
            handleOnClick={() => navigate('/addPool')}
          />
        </div>
        <h2 className="view-pools-text">View Pool Templates</h2>
        <div>
          <label>
            <input
              type="radio"
              value={POOL_TEMPLATE_STATUS_OPTIONS[0].label}
              checked={poolTemplatesStatus == POOL_TEMPLATE_STATUS_OPTIONS[0]}
              onChange={() => {
                setPoolTemplatesStatus(POOL_TEMPLATE_STATUS_OPTIONS[0]);
              }}
            />
            Open Pool Templates
          </label>
          <label>
            <input
              type="radio"
              value={POOL_TEMPLATE_STATUS_OPTIONS[1].label}
              checked={poolTemplatesStatus == POOL_TEMPLATE_STATUS_OPTIONS[1]}
              onChange={() => {
                setPoolTemplatesStatus(POOL_TEMPLATE_STATUS_OPTIONS[1]);
              }}
            />
            Upcoming Pool Templates
          </label>
        </div>
        {allPoolTemplates && (
          <div>
            {allPoolTemplates.length == 0 ? (
              <div> No Pool Templates to display.</div>
            ) : (
              allPoolTemplates.map((poolTemplate) => (
                <PoolTemplatePreviewCard
                  key={poolTemplate.poolTemplateId}
                  poolTemplate={poolTemplate}
                />
              ))
            )}
          </div>
        )}
      </div>
    );
  } else {
    return <p>Error Loading Pools.</p>;
  }
};

export default PoolTemplates;
