import { type MetricOption } from 'types/addPrediction';

export const NFL_PLAYER_FUTURES: MetricOption[] = [
  {
    value: 'MVP',
    label: 'MVP',
  },
  {
    value: 'DefensivePlayerOfTheYear',
    label: 'Defensive Player of the Year',
  },
  {
    value: 'MostReceivingYards',
    label: 'Most Receiving Yards',
  },
  {
    value: 'MostRushingYards',
    label: 'Most Rushing Yards',
  },
  {
    value: 'ComebackPlayerOfTheYear',
    label: 'Comeback Player Of The Year',
  },
  {
    value: 'OffensivePlayerOfTheYear',
    label: 'Offensive Player of the Year',
  },
  {
    value: 'DefensiveRookieOfTheYear',
    label: 'Defensive Rookie of the Year',
  },
  {
    value: 'OffensiveRookieOfTheYear',
    label: 'Offensive Rookie of the Year',
  },
  {
    value: 'MostPassingTouchdowns',
    label: 'Most Passing Touchdowns',
  },
  {
    value: 'MostPassingYards',
    label: 'Most Passing Yards',
  },
  {
    value: 'MostTotalTouchdowns',
    label: 'Most Total Touchdowns',
  },
  {
    value: 'MostRushingTouchdowns',
    label: 'Most Rushing Touchdowns',
  },
  {
    value: 'MostInterceptionsThrown',
    label: 'Most Interceptions Thrown',
  },
  {
    value: 'MostReceivingTouchdowns',
    label: 'Most Receiving Touchdowns',
  },
  {
    value: 'ReceptionsLeader',
    label: 'Receptions Leader',
  },
  {
    value: 'SackLeader',
    label: 'Sack Leader',
  },
  {
    value: 'MostTacklesLeaderSoloAndAssists',
    label: 'Most Tackles Leader - Solo & Assists',
  },
];
