import {
  keepPreviousData,
  useInfiniteQuery,
  type InfiniteData,
  type QueryKey,
} from '@tanstack/react-query';
import { getCommunities } from 'api/community';
import type { Community } from 'types/community';

type CommunitiesResult = {
  communities: Community[];
  cursor?: string;
};

export const useGetCommunities = () => {
  const { data: communities, ...communitiesQuery } = useInfiniteQuery<
    CommunitiesResult,
    Error,
    InfiniteData<CommunitiesResult>,
    QueryKey,
    string | undefined
  >({
    queryKey: ['getVltedCommunities'],
    queryFn: async ({ pageParam }) =>
      await getCommunities({
        queryLimit: 100,
        cursor: pageParam,
      }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage?.cursor;
    },
    placeholderData: keepPreviousData,
  });

  return { communities, communitiesQuery };
};
