import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from './components/Modal';
import PoolPredictionPreview from './components/PoolPredictionPreview';
import { deletePool } from 'api/pools';
import { Button } from 'components/Button';
import { FullScreenSpinner } from 'components/Spinner';
import { getApiUrls } from 'config/api';
import usePoolDetails from 'hooks/usePoolDetails';
import { type PoolPrediction } from 'types/pools';
import { getDateTime } from 'utils/date';

import './style.css';

function getStatusColor(status: string): string {
  switch (status) {
    case 'correct':
      return 'green';
    case 'incorrect':
      return 'red';
    default:
      return 'black';
  }
}

export const PoolDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { poolIdentifier } = useParams();
  // Page Status
  const [deletingPool, setDeletingPool] = useState(false);
  // Confirmation Modal
  const [showModal, setShowModal] = useState(false);
  const { SHARE_BASE_URL } = getApiUrls();

  const { poolResponse, poolLoading, poolError, isFetchingPool } =
    usePoolDetails(poolIdentifier || '');

  if (poolLoading || isFetchingPool || deletingPool) {
    return <FullScreenSpinner />;
  }

  if (!poolError && poolResponse) {
    // Destructure pool information
    const {
      authorData,
      totalPredictions,
      finishedPredictions,
      currentParticipants,
      maxParticipants,
      poolTitle,
      poolStatus,
      privacyStatus,
      poolId,
      goLiveTime,
      poolCloseTime,
    } = poolResponse.pool;

    const deleteCurrentPool = async () => {
      setDeletingPool(true);
      try {
        await deletePool(poolId);
      } catch (err) {
        console.error('Error deleting pool.', err);
      }
      setDeletingPool(false);
      queryClient.invalidateQueries({ queryKey: ['pools'] });
      navigate('/viewPools');
    };

    const poolLink = SHARE_BASE_URL + '/pool/' + goLiveTime + '#' + poolId;

    return (
      <div className="pool-details-container">
        <div className="pool-info-container">
          <h2 className="pool-title"> {poolTitle} </h2>
          <p className="pool-details"> Status: {poolStatus} </p>
          {privacyStatus && <p>Privacy Status: {privacyStatus}</p>}
          {poolResponse.promotionInfo && (
            <p className="pool-promotion">
              Promotional Pool
              <br />
              reward string: {poolResponse.promotionInfo.rewardString}
              <br />
              terms link: {poolResponse.promotionInfo.termsLink}
            </p>
          )}
          <div>
            <p>
              Completed Predictions: {finishedPredictions}/{totalPredictions}
            </p>
          </div>
          <details className="more-info-container">
            <summary>Pool Details</summary>
            <div className="more-info-details-container">
              <div className="pool-details">
                <p className="info-label">Pool ID:</p>
                <p>{poolId}</p>
              </div>

              <div className="pool-details">
                <p className="info-label">Author:</p>
                <p>
                  {authorData.firstName} {authorData.lastName} (@
                  {authorData.username})
                </p>
              </div>
              <div className="pool-details">
                <p className="info-label">Open:</p>
                <p>{getDateTime(new Date(goLiveTime))}</p>
              </div>
              <div className="pool-details">
                <p className="info-label">Close:</p>
                <p>{getDateTime(new Date(poolCloseTime))}</p>
              </div>
              <div className="pool-details">
                <p className="info-label">Participants:</p>
                <p>
                  {currentParticipants} / {maxParticipants}
                </p>
              </div>

              <div className="pool-details">
                <p className="info-label">Share Link:</p>
                <p>{poolLink}</p>
                <button onClick={() => navigator.clipboard.writeText(poolLink)}>
                  Copy Link
                </button>
              </div>
            </div>
          </details>
          <details className="more-info-container">
            <summary>Pool Users</summary>
            <div className="more-info-details-container">
              <table>
                <thead>
                  <th>Rank</th>
                  <th>User Name</th>
                  <th>User Id</th>
                  <th>Score</th>
                  <th>Possible</th>
                </thead>
                <tbody>
                  {poolResponse.leaderboard.userList.map((user) => {
                    return (
                      <tr key={user.userId}>
                        <td>{user.rank}</td>
                        <td>{user.userSummary.username}</td>
                        <td>{user.userId}</td>
                        <td>{user.score}</td>
                        <td>{user.maxPotentialScore}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </details>
          <div>
            {poolResponse.pool.winner && (
              <p className="pool-details">
                {' '}
                winner user id: {poolResponse.pool.winner}
              </p>
            )}
          </div>
        </div>
        <div className="predictions-container">
          <p className="pool-title"> predictions in pool: 🤿</p>
          {poolResponse.poolPredictions?.length == 0 ? (
            <div> No predictions to display. </div>
          ) : (
            poolResponse.poolPredictions.map((prediction: PoolPrediction) => (
              <div className="pool-pred-card" key={prediction.predictionId}>
                <div
                  className="pred-status"
                  style={{ color: getStatusColor(prediction.predictionStatus) }}
                >
                  {prediction.predictionStatus}
                </div>
                <div className="pred-string-container">
                  <PoolPredictionPreview prediction={prediction} />
                </div>
                <details className="more-info-container">
                  <summary>Prediction Details</summary>
                  <div className="more-info-details-container">
                    <div className="pool-details">
                      <pre>{JSON.stringify(prediction, null, 2)}</pre>
                    </div>
                  </div>
                </details>
                <div className="vote-count">
                  <p>Agree: {prediction.agreeCount}</p>
                  <p>Disagree: {prediction.disagreeCount}</p>
                </div>
              </div>
            ))
          )}
        </div>
        <Button
          accessibilityLabel="delete-button"
          type="primary"
          text="Delete Pool"
          handleOnClick={() => setShowModal(true)}
        />
        {showModal ? (
          <Modal
            setShowModal={setShowModal}
            deletePool={deleteCurrentPool}
            setDeletingPool={setDeletingPool}
          />
        ) : null}
      </div>
    );
  } else {
    return (
      <div>
        <p>Error Loading Pool.</p>
        <Button
          accessibilityLabel="go-back-button"
          type="primary"
          text="Go Back"
          handleOnClick={() => navigate('/viewPools')}
        />
      </div>
    );
  }
};

export default PoolDetails;
