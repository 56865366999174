// https://reactjs.org/docs/error-boundaries.html
import { Component, type ErrorInfo, type ReactNode } from 'react';

import './style.css';

interface Props {
  silent: boolean;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.silent) {
      return;
    }
    // TODO [#51]: Datadog logging. See below code snippet
    // log.error('ErrorBoundary: Uncaught Error', {
    //   error,
    //   errorInfo,
    // });
  }

  render() {
    if (this.state.hasError) {
      // TODO [#52] Improve error boundary page
      return (
        <>
          <h1 className="text">Whoops! We fumbled the ball.</h1>
          <h2 className="text">
            Something went wrong. Please reload the page and try again.
          </h2>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
