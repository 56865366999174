import { useState } from 'react';

import './style.css';
import 'screens/AddHotTake/style.css';
import { useNavigate } from 'react-router-dom';
import PoolPredictionsList from './components/PoolPredictionsList';
import { postPool, postPoolTemplate } from 'api/pools';
import { CommunitySearch } from 'chakraComponents/CommunitySearch';
import { AddPredictionForm } from 'components/AddPredictionForm';
import { DateInput } from 'components/AddPredictionForm/components/DateInput';
import { type CreateComparisonPrediction } from 'components/AddPredictionForm/types';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { UserSearch } from 'components/UserSearch';
import {
  CATEGORY_OPTIONS,
  COMPARISON_OPTIONS,
  DURATION_OPTIONS,
  LEAGUE_OPTIONS,
} from 'constants/options';
import usePoolParticipantAvg from 'hooks/usePoolParticipantAvg';
import { type MetricOption } from 'types/addPrediction';
import { type Community } from 'types/community';
import {
  type PostPool,
  type PostPoolPrediction,
  type PostPoolPredictionBase,
  type PostPoolTemplate,
} from 'types/pools';
import {
  type Entity,
  type Game,
  type League,
  type Season,
  type Team,
} from 'types/sportsdata';
import { type UserSummary } from 'types/user';
import {
  getComparisonPredictionContent,
  getFuturePredContent,
  getGameDetails,
  getGameOutcomePredContent,
  getPredictionType,
  getSeasonDetails,
  getSeasonOutcomePredContent,
  getStatPredContent,
  isValidSpread,
} from 'utils/addPred';

export const AddPool = () => {
  const navigate = useNavigate();

  // Prediction Content
  const [league, setLeague] = useState(LEAGUE_OPTIONS[0].value);
  const [leagueTeams, setLeagueTeams] = useState<Team[]>([]);
  const [entity, setEntity] = useState<Entity>();
  const [category, setCategory] = useState(CATEGORY_OPTIONS[0].value);
  const [duration, setDuration] = useState(DURATION_OPTIONS[0].value);
  const [metric, setMetric] = useState<MetricOption>();
  const [metricItems, setMetricItems] = useState<MetricOption[]>([]);
  const [value, setValue] = useState<string>('');
  const [game, setGame] = useState<Game>();
  const [comparison, setComparison] = useState(COMPARISON_OPTIONS[0].value);
  const [season, setSeason] = useState<Season>();
  const [comparisonPrediction, setComparisonPrediction] =
    useState<CreateComparisonPrediction>({
      // Initialize the duration for both predictions to game this will need to change
      // when we have more than 2 comparison predictions
      predictions: {
        '1': {
          duration: {
            id: 'game',
          },
        },
        '2': {
          duration: {
            id: 'game',
          },
        },
      },
    } as CreateComparisonPrediction);

  // Page Status
  const [placingPrediction, setPlacingPrediction] = useState(false);
  const [placingPool, setPlacingPool] = useState(false);

  // Pool Content
  const [poolPredictions, setPoolPredictions] = useState<PostPoolPrediction[]>(
    []
  );
  const [poolTitle, setPoolTitle] = useState<string>('');
  const [poolGoLiveTime, setPoolGoLiveTime] = useState<Date>(
    new Date(Date.now())
  );
  const [poolCloseTime, setPoolCloseTime] = useState<Date>(
    new Date(Date.now())
  );
  const [maxParticipants, setMaxParticipants] = useState<string>('100');
  const [chosenAuthor, setChosenAuthor] = useState<UserSummary>();
  const [isPromotional, setIsPromotional] = useState(false);
  const [isHiddenPool, setIsHiddenPool] = useState(false);
  const [termsLink, setTermsLink] = useState<string>('');
  const [rewardString, setRewardString] = useState<string>('');
  const [isPoolTemplate, setIsPoolTemplate] = useState(false);
  const [poolTemplateCloseTime, setPoolTemplateCloseTime] = useState<Date>(
    new Date(Date.now())
  );
  const [leagues, setLeagues] = useState<Set<League>>(new Set());
  const [numberOfWinners, setNumberOfWinners] = useState<string>('3');
  const [prizeWinnings, setPrizeWinnings] = useState<Array<string>>([]);
  const [communities, setCommunities] = useState<Community[] | undefined>(
    undefined
  );
  const [prependCmntyName, setPrependCmntyName] = useState(true);

  const comparisonPredictionSubmitEnabled = () => {
    const comparisonPredictions = comparisonPrediction.predictions;
    let enabled = true;
    for (const childPrediction of Object.values(comparisonPredictions)) {
      enabled = !!(
        childPrediction?.entity &&
        childPrediction?.metric?.value &&
        (childPrediction?.game || childPrediction?.season) &&
        comparison
      );
      if (!enabled) {
        return enabled;
      }
    }
    return enabled;
  };

  const submitPredictionEnabled = () => {
    if (poolPredictions.length > 24) {
      return false;
    }
    const predictionType = getPredictionType(
      category.id,
      duration.id,
      entity as Entity
    );
    switch (predictionType) {
      case 'PlayerGameStatistic':
      case 'TeamGameStatistic':
        return !!(entity && metric?.value && comparison && value && game);
      case 'PlayerSeasonStatistic':
      case 'TeamSeasonStatistic':
      case 'TeamSeasonOutcome':
        return !!(entity && metric?.value && comparison && value && season);
      case 'TeamGameOutcome':
        if (metric?.value === 'spread') {
          return !!(entity && metric?.value && isValidSpread(value) && game);
        } else {
          return !!(entity && metric?.value && game);
        }
      case 'PlayerSeasonFuture':
      case 'TeamSeasonFuture':
        return !!(entity && metric?.value);
      case 'Comparison':
        return comparisonPredictionSubmitEnabled();
      default:
        return false;
    }
  };

  const isPromotionalPool = () => {
    return isPromotional && termsLink && rewardString;
  };

  const winningsValid = () => {
    if (isPromotionalPool()) {
      if (prizeWinnings.length != Number(numberOfWinners)) {
        return false;
      }
      for (const prize of prizeWinnings) {
        if (!Number.isInteger(Number(prize))) {
          return false;
        }
      }
    }
    return true;
  };

  const submitPoolEnabled = () => {
    return (
      poolPredictions.length > 0 &&
      poolPredictions.length < 25 &&
      poolGoLiveTime &&
      poolCloseTime &&
      poolTitle &&
      chosenAuthor &&
      Number(maxParticipants) &&
      (isPromotionalPool() || !isPromotional) &&
      Number(numberOfWinners) &&
      winningsValid()
    );
  };

  const getPredPostBody = (): PostPoolPrediction => {
    const predictionType = getPredictionType(
      category.id,
      duration.id,
      entity as Entity
    );

    const postBodyBase: PostPoolPredictionBase = {
      predictionType,
      league: league.id,
    };

    let content;
    let gameDetails;
    let seasonDetails;
    let postBody: PostPoolPrediction;
    switch (predictionType) {
      case 'PlayerGameStatistic':
      case 'TeamGameStatistic':
        content = getStatPredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          comparison,
          value,
          leagueTeams,
        });
        gameDetails = getGameDetails(game as Game);
        postBody = {
          ...postBodyBase,
          content,
          gameDetails,
        };
        break;
      case 'PlayerSeasonStatistic':
      case 'TeamSeasonStatistic':
        content = getStatPredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          comparison,
          value,
          leagueTeams,
        });
        seasonDetails = getSeasonDetails(season as Season);
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'TeamGameOutcome':
        content = getGameOutcomePredContent({
          team: entity as Team,
          metric: metric as MetricOption,
          value,
          leagueTeams,
          game: game as Game,
        });
        gameDetails = getGameDetails(game as Game);
        postBody = {
          ...postBodyBase,
          content,
          gameDetails,
        };
        break;
      case 'TeamSeasonOutcome':
        content = getSeasonOutcomePredContent({
          team: entity as Team,
          metric: metric as MetricOption,
          comparison,
          value,
        });
        seasonDetails = getSeasonDetails(season as Season);
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'PlayerSeasonFuture':
      case 'TeamSeasonFuture':
        seasonDetails = getSeasonDetails(season as Season);
        content = getFuturePredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          leagueTeams,
          seasonId: season?.seasonId || '',
          league: league.id as League,
        });
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'Comparison':
        content = getComparisonPredictionContent({
          comparisonPrediction: comparisonPrediction,
          comparison: comparison,
          leagueTeams: leagueTeams,
          league: league.id as League,
        });
        postBody = {
          ...postBodyBase,
          content,
        };
        break;
    }
    return postBody;
  };

  const submitPool = async () => {
    if (!chosenAuthor) {
      console.error('Error adding pool: no author chosen for pool');
      return;
    }
    const postBody: PostPool = {
      poolTitle: poolTitle,
      predictions: poolPredictions,
      maxParticipants: Number(maxParticipants),
      goLiveTime: poolGoLiveTime,
      poolCloseTime: poolCloseTime,
      authorUserId: chosenAuthor.userId,
      numberOfWinners: Number(numberOfWinners),
      communities: communities,
      prependCommunityName: prependCmntyName,
    };
    const prizeWinningsMap = new Map(
      prizeWinnings.map((val, index) => [index + 1, Number(val)])
    );

    if (isPromotionalPool()) {
      postBody.promotionInfo = {
        rewardString,
        termsLink,
        prizeWinnings: Object.fromEntries(prizeWinningsMap),
      };
    }
    postBody.privacyStatus = isHiddenPool ? 'hidden' : 'public';

    // TODO[#56]: Optimistic Mutation?
    setPlacingPool(true);
    try {
      await postPool(postBody);
    } catch (err) {
      console.error('Error adding pool.', err);
    }
    // TODO[#57]: Invalidate Pool query once view pools its completed.
    setPlacingPool(false);
    navigate('/viewPools');
  };

  const submitPoolTemplate = async () => {
    if (!chosenAuthor) {
      console.error('Error adding pool template: no author chosen for pool');
      return;
    }
    const postBody: PostPoolTemplate = {
      poolTemplateTitle: poolTitle,
      predictions: poolPredictions,
      goLiveTime: poolGoLiveTime,
      poolCloseTime: poolCloseTime,
      authorUserId: chosenAuthor.userId,
      poolTemplateCloseTime: poolTemplateCloseTime,
      leagues: Array.from(leagues),
    };

    // TODO[#56]: Optimistic Mutation?
    setPlacingPool(true);
    try {
      await postPoolTemplate(postBody);
    } catch (err) {
      console.error('Error adding pool template.', err);
    }
    // TODO[#57]: Invalidate Pool query once view pools its completed.
    setPlacingPool(false);
    navigate('/viewPoolTemplates');
  };

  const addPoolPrediction = async () => {
    setPlacingPrediction(true);
    const postBody = getPredPostBody();
    const newPredictions = poolPredictions.concat(postBody);
    const newLeagues = leagues.add(league.id);
    setPoolPredictions(newPredictions);
    setLeagues(newLeagues);
    setPlacingPrediction(false);
  };

  const clearAllFields = async () => {
    setSeason(undefined);
    setEntity(undefined);
    setMetric(undefined);
  };

  const { participantAvg } = usePoolParticipantAvg(chosenAuthor?.userId || '');

  return (
    <div className="add-pool-container">
      <h2>Add Pool</h2>
      <h3>Add Prediction for the Pool</h3>
      <AddPredictionForm
        league={league}
        setLeague={setLeague}
        category={category}
        setCategory={setCategory}
        entity={entity}
        setEntity={setEntity}
        leagueTeams={leagueTeams}
        setLeagueTeams={setLeagueTeams}
        metric={metric}
        setMetric={setMetric}
        metricItems={metricItems}
        setMetricItems={setMetricItems}
        duration={duration}
        setDuration={setDuration}
        game={game}
        setGame={setGame}
        season={season}
        setSeason={setSeason}
        value={value}
        setValue={setValue}
        comparison={comparison}
        setComparison={setComparison}
        placingPrediction={placingPrediction}
        comparisonPrediction={comparisonPrediction}
        setComparisonPrediction={setComparisonPrediction}
      />
      <>
        <Button
          accessibilityLabel="Add Pool Prediction Button"
          handleOnClick={() => {
            addPoolPrediction();
            clearAllFields();
          }}
          type="primary"
          text="Add Prediction to Pool"
          disabled={!submitPredictionEnabled() || placingPrediction}
          loading={placingPrediction}
        />
        <Button
          accessibilityLabel="Clear Pool Predictions Button"
          handleOnClick={() => {
            setPoolPredictions([]);
          }}
          type="primary"
          text="Clear Predictions from Pool"
          disabled={poolPredictions.length == 0}
        />
      </>
      <div>
        <h3>Current Predictions in Pool</h3>
        <PoolPredictionsList predictions={poolPredictions} />
      </div>
      <div>
        <p>Pool Title</p>
        <Input
          id={'Pool title field'}
          accessibilityLabel={'Pool title input'}
          value={poolTitle}
          handleOnChange={setPoolTitle}
        />
      </div>
      <div>
        <p>Pool Go Live Time</p>
        <DateInput
          date={poolGoLiveTime}
          setDate={setPoolGoLiveTime}
          disabled={placingPool}
          showTime={true}
        />
      </div>
      <div>
        <p>Pool Close Time</p>
        <DateInput
          date={poolCloseTime}
          setDate={setPoolCloseTime}
          disabled={placingPool}
          showTime={true}
        />
      </div>
      <div>
        <p>Max # Of Participants</p>
        <Input
          id={'Pool max participants field'}
          accessibilityLabel={'Pool max participants input'}
          value={maxParticipants}
          placeholder={
            typeof maxParticipants == 'string' ? maxParticipants : ''
          }
          handleOnChange={setMaxParticipants}
        />
      </div>
      <div>
        <p>Number of Winners</p>
        <Input
          id={'Pool number of winners field'}
          accessibilityLabel={'Pool number of winners input'}
          value={numberOfWinners}
          placeholder={
            typeof numberOfWinners == 'string' ? numberOfWinners : ''
          }
          handleOnChange={setNumberOfWinners}
        />
      </div>
      <div>
        <p>Pool Author</p>
        <UserSearch
          chosenAuthor={chosenAuthor}
          setChosenAuthor={setChosenAuthor}
        />
        {chosenAuthor && (
          <p>Avg Participants for Author: {participantAvg?.avgParticipants}</p>
        )}
      </div>
      <div>
        <label>
          Is this a promotional pool?
          <input
            type="checkbox"
            aria-label={'Pool promotion checkbox'}
            checked={isPromotional}
            onChange={() => {
              setIsPromotional(!isPromotional);
            }}
          />
          {isPromotional && (
            <div>
              <div>
                <p>Terms Link</p>
                <Input
                  id={'Pool promotion terms link field'}
                  accessibilityLabel={'Promotion terms link input'}
                  value={termsLink}
                  handleOnChange={setTermsLink}
                />
              </div>
              <div>
                <p>Reward String</p>
                <Input
                  id={'Pool promotion reward string field'}
                  accessibilityLabel={'Promotion reward input'}
                  value={rewardString}
                  handleOnChange={setRewardString}
                />
              </div>
              <div>
                <p>Prize Winnings</p>
                {Array.from(
                  { length: Number(numberOfWinners) },
                  (_, idx: number) => (
                    <Input
                      accessibilityLabel={`Prize Winnings ${idx}`}
                      id={`Prize Winnings ${idx}`}
                      key={`Prize Winnings ${idx}`}
                      value={prizeWinnings[idx]}
                      handleOnChange={(val) => {
                        prizeWinnings[idx] = val;
                        setPrizeWinnings([...prizeWinnings]);
                      }}
                      error={
                        Number.isInteger(Number(prizeWinnings[idx]))
                          ? ''
                          : 'Must enter valid integer'
                      }
                    />
                  )
                )}
              </div>
            </div>
          )}
        </label>
      </div>
      <div>
        <label>Is this a hidden pool?</label>
        <input
          type="checkbox"
          aria-label={'Hidden pool checkbox'}
          checked={isHiddenPool}
          onChange={() => {
            setIsHiddenPool(!isHiddenPool);
          }}
        />
      </div>
      <div>
        <label>
          Is this a pool template?
          <input
            type="checkbox"
            aria-label={'Pool template checkbox'}
            checked={isPoolTemplate}
            onChange={() => {
              setIsPoolTemplate(!isPoolTemplate);
            }}
          />
        </label>
        {isPoolTemplate && (
          <div>
            <p>
              Note: Pool go live time will be used as the template go live time.
              Please enter the pool template close time below. This is when the
              pool template will no longer be available to users.
            </p>
            <div>
              <p>Pool Template Close Time</p>
              <DateInput
                date={poolTemplateCloseTime}
                setDate={setPoolTemplateCloseTime}
                disabled={placingPool}
                showTime={true}
              />
            </div>
          </div>
        )}
      </div>
      {isHiddenPool && (
        <div className="pool-communities-container">
          <div>
            <p>Communities to attach this pool to (optional):</p>
            <CommunitySearch
              multiSelect={true}
              handleOnClick={(communities) => {
                setCommunities(communities);
              }}
            />
          </div>
          <div>
            <p>Communities attached:</p>
            {communities?.map((community, index) => (
              <p key={index}>{community.title}</p>
            ))}
          </div>
          <label>
            Prepend community names to pool names?
            <input
              type="checkbox"
              aria-label={'Prepend community name checkbox'}
              checked={prependCmntyName}
              onChange={() => {
                setPrependCmntyName(!prependCmntyName);
              }}
            />
          </label>
        </div>
      )}
      <Button
        accessibilityLabel="Create Pool Button"
        handleOnClick={() => {
          setPlacingPool(true);
          if (isPoolTemplate) {
            submitPoolTemplate();
          } else {
            submitPool();
          }
          clearAllFields();
        }}
        type="primary"
        text="Create Pool"
        disabled={!submitPoolEnabled() || placingPool}
        loading={placingPool}
      />
    </div>
  );
};

export default AddPool;
