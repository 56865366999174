import { type MetricOption } from 'types/addPrediction';

export const MLB_TEAM_FUTURES: MetricOption[] = [
  {
    label: 'Playoff Birth',
    value: 'mlbPlayoffBirth',
  },
  {
    label: 'Wild Card Birth',
    value: 'mlbWildCardBirth',
  },
  {
    label: 'NL East Division Winner',
    value: 'mlbNLEastChampion',
  },
  {
    label: 'NL West Division Winner',
    value: 'mlbNLWestChampion',
  },
  {
    label: 'NL Central Division Winner',
    value: 'mlbNLCentralChampion',
  },
  {
    label: 'AL East Division Winner',
    value: 'mlbALEastChampion',
  },
  {
    label: 'AL West Division Winner',
    value: 'mlbALWestChampion',
  },
  {
    label: 'AL Central Division Winner',
    value: 'mlbALCentralChampion',
  },
  {
    label: 'Division Series Round Playoff Appearance',
    value: 'mlbDivisionSeriesRoundPlayoffs',
  },
  {
    label: 'Championship Series Round Playoff Appearance',
    value: 'mlbChampionSeriesRoundPlayoffs',
  },
  {
    label: 'World Series Appearance',
    value: 'mlbReachWorldSeries',
  },
  {
    label: 'World Series Winner',
    value: 'mlbWorldSeriesWinner',
  },
];
