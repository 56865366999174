import { type MetricOption } from 'types/addPrediction';

export const NBA_TEAM_FUTURES: MetricOption[] = [
  {
    value: 'NBAChampion',
    label: 'NBA Champion',
  },
  {
    value: 'EasternConferenceWinner',
    label: 'Eastern Conference Winner',
  },
  {
    value: 'WesternConferenceWinner',
    label: 'Western Conference Winner',
  },
  {
    value: 'SouthwestDivisionWinner',
    label: 'Southwest Division Winner',
  },
  {
    value: 'AtlanticDivisionWinner',
    label: 'Atlantic Division Winner',
  },
  {
    value: 'CentralDivisionWinner',
    label: 'Central Division Winner',
  },
  {
    value: 'NorthwestDivisionWinner',
    label: 'Northwest Division Winner',
  },
  {
    value: 'PacificDivisionWinner',
    label: 'Pacific Division Winner',
  },
  {
    value: 'SoutheastDivisionWinner',
    label: 'Southeast Division Winner',
  },
  {
    value: 'ToMakeThePlayoffs',
    label: 'To Make The Playoffs',
  },
  {
    value: 'WesternConferenceFinalsMatchup',
    label: 'Western Conference Finals Appearance',
  },
  {
    value: 'ToMakeWesternConferencePlayIn',
    label: 'To Make Western Conference Play-In',
  },
  {
    value: 'EasternConferenceFinalsMatchup',
    label: 'Eastern Conference Finals Appearance',
  },
  {
    value: 'ToMakeEasternConferencePlayIn',
    label: 'To Make Eastern Conference Play-In',
  },
  {
    value: 'ToMakeThePlayInTournament',
    label: 'To Make The Play-In Tournament',
  },
  {
    value: 'ConferenceFinalsSweep',
    label: 'Sweep Their Opponent in the Conference Finals',
  },
  {
    value: 'ConferenceFinalsIn5',
    label: 'Win the Conference Finals in 5',
  },
  {
    value: 'ConferenceFinalsIn6',
    label: 'Win the Conference Finals in 6',
  },
  {
    value: 'ConferenceFinalsIn7',
    label: 'Win the Conference Finals in 7',
  },
  {
    value: '1stRoundSweep',
    label: 'Sweep Their Opponent in the 1st Round',
  },
  {
    value: '1stRoundIn5',
    label: 'Win the 1st Round in 5',
  },
  {
    value: '1stRoundIn6',
    label: 'Win the 1st Round in 6',
  },
  {
    value: '1stRoundIn7',
    label: 'Win the 1st Round in 7',
  },
  {
    value: 'Playoffs2ndRound',
    label: 'Reach the 2nd Round',
  },
  {
    value: '2ndRoundSweep',
    label: 'Sweep Their Opponent in the 2nd Round',
  },
  {
    value: '2ndRoundIn5',
    label: 'Win the 2nd Round in 5',
  },
  {
    value: '2ndRoundIn6',
    label: 'Win the 2nd Round in 6',
  },
  {
    value: '2ndRoundIn7',
    label: 'Win the 2nd Round in 7',
  },
  {
    value: 'FinalsMatchup',
    label: 'Finals Appearance',
  },
  {
    value: 'FinalsSweep',
    label: 'Sweep Their Opponent in the Finals',
  },
  {
    value: 'FinalsIn5',
    label: 'Win the Finals in 5',
  },
  {
    value: 'FinalsIn6',
    label: 'Win the Finals in 6',
  },
  {
    value: 'FinalsIn7',
    label: 'Win the Finals in 7',
  },
  {
    value: 'MostRegularSeasonWins',
    label: 'Most Regular Season Wins',
  },
  {
    value: 'LongestWinStreak',
    label: 'Longest Win Streak',
  },
  {
    value: 'BestRecord',
    label: 'Best Record',
  },
  {
    value: '1SeedinEasternConference',
    label: '#1 Seed in Eastern Conference',
  },
  {
    value: '2SeedinEasternConference',
    label: '#2 Seed in Eastern Conference',
  },
  {
    value: '3SeedinEasternConference',
    label: '#3 Seed in Eastern Conference',
  },
  {
    value: '4SeedinEasternConference',
    label: '#4 Seed in Eastern Conference',
  },
  {
    value: '5SeedinEasternConference',
    label: '#5 Seed in Eastern Conference',
  },
  {
    value: '6SeedinEasternConference',
    label: '#6 Seed in Eastern Conference',
  },
  {
    value: '7SeedinEasternConference',
    label: '#7 Seed in Eastern Conference',
  },
  {
    value: '8SeedinEasternConference',
    label: '#8 Seed in Eastern Conference',
  },
  {
    value: '9SeedinEasternConference',
    label: '#9 Seed in Eastern Conference',
  },
  {
    value: '10SeedinEasternConference',
    label: '#10 Seed in Eastern Conference',
  },
  {
    value: '1SeedinWesternConference',
    label: '#1 Seed in Western Conference',
  },
  {
    value: '2SeedinWesternConference',
    label: '#2 Seed in Western Conference',
  },
  {
    value: '3SeedinWesternConference',
    label: '#3 Seed in Western Conference',
  },
  {
    value: '4SeedinWesternConference',
    label: '#4 Seed in Western Conference',
  },
  {
    value: '5SeedinWesternConference',
    label: '#5 Seed in Western Conference',
  },
  {
    value: '6SeedinWesternConference',
    label: '#6 Seed in Western Conference',
  },
  {
    value: '7SeedinWesternConference',
    label: '#7 Seed in Western Conference',
  },
  {
    value: '8SeedinWesternConference',
    label: '#8 Seed in Western Conference',
  },
  {
    value: '9SeedinWesternConference',
    label: '#9 Seed in Western Conference',
  },
  {
    value: '10SeedinWesternConference',
    label: '#10 Seed in Western Conference',
  },
  {
    value: 'FinalSeedPosition',
    label: 'Final Seed Position',
  },
  {
    value: 'WorstRecord',
    label: 'Worst Record',
  },
  {
    value: 'MissthePlayoffs',
    label: 'Miss the Playoffs',
  },
];
