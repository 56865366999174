import { useQuery } from '@tanstack/react-query';
import { getPoolTemplate } from 'api/pools';
import { type PoolTemplateDetails } from 'types/pools';

export default function usePoolTemplateDetails(poolTemplateIdentifier: string) {
  const queryResponse = useQuery<PoolTemplateDetails>({
    queryKey: ['poolTemplate', poolTemplateIdentifier],
    queryFn: async () => await getPoolTemplate(poolTemplateIdentifier),
  });

  if (queryResponse.isError) {
    console.error('Error getting pool template details: ', queryResponse.error);
  }

  return {
    poolTemplate: queryResponse.data,
    poolTemplateLoading: queryResponse.isLoading,
    poolTemplateError: queryResponse.isError,
    refetchPoolTemplate: queryResponse.refetch,
    isFetchingPoolTemplate: queryResponse.isFetching,
  };
}
