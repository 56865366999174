import {
  keepPreviousData,
  useInfiniteQuery,
  type InfiniteData,
  type QueryKey,
} from '@tanstack/react-query';

import { searchUsers } from 'api/user';
import { type SearchUserResult } from 'types/user';

export default function useSearchUsers(searchQuery?: string) {
  const queryResponse = useInfiniteQuery<
    SearchUserResult,
    Error,
    InfiniteData<SearchUserResult>,
    QueryKey,
    string | undefined
  >({
    queryKey: ['usernames', searchQuery],
    queryFn: async ({ pageParam = '' }) =>
      await searchUsers(searchQuery || '', pageParam),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage?.cursor;
    },
    enabled: !!searchQuery,
    placeholderData: keepPreviousData,
  });

  return {
    searchUserResults: queryResponse.data?.pages,
    searchUsersLoading: queryResponse.isLoading,
    searchUsersError: queryResponse.isError,
    fetching: queryResponse.isFetching,
    refetchUserResults: queryResponse.refetch,
    hasNextPage: queryResponse.hasNextPage,
    fetchNextPage: queryResponse.fetchNextPage,
    isFetching: queryResponse.isFetching,
    isFetchingNextPage: queryResponse.isFetchingNextPage,
  };
}
