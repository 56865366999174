import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { getAuth, onAuthStateChanged, RecaptchaVerifier } from 'firebase/auth';
import { firebaseInit } from 'config/firebase';
import { type AuthContextState } from 'contexts/AuthContext/types';
import useCheckAdmin from 'hooks/useCheckAdmin';
import { type FirebaseUser } from 'types/user';

firebaseInit();
const auth = getAuth();
auth.useDeviceLanguage();

const AuthContext = createContext<AuthContextState | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  // This component is inspired by:
  // https://dev.to/dchowitz/react-firebase-a-simple-context-based-authentication-provider-1ool
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>(null);
  const [firebaseLoading, setFirebaseLoading] = useState<boolean>(true);
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();
  const [loading, setLoading] = useState<boolean>(false);

  // Queries
  const queryClient = useQueryClient();
  const { isAdmin, isAdminLoading, fetchingIsAdmin } = useCheckAdmin(
    firebaseUser?.uid
  );

  useEffect(() => {
    // Manage Firebase Auth State
    const unsubscribe = onAuthStateChanged(auth, async (fbUser) => {
      // Set the auth token for every outgoing axios request
      if (fbUser) {
        const token = await fbUser.getIdToken();
        axios.defaults.headers.common['Authorization'] = token;
      } else {
        axios.defaults.headers.common = {};
      }
      // Update firebaseUser in context state
      setFirebaseUser(fbUser);
      setFirebaseLoading(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: () => null, // reCaptcha Solved, allow signInWithPhoneNumber
    });
    setRecaptchaVerifier(verifier);
  }, []);

  useEffect(() => {
    if (firebaseUser === null) {
      queryClient.removeQueries({ queryKey: ['checkAdmin'] });
    }
  }, [firebaseUser]);

  const logout = (callback?: VoidFunction) => {
    callback?.();
    auth.signOut();
  };

  const isLoading = () => {
    return loading || firebaseLoading || (isAdminLoading && fetchingIsAdmin);
  };

  const value = {
    isAdmin,
    firebaseUser,
    recaptchaVerifier,
    loading: isLoading(),
    setLoading,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth(): AuthContextState {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

const AuthConsumer = () => {
  const { isAdmin, firebaseUser, loading } = useAuth();
  return (
    <>
      <p>firebaseUserUID: {firebaseUser?.uid}</p>
      <p>isAdmin: {JSON.stringify(isAdmin)}</p>
      <p>loading: {loading.toString()}</p>
    </>
  );
};

export { AuthContext, AuthProvider, AuthConsumer, useAuth };
