import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { ComparisonForm } from './forms/Comparison';
import { FutureForm } from './forms/Future';
import { OutcomeForm } from './forms/Outcome';
import { StatisticForm } from './forms/Statistic';
import { type CreateComparisonPrediction } from './types';
import usePlayers from 'hooks/usePlayers';
import useTeams from 'hooks/useTeams';
import {
  type ComparisonValue,
  type DurationValue,
  type MetricOption,
  type PredictionCategory,
} from 'types/addPrediction';
import {
  type Entity,
  type Game,
  type League,
  type Player,
  type Season,
  type Team,
} from 'types/sportsdata';
import { getMetricItems } from 'utils/addPred';

import 'screens/AddHotTake/style.css';

export type AddPredictionFormProps = {
  isTournamentRoundTieBreaker?: boolean;
  setIsTournamentRoundTieBreaker?: (value: boolean) => void;
  roundHasTieBreaker?: boolean;
  isForTournament?: boolean;
  league: { id: League };
  setLeague: (league: { id: League }) => void;
  category: { id: PredictionCategory };
  setCategory: (cat: { id: PredictionCategory }) => void;
  entity?: Entity;
  setEntity: (item?: Entity) => void;
  leagueTeams: Team[];
  setLeagueTeams: Dispatch<SetStateAction<Team[]>>;
  metric?: MetricOption;
  setMetric: (item?: MetricOption) => void;
  metricItems: MetricOption[];
  setMetricItems: Dispatch<SetStateAction<MetricOption[]>>;
  duration: DurationValue;
  setDuration: (duration: DurationValue) => void;
  game?: Game;
  setGame: (game?: Game) => void;
  season?: Season;
  setSeason: (season?: Season) => void;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  comparison: ComparisonValue;
  setComparison: Dispatch<SetStateAction<ComparisonValue>>;
  placingPrediction: boolean;
  comparisonPrediction: CreateComparisonPrediction | null;
  setComparisonPrediction: Dispatch<SetStateAction<CreateComparisonPrediction>>;
};

export const AddPredictionForm = ({
  isTournamentRoundTieBreaker,
  setIsTournamentRoundTieBreaker,
  roundHasTieBreaker,
  isForTournament,
  league,
  setLeague,
  category,
  setCategory,
  entity,
  setEntity,
  leagueTeams,
  setLeagueTeams,
  metric,
  setMetric,
  metricItems,
  setMetricItems,
  duration,
  setDuration,
  game,
  setGame,
  season,
  setSeason,
  value,
  setValue,
  comparison,
  setComparison,
  placingPrediction,
  comparisonPrediction,
  setComparisonPrediction,
}: AddPredictionFormProps) => {
  // Load Players for each league
  const {
    players: nbaPlayers,
    playersLoading: nbaPlayersLoading,
    playersError: nbaPlayersError,
  } = usePlayers('nba');
  const {
    players: cbbPlayers,
    playersLoading: cbbPlayersLoading,
    playersError: cbbPlayersError,
  } = usePlayers('cbb');
  const {
    players: mlbPlayers,
    playersLoading: mlbPlayersLoading,
    playersError: mlbPlayersError,
  } = usePlayers('mlb');
  const {
    players: nflPlayers,
    playersLoading: nflPlayersLoading,
    playersError: nflPlayersError,
  } = usePlayers('nfl');
  const {
    players: cfbPlayers,
    playersLoading: cfbPlayersLoading,
    playersError: cfbPlayersError,
  } = usePlayers('cfb');
  const {
    players: nhlPlayers,
    playersLoading: nhlPlayersLoading,
    playersError: nhlPlayersError,
  } = usePlayers('nhl');

  // Load Teams for each league
  const {
    teams: nbaTeams,
    teamsLoading: nbaTeamsLoading,
    teamsError: nbaTeamsError,
  } = useTeams('nba');
  const {
    teams: cbbTeams,
    teamsLoading: cbbTeamsLoading,
    teamsError: cbbTeamsError,
  } = useTeams('cbb');
  const {
    teams: mlbTeams,
    teamsLoading: mlbTeamsLoading,
    teamsError: mlbTeamsError,
  } = useTeams('mlb');
  const {
    teams: nflTeams,
    teamsLoading: nflTeamsLoading,
    teamsError: nflTeamsError,
  } = useTeams('nfl');
  const {
    teams: cfbTeams,
    teamsLoading: cfbTeamsLoading,
    teamsError: cfbTeamsError,
  } = useTeams('cfb');
  const {
    teams: wnbaTeams,
    teamsLoading: wnbaTeamsLoading,
    teamsError: wnbaTeamsError,
  } = useTeams('wnba');
  const {
    teams: cwbbTeams,
    teamsLoading: cwbbTeamsLoading,
    teamsError: cwbbTeamsError,
  } = useTeams('cwbb');
  const {
    teams: nhlTeams,
    teamsLoading: nhlTeamsLoading,
    teamsError: nhlTeamsError,
  } = useTeams('nhl');

  const [entityArray, setEntityArray] = useState<Entity[]>([]);

  const entitiesError = useCallback(
    // TODO [#373]: Launch flash message for resource loading error on AddPrediction screen
    () =>
      nbaPlayersError ||
      cbbPlayersError ||
      mlbPlayersError ||
      nflPlayersError ||
      cfbPlayersError ||
      nhlPlayersError ||
      nbaTeamsError ||
      mlbTeamsError ||
      nflTeamsError ||
      cbbTeamsError ||
      cfbTeamsError ||
      wnbaTeamsError ||
      cwbbTeamsError ||
      nhlTeamsError,
    [
      nbaPlayersError,
      cbbPlayersError,
      mlbPlayersError,
      nflPlayersError,
      cfbPlayersError,
      nhlPlayersError,
      nbaTeamsError,
      mlbTeamsError,
      nflTeamsError,
      cbbTeamsError,
      cfbTeamsError,
      wnbaTeamsError,
      cwbbTeamsError,
      nhlTeamsError,
    ]
  );

  const entitiesLoading = useCallback(
    () =>
      nbaPlayersLoading ||
      cbbPlayersLoading ||
      mlbPlayersLoading ||
      nflPlayersLoading ||
      cfbPlayersLoading ||
      nhlPlayersLoading ||
      nbaTeamsLoading ||
      mlbTeamsLoading ||
      nflTeamsLoading ||
      cbbTeamsLoading ||
      cfbTeamsLoading ||
      wnbaTeamsLoading ||
      cwbbTeamsLoading ||
      nhlTeamsLoading,
    [
      nbaPlayersLoading,
      cbbPlayersLoading,
      mlbPlayersLoading,
      nflPlayersLoading,
      cfbPlayersLoading,
      nhlPlayersLoading,
      nbaTeamsLoading,
      mlbTeamsLoading,
      nflTeamsLoading,
      cbbTeamsLoading,
      cfbTeamsLoading,
      wnbaTeamsLoading,
      cwbbTeamsLoading,
      nhlTeamsLoading,
    ]
  );

  useEffect(() => {
    let playersArray: Player[] = [];
    let teamsArray: Team[] = [];

    switch (league.id) {
      case 'mlb':
        playersArray = mlbPlayers || [];
        teamsArray = mlbTeams || [];
        break;
      case 'nba':
        playersArray = nbaPlayers || [];
        teamsArray = nbaTeams || [];
        break;
      case 'nfl':
        playersArray = nflPlayers || [];
        teamsArray = nflTeams || [];
        break;
      case 'cbb':
        playersArray = cbbPlayers || [];
        teamsArray = cbbTeams || [];
        break;
      case 'cfb':
        teamsArray = cfbTeams || [];
        playersArray = cfbPlayers || [];
        break;
      case 'wnba':
        playersArray = [];
        teamsArray = wnbaTeams || [];
        break;
      case 'cwbb':
        playersArray = [];
        teamsArray = cwbbTeams || [];
        break;
      case 'nhl':
        playersArray = nhlPlayers || [];
        teamsArray = nhlTeams || [];
        break;
    }

    setLeagueTeams(teamsArray);

    setEntityArray(() => [...playersArray, ...teamsArray]);
  }, [
    nflPlayers,
    nbaPlayers,
    cbbPlayers,
    // mlbPlayers,
    cfbPlayers,
    nhlPlayers,
    nflTeams,
    nbaTeams,
    // mlbTeams,
    cbbTeams,
    cfbTeams,
    wnbaTeams,
    cwbbTeams,
    nhlTeams,
    league,
  ]);

  useEffect(() => {
    const items = getMetricItems(league.id, category.id, entity, duration.id);
    setMetricItems(items);
  }, [entity, league, category, duration]);

  function renderTournamentSection() {
    return (
      <div>
        <div>
          <label>
            Is this the tie-breaker question for the round?
            <input
              type="checkbox"
              aria-label={'Tie-breaker tournament round checkbox'}
              checked={isTournamentRoundTieBreaker}
              disabled={roundHasTieBreaker}
              onChange={() => {
                if (setIsTournamentRoundTieBreaker) {
                  setIsTournamentRoundTieBreaker(!isTournamentRoundTieBreaker);
                }
              }}
            />
          </label>
        </div>
      </div>
    );
  }

  function renderForm(category: PredictionCategory) {
    switch (category) {
      case 'statistic':
        return (
          <StatisticForm
            placingPrediction={placingPrediction}
            league={league}
            entity={entity}
            entityArray={entityArray}
            setEntity={setEntity}
            entitiesLoading={entitiesLoading()}
            entitiesError={entitiesError()}
            metric={metric}
            metricItems={metricItems}
            setMetric={setMetric}
            comparison={comparison}
            setComparison={setComparison}
            value={value}
            setValue={setValue}
            duration={duration}
            setDuration={setDuration}
            setSeason={setSeason}
            setGame={setGame}
            game={game}
            season={season}
          />
        );
      case 'outcome':
        return (
          <OutcomeForm
            placingPrediction={placingPrediction}
            league={league}
            entity={entity as Team}
            entityArray={leagueTeams}
            setEntity={setEntity}
            entitiesLoading={entitiesLoading()}
            entitiesError={entitiesError()}
            metric={metric}
            metricItems={metricItems}
            setMetric={setMetric}
            comparison={comparison}
            setComparison={setComparison}
            value={value}
            setValue={setValue}
            game={game}
            setGame={setGame}
            duration={duration}
            setDuration={setDuration}
            setSeason={setSeason}
            season={season}
          />
        );
      case 'future':
        return (
          <FutureForm
            placingPrediction={placingPrediction}
            league={league}
            entity={entity}
            entityArray={entityArray}
            setEntity={setEntity}
            entitiesLoading={entitiesLoading()}
            entitiesError={entitiesError()}
            metricItems={metricItems}
            metric={metric}
            setMetric={setMetric}
            setSeason={setSeason}
          />
        );
      case 'comparison':
        return (
          <ComparisonForm
            placingPrediction={placingPrediction}
            league={league.id}
            entityArray={entityArray}
            entitiesLoading={entitiesLoading()}
            entitiesError={entitiesError()}
            metricItems={metricItems}
            comparison={comparison}
            setComparison={setComparison}
            comparisonPrediction={comparisonPrediction}
            setComparisonPrediction={setComparisonPrediction}
          />
        );
    }
  }

  return (
    <div>
      {isForTournament && renderTournamentSection()}
      <div>
        <p> Select League </p>
        <label>
          <input
            type="radio"
            value="NFL"
            checked={league.id === 'nfl'}
            onChange={() => setLeague({ id: 'nfl' })}
          />
          NFL
        </label>
        <label>
          <input
            type="radio"
            value="NBA"
            checked={league.id === 'nba'}
            onChange={() => setLeague({ id: 'nba' })}
          />
          NBA
        </label>
        <label>
          <input
            type="radio"
            value="NCAA (M)"
            checked={league.id === 'cbb'}
            onChange={() => setLeague({ id: 'cbb' })}
          />
          NCAA (M)
        </label>
        <label>
          <input
            type="radio"
            value="MLB"
            checked={league.id === 'mlb'}
            onChange={() => setLeague({ id: 'mlb' })}
          />
          MLB
        </label>
        <label>
          <input
            type="radio"
            value="CFB"
            checked={league.id === 'cfb'}
            onChange={() => setLeague({ id: 'cfb' })}
          />
          CFB
        </label>
        <label>
          <input
            type="radio"
            value="WNBA"
            checked={league.id === 'wnba'}
            onChange={() => setLeague({ id: 'wnba' })}
          />
          WNBA
        </label>
        <label>
          <input
            type="radio"
            value="NCAA (W)"
            checked={league.id === 'cwbb'}
            onChange={() => setLeague({ id: 'cwbb' })}
          />
          CWBB
        </label>
        <label>
          <input
            type="radio"
            value="NHL"
            checked={league.id === 'nhl'}
            onChange={() => setLeague({ id: 'nhl' })}
          />
          NHL
        </label>
      </div>
      <div>
        <p> Select Prediction Type </p>
        <label>
          <input
            type="radio"
            value="outcome"
            checked={category.id === 'outcome'}
            onChange={() => setCategory({ id: 'outcome' })}
          />
          Outcome
        </label>
        <label>
          <input
            type="radio"
            value="statistic"
            checked={category.id === 'statistic'}
            onChange={() => setCategory({ id: 'statistic' })}
          />
          Statistic
        </label>
        <label>
          <input
            type="radio"
            value="future"
            checked={category.id === 'future'}
            onChange={() => setCategory({ id: 'future' })}
          />
          Futures
        </label>
        <label>
          <input
            type="radio"
            value="comparison"
            checked={category.id === 'comparison'}
            onChange={() => setCategory({ id: 'comparison' })}
          />
          Comparison
        </label>
      </div>
      {renderForm(category.id)}
    </div>
  );
};
