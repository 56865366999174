import {
  type CreateChildComparisonPrediction,
  type CreateComparisonPrediction,
} from 'components/AddPredictionForm/types';
import {
  type ComparisonChildStatisticPrediction,
  type PredictionContent,
  type PredictionType,
} from 'types/prediction';
import { type League } from 'types/sportsdata';
import { dateToDateStringLong } from 'utils/date';
import {
  getEntityString,
  getMetricString,
  getSeasonString,
  isCompareParentPred,
} from 'utils/prediction';

type ComparisonPredictionPreviewStringProps = {
  comparisonPrediction?: CreateComparisonPrediction;
  comparison?: string;
  league: League;
};

const getComparisonPredictionsPreviewStringsAndStylings = (
  comparisonChildPrediction: CreateChildComparisonPrediction | undefined,
  league: League
) => {
  // Comparison Prediction Strings
  let entityString = 'Player/Team';
  let metricString = 'stat';
  let durationString = 'duration';
  let durationPrefix = 'duration prefix';

  if (comparisonChildPrediction) {
    // Check if entity is present
    if (comparisonChildPrediction.entity) {
      entityString = getEntityString(comparisonChildPrediction.entity);
    }
    if (comparisonChildPrediction.metric) {
      metricString =
        getMetricString(
          comparisonChildPrediction.metric.value,
          comparisonChildPrediction.metric.perGame
        ) || 'stat';
    }
    if (comparisonChildPrediction.duration) {
      if (comparisonChildPrediction.duration.id == 'game') {
        durationPrefix = 'during the game taking place on';
        if (comparisonChildPrediction.game) {
          durationString = dateToDateStringLong(
            new Date(comparisonChildPrediction.game.DateTimeUTC)
          );
        }
      }
      if (comparisonChildPrediction.duration.id == 'season') {
        durationPrefix = 'during the';
        if (comparisonChildPrediction.season) {
          durationString =
            getSeasonString(comparisonChildPrediction.season, league) ||
            'duration';
        }
      }
    }
  }
  // Rerturn default values
  return {
    predictionStrings: {
      entityString: entityString,
      metricString: metricString,
      durationString: durationString,
      durationPrefix: durationPrefix,
    },
  };
};

export const ComparisonPredictionPreviewString = ({
  comparisonPrediction,
  comparison,
  league,
}: ComparisonPredictionPreviewStringProps) => {
  let comparisonString = 'comparison';
  let comparatorString = 'than';

  const { predictionStrings: prediction1Strings } =
    getComparisonPredictionsPreviewStringsAndStylings(
      comparisonPrediction?.predictions['1'],
      league
    );
  const { predictionStrings: prediction2Strings } =
    getComparisonPredictionsPreviewStringsAndStylings(
      comparisonPrediction?.predictions['2'],
      league
    );

  if (typeof comparison === 'string') {
    if (comparison == 'will have') {
      comparisonString = 'equal';
      comparatorString = 'as';
    }
    if (comparison == 'will have more than') {
      comparisonString = 'more';
    }
    if (comparison == 'will have less than') {
      comparisonString = 'less';
    }
  }
  return (
    <div>
      <p>
        {`${prediction1Strings.entityString} `}
        will have {` ${comparisonString}`}
        {` ${prediction1Strings.metricString} `}
        {`${prediction1Strings.durationPrefix}`}
        {` ${prediction1Strings.durationString}`}
        {` ${comparatorString} `}
        {`${prediction2Strings.entityString} `}
        will have
        {` ${prediction2Strings.metricString} `}
        {`${prediction2Strings.durationPrefix}`}
        {` ${prediction2Strings.durationString}.`}
      </p>
    </div>
  );
};

const getComparisonPredictionsStringsAndStylings = (
  comparisonChildPrediction: ComparisonChildStatisticPrediction | undefined,
  league: League
) => {
  // Comparison Prediction Strings
  let entityString = 'Player/Team';
  let metricString = 'stat';
  let durationString = 'duration';
  let durationPrefix = 'duration prefix';

  if (comparisonChildPrediction) {
    // Check if entity is present
    if (comparisonChildPrediction.content.player) {
      entityString = comparisonChildPrediction.content.player;
    }
    if (comparisonChildPrediction.content.team) {
      entityString = comparisonChildPrediction.content.team;
    }
    if (comparisonChildPrediction.content.metric) {
      metricString =
        getMetricString(
          comparisonChildPrediction.content.metric,
          comparisonChildPrediction.content.perGame
        )?.toLowerCase() || 'stat';
    }
    if (comparisonChildPrediction.gameDetails) {
      durationPrefix = 'during the game taking place on';
      durationString = dateToDateStringLong(
        new Date(comparisonChildPrediction.gameDetails.gameStartTime)
      );
    }
    if (comparisonChildPrediction.seasonDetails) {
      durationPrefix = 'during the';
      durationString =
        getSeasonString(comparisonChildPrediction.seasonDetails, league) ||
        'season';
    }
  }
  // Rerturn default values
  return {
    predictionStrings: {
      entityString: entityString,
      metricString: metricString,
      durationString: durationString,
      durationPrefix: durationPrefix,
    },
  };
};

type ComparisonPredictionStringProps = {
  comparisonContent: PredictionContent;
  comparisonType: PredictionType;
  comparison?: string;
  league: League;
};

export const ComparisonPredictionString = ({
  comparisonContent,
  comparisonType,
  comparison,
  league,
}: ComparisonPredictionStringProps) => {
  if (isCompareParentPred(comparisonContent, comparisonType)) {
    let comparisonString = 'comparison';
    let comparatorString = 'than';
    const { predictionStrings: prediction1Strings } =
      getComparisonPredictionsStringsAndStylings(
        comparisonContent?.predictions['1'],
        league
      );
    const { predictionStrings: prediction2Strings } =
      getComparisonPredictionsStringsAndStylings(
        comparisonContent?.predictions['2'],
        league
      );

    const comparisonOperator = comparison?.split(/(>=|<=|!=|>|<|=)/g)[1];

    switch (comparisonOperator) {
      case '=':
        comparisonString = 'equal';
        comparatorString = 'as';
        break;
      case '>':
        comparisonString = 'more';
        break;
      case '<':
        comparisonString = 'less';
        break;
      case '>=':
        comparisonString = 'more or equal';
        break;
      case '<=':
        comparisonString = 'less or equal';
        break;
      case '!=':
        comparatorString = 'not equal';
        break;
    }
    return (
      <div>
        <p>
          {`${prediction1Strings.entityString} `}
          will have {`${comparisonString}`}
          {` ${prediction1Strings.metricString} `}
          {`${prediction1Strings.durationPrefix}`}
          {` ${prediction1Strings.durationString}`}
          {` ${comparatorString} `}
          {`${prediction2Strings.entityString} `}
          will have
          {` ${prediction2Strings.metricString} `}
          {`${prediction2Strings.durationPrefix}`}
          {` ${prediction2Strings.durationString}.`}
        </p>
      </div>
    );
  }
  return null;
};
