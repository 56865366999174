import { useEffect, useState } from 'react';

import { Dropdown } from '../components/Dropdown';
import { GameSelector } from '../components/GameSelector';
import { renderEntityItem, renderMetricItem } from '../components/RenderItems';
import { SeasonSelector } from '../components/SeasonSelector';
import {
  type ComparisonFormProps,
  type DurationFormProps,
  type MetricFormProps,
  type ValueFormProps,
} from '../types';
import { Input } from 'components/Input';
import {
  type Entity,
  type League,
  type Player,
  type Season,
  type Team,
} from 'types/sportsdata';
import {
  filterEntities,
  filterMetrics,
  getSeasonsForLeague,
  getTeamId,
  setEntityLabel,
  setMetricLabel,
} from 'utils/addPred';
import { getComparisonString } from 'utils/prediction';

type EntityFormProps = {
  entity: Player | Team | undefined;
  entityArray: (Player | Team)[];
  setEntity: (item?: Entity) => void;
  entitiesLoading: boolean;
  entitiesError: boolean;
};

type StatisticFormProps = {
  placingPrediction: boolean;
  league: { id: League };
} & EntityFormProps &
  MetricFormProps &
  ComparisonFormProps &
  ValueFormProps &
  DurationFormProps;

export const StatisticForm = ({
  league,
  placingPrediction,
  entity,
  entityArray,
  setEntity,
  entitiesLoading,
  metric,
  metricItems,
  setMetric,
  comparison,
  setComparison,
  value,
  setValue,
  duration,
  setDuration,
  setGame,
  setSeason,
  game,
  season,
}: StatisticFormProps) => {
  const [seasons, setSeasons] = useState<Season[]>(
    getSeasonsForLeague(league.id)
  );

  useEffect(() => {
    setValue('');
  }, [metric]);

  useEffect(() => {
    setMetric(undefined);
  }, [duration]);

  useEffect(() => {
    setSeasons(getSeasonsForLeague(league.id));
  }, [league]);

  return (
    <div>
      <div>
        <p> Select Player or Team </p>
        <Dropdown
          accessibilityLabel="Entity Picker"
          placeholder="Select Player or Team"
          items={entityArray}
          value={entity}
          renderItem={renderEntityItem}
          onItemSelect={setEntity}
          filterFn={filterEntities}
          setItemLabel={setEntityLabel}
          clearDisabled={placingPrediction}
          loading={entitiesLoading}
          showItemsOnClick={false}
        />
      </div>
      <div>
        <p> Select Duration </p>
        <label>
          <input
            type="radio"
            value="game"
            checked={duration.id === 'game'}
            onChange={() => setDuration({ id: 'game' })}
          />
          Game
        </label>
        <label>
          <input
            type="radio"
            value="season"
            checked={duration.id === 'season'}
            onChange={() => setDuration({ id: 'season' })}
          />
          Season
        </label>
      </div>
      {duration.id == 'season' && (
        <SeasonSelector
          seasons={seasons}
          disabled={placingPrediction}
          onSeasonSelect={setSeason}
          selectedSeason={season}
        />
      )}
      <div>
        <p> Select Metric </p>
        <Dropdown
          accessibilityLabel="Metric Picker"
          placeholder="Select Metric"
          items={metricItems}
          value={metric}
          renderItem={renderMetricItem}
          onItemSelect={setMetric}
          filterFn={filterMetrics}
          setItemLabel={setMetricLabel}
          clearDisabled={placingPrediction}
          showItemsOnClick={false}
        />
      </div>
      {duration.id == 'game' && (
        <div>
          <p>Game</p>
          <GameSelector
            league={league.id}
            teamId={getTeamId(entity) || ''}
            disabled={placingPrediction}
            onGameSelect={setGame}
            selectedGame={game}
          />
        </div>
      )}
      <div>
        <div>
          <label>
            <input
              type="radio"
              value=">"
              checked={comparison.id === '>'}
              onChange={() =>
                setComparison({
                  id: '>',
                  operator: '>',
                  stringValue: getComparisonString('>'),
                })
              }
            />
            greater than
          </label>
          <label>
            <input
              type="radio"
              value="<"
              checked={comparison.id === '<'}
              onChange={() =>
                setComparison({
                  id: '<',
                  operator: '<',
                  stringValue: getComparisonString('<'),
                })
              }
            />
            less than
          </label>
          <label>
            <input
              type="radio"
              value="="
              checked={comparison.id === '='}
              onChange={() =>
                setComparison({
                  id: '=',
                  operator: '=',
                  stringValue: getComparisonString('='),
                })
              }
            />
            equal to
          </label>
        </div>
        <p>Stat Number</p>
        <Input
          id="Value Field"
          value={value == '.' ? '0.' : value}
          placeholder={typeof metric == 'string' ? metric : ''}
          handleOnChange={setValue}
          accessibilityLabel="Value Field"
        />
      </div>
    </div>
  );
};
