import { useState } from 'react';

import UserList from './UserList';
import { Input } from 'components/Input';
import { type UserSummary } from 'types/user';

export type UserSearchProps = {
  chosenAuthor?: UserSummary;
  setChosenAuthor: (user?: UserSummary) => void;
};

export const UserSearch = ({
  chosenAuthor,
  setChosenAuthor,
}: UserSearchProps) => {
  const [query, setQuery] = useState('');

  return (
    <div>
      <div>
        <Input
          id="user-search-input"
          accessibilityLabel="User search input"
          value={
            chosenAuthor
              ? `${chosenAuthor.firstName} ${chosenAuthor.lastName}`
              : query
          }
          handleOnChange={setQuery}
          icon="search"
          placeholder={'Search for user'}
          clearable={true}
          onClear={() => {
            setQuery('');
            setChosenAuthor(undefined);
          }}
        />
      </div>
      <UserList
        chosenAuthor={chosenAuthor}
        setChosenAuthor={setChosenAuthor}
        query={query}
      />
    </div>
  );
};
