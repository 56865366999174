import {
  type InfiniteData,
  keepPreviousData,
  type QueryKey,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { getHotTakes } from 'api/hotTakes';
import { type HotTakeResponse } from 'types/hotTakes';

export default function useHotTakes(includeAll = false) {
  const queryResponse = useInfiniteQuery<
    HotTakeResponse,
    Error,
    InfiniteData<HotTakeResponse>,
    QueryKey,
    string | undefined
  >({
    queryKey: ['hotTakes'],
    queryFn: async ({ pageParam }) => await getHotTakes(pageParam, includeAll),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage?.cursor;
    },

    placeholderData: keepPreviousData,
  });

  if (queryResponse.isError) {
    console.error('Error getting hot takes: ', queryResponse.error);
  }
  return {
    hotTakes: queryResponse.data?.pages,
    hotTakesLoading: queryResponse.isLoading,
    hotTakesError: queryResponse.isError,
    refetchHotTakes: queryResponse.refetch,
    hasNextPage: queryResponse.hasNextPage,
    fetchNextPage: queryResponse.fetchNextPage,
    isFetching: queryResponse.isFetching,
    isFetchingNextPage: queryResponse.isFetchingNextPage,
  };
}
