import React, { type Dispatch, type SetStateAction } from 'react';

import { CATEGORY_OPTIONS } from 'constants/options';
import { type PredictionCategoryOption } from 'types/addPrediction';

import './selector.css';

type CategorySelectorProps = {
  category: PredictionCategoryOption;
  setCategory: Dispatch<SetStateAction<PredictionCategoryOption>>;
};

export const CategorySelector = ({
  category,
  setCategory,
}: CategorySelectorProps) => {
  return (
    <div className="selector-container">
      <p className="selector-label"> Select Category: {category.label}</p>
      <div className="options-row">
        {CATEGORY_OPTIONS.map((categoryOption) => {
          return (
            <button
              key={categoryOption.value.id}
              className={
                category.value.id === categoryOption.value.id
                  ? 'option-selected'
                  : 'option'
              }
              onClick={() => setCategory(categoryOption)}
            >
              {categoryOption.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
