import type { ComponentStyleConfig } from '@chakra-ui/react';

export const InputTheme: ComponentStyleConfig = {
  baseStyle: {
    height: '56px',
    borderRadius: '4px',
    color: 'text.primary',
    fontWeight: 400,
    fontSize: '16px',
    borderColor: 'neutral.300',
    borderWidth: '1px',
    _focus: {
      borderColor: 'accent.200',
    },
    _placeholder: {
      color: 'text.secondary',
    },
  },
};
