import axios from 'axios';

import { getApiUrls } from 'config/api';
import { type PostRound, type PostTournament } from 'types/tournaments';

const { ADMIN_API_URL, API_URL } = getApiUrls();

export const getTournaments = async (status?: string, pageParam = '') => {
  let url = `${API_URL}/tournaments?queryLimit=50`;
  if (status) {
    url = url + `&status=${status}`;
  }
  if (pageParam) {
    url = url + `&cursor=${pageParam}`;
  }
  const { data } = await axios.get(url);
  return data.object;
};

export const getTournamentDetails = async (tournamentIdentifier: string) => {
  const encodedTournamentIdentifier = encodeURIComponent(tournamentIdentifier);
  const url = `${API_URL}/tournamentDetails/${encodedTournamentIdentifier}`;
  const { data } = await axios.get(url);
  return data.object;
};

export const postTournament = async (body: PostTournament) => {
  const { data } = await axios.post(`${ADMIN_API_URL}/admin/tournament`, body);
  return data;
};

export const postRound = async (body: PostRound) => {
  const { data } = await axios.post(
    `${ADMIN_API_URL}/admin/tournament/addRound`,
    body
  );
  return data;
};
