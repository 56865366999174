import { type MetricOption } from 'types/addPrediction';

export const WNBA_TEAM_FUTURES: MetricOption[] = [
  {
    value: 'wnbaChampion',
    label: 'Champion',
  },
  {
    value: 'FinalsMatchup',
    label: 'Finals Appearance',
  },
  {
    value: 'SemifinalsMatchup',
    label: 'Semi-Finals Appearance',
  },
  {
    value: 'ToMakeThePlayoffs',
    label: 'To Make The Playoffs',
  },
  {
    value: 'MissthePlayoffs',
    label: 'Miss the Playoffs',
  },
  {
    value: 'CommissionerCupChampion',
    label: 'Commissioner Cup Champion',
  },
  {
    value: 'CommissionerCupAppearance',
    label: 'Commissioner Cup Appearance',
  },
  {
    value: '1SeedRegularSeason',
    label: 'Regular Season #1 Seed',
  },
  {
    value: 'RegularSeasonTop4Finish',
    label: 'Regular Season Top 4 Finish',
  },
];
