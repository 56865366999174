import { type MetricOption } from 'types/addPrediction';

export const NBA_METRICS = [
  { label: 'Points Scored', value: 'Points' },
  { label: 'Assists', value: 'Assists' },
  { label: 'Rebounds', value: 'Rebounds' },
  { label: 'Steals', value: 'Steals' },
  { label: 'Blocked Shots', value: 'BlockedShots' },
  { label: 'Turnovers', value: 'Turnovers' },
  { label: '+/-', value: 'PlusMinus' },
  { label: 'Personal Fouls', value: 'PersonalFouls' },
  { label: 'Field Goals Percentage', value: 'FieldGoalsPercentage' },
  { label: 'Two Pointers Made', value: 'TwoPointersMade' },
  { label: 'Two Pointers Attempted', value: 'TwoPointersAttempted' },
  { label: 'Two Pointers Percentage', value: 'TwoPointersPercentage' },
  { label: 'Three Pointers Made', value: 'ThreePointersMade' },
  { label: 'Three Pointers Attempted', value: 'ThreePointersAttempted' },
  { label: 'Three Pointers Percentage', value: 'ThreePointersPercentage' },
  { label: 'Offensive Rebounds', value: 'OffensiveRebounds' },
  { label: 'Defensive Rebounds', value: 'DefensiveRebounds' },
];

export const NBA_PER_GAME_METRICS: MetricOption[] = [
  { label: 'Points Scored Per Game', value: 'Points', perGame: true },
  { label: 'Assists Per Game', value: 'Assists', perGame: true },
  { label: 'Rebounds Per Game', value: 'Rebounds', perGame: true },
  { label: 'Steals Per Game', value: 'Steals', perGame: true },
  {
    label: 'Blocked Shots Per Game',
    value: 'BlockedShots',
    perGame: true,
  },
  { label: 'Turnovers Per Game', value: 'Turnovers', perGame: true },
  { label: '+/- Per Game', value: 'PlusMinus', perGame: true },
  {
    label: 'Personal Fouls Per Game',
    value: 'PersonalFouls',
    perGame: true,
  },
  {
    label: 'Two Pointers Made Per Game',
    value: 'TwoPointersMade',
    perGame: true,
  },
  {
    label: 'Two Pointers Attempted Per Game',
    value: 'TwoPointersAttempted',
    perGame: true,
  },
  {
    label: 'Three Pointers Made Per Game',
    value: 'ThreePointersMade',
    perGame: true,
  },
  {
    label: 'Three Pointers Attempted Per Game',
    value: 'ThreePointersAttempted',
    perGame: true,
  },
  {
    label: 'Offensive Rebounds Per Game',
    value: 'OffensiveRebounds',
    perGame: true,
  },
  {
    label: 'Defensive Rebounds Per Game',
    value: 'DefensiveRebounds',
    perGame: true,
  },
];
