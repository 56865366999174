import React, { useRef } from 'react';

import { Button } from 'components/Button';

type ModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deletePool: () => void;
  setDeletingPool: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Modal = ({
  setShowModal,
  deletePool,
  setDeletingPool,
}: ModalProps) => {
  // close the modal when clicking outside the modal.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const closeModal = (e: any) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  return (
    <div className="container" ref={modalRef} onClick={closeModal}>
      <div className="modal">
        <h2>Are you sure you want to delete this pool?</h2>
        <Button
          accessibilityLabel="delete-pool-confirm-button"
          type="primary"
          text="Delete Pool"
          handleOnClick={() => {
            deletePool();
            setShowModal(false);
            setDeletingPool(false);
          }}
        />
        <Button
          accessibilityLabel="delete-pool-cancel-button"
          type="secondary"
          handleOnClick={() => {
            setShowModal(false);
            setDeletingPool(false);
          }}
          text="Cancel"
        />
      </div>
    </div>
  );
};
