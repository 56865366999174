import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

import { ComparisonSelector } from './steps/ComparisonSelector';
import { WizardString } from './WizardString';
import { getWizardSteps } from '../utils';
import { type PredInfo } from 'screens/AddPrediction/types';

import './steps/selector.css';
import './forms.css';

type WizardProps = {
  predictionInfo: PredInfo;
  setPredictionInfo: Dispatch<SetStateAction<PredInfo>>;
  lhsPredictionInfo: PredInfo;
  setLhsPredictionInfo: Dispatch<SetStateAction<PredInfo>>;
  rhsPredictionInfo: PredInfo;
  setRhsPredictionInfo: Dispatch<SetStateAction<PredInfo>>;
};

export const AddPredictionWizard = ({
  predictionInfo,
  setPredictionInfo,
  lhsPredictionInfo,
  setLhsPredictionInfo,
  rhsPredictionInfo,
  setRhsPredictionInfo,
}: WizardProps) => {
  // Prediction Content Info
  const [step, setStep] = useState(0);

  let steps = [];
  if (predictionInfo.category != 'comparison') {
    steps = getWizardSteps(predictionInfo, setPredictionInfo);
  } else {
    steps = [
      ...getWizardSteps(lhsPredictionInfo, setLhsPredictionInfo, 'lhs'),
      <ComparisonSelector
        key="season_outcome_comp"
        predContent={predictionInfo}
        setPredContent={setPredictionInfo}
      />,
      ...getWizardSteps(rhsPredictionInfo, setRhsPredictionInfo, 'rhs'),
    ];
  }

  useEffect(() => {
    const maxStep = Math.min(step, steps.length - 1);
    setStep(maxStep);
  }, [steps]);

  return (
    <div className="form-container">
      <div className="form-preview-container">
        <div className="form-preview-title">Prediction Preview</div>
        <div className="form-preview-content">
          <WizardString
            predictionInfo={predictionInfo}
            lhsPredInfo={lhsPredictionInfo}
            rhsPredInfo={rhsPredictionInfo}
            setStep={setStep}
          />
          <p style={{ fontSize: '16px', paddingTop: '12px' }}>
            &#9432; You can click on the underlined strings to jump to that
            step!
          </p>
        </div>
      </div>
      <div className="form-step-container">
        <div className="form-step-input">{steps[step]}</div>
        <div className="form-step-selector">
          <button disabled={step == 0} onClick={() => setStep(step - 1)}>
            {'<'}
          </button>
          {new Array(steps.length).fill('temp').map((placeholder, index) => {
            return index === step ? (
              <div
                key={`step_${index}_selected`}
                className="selected-display-step"
                onClick={() => setStep(index)}
              >
                {index + 1}
              </div>
            ) : (
              <div
                key={`step_${index}`}
                className="display-step"
                onClick={() => setStep(index)}
              >
                {index + 1}
              </div>
            );
          })}
          <button
            disabled={step == steps.length - 1}
            onClick={() => setStep(step + 1)}
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  );
};
