import React, { useEffect, useState } from 'react';

import { GameSelector as RadioGameSelector } from 'components/AddPredictionForm/components/GameSelector';
import { type WizardStepProps } from 'screens/AddPrediction/types';
import { type Game } from 'types/sportsdata';
import { getTeamId } from 'utils/addPred';
// TODO [#79]: Refactor AddPredictionForm to use Wizard

export const GameSelector = ({
  predContent,
  setPredContent,
}: WizardStepProps) => {
  // Manage Prediction Content State
  const [game, setGame] = useState<Game | undefined>(predContent.game);

  useEffect(() => {
    setPredContent({
      ...predContent,
      game,
    });
  }, [game]);

  return (
    <div className="form-dropdown-container">
      <p>Select Game</p>
      {/*
        TODO [#79]: Refactor component to manage all season selection logic when transitioning away
        from the AddPredictionForm components to the Wizard
      */}
      <RadioGameSelector
        league={predContent.league}
        teamId={getTeamId(predContent.entity) || ''}
        disabled={false}
        onGameSelect={setGame}
        selectedGame={game}
      />
    </div>
  );
};
