import { type Dispatch, type SetStateAction, type SyntheticEvent } from 'react';

import { Button, Input, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

type ConfirmationInputFormProps = {
  verificationCode: string;
  setVerificationCode: Dispatch<SetStateAction<string>>;
  handleSubmit: (event: SyntheticEvent) => Promise<void>;
  handleResendCode: () => Promise<void>;
};

export const ConfirmationInputForm = ({
  verificationCode,
  setVerificationCode,
  handleSubmit,
  handleResendCode,
}: ConfirmationInputFormProps) => {
  const navigate = useNavigate();

  return (
    <VStack w="100%" gap="30px">
      <form id="verification-code-form" onSubmit={handleSubmit}>
        <label>
          <Text variant="eyebrow">Verification Code:</Text>
          <Input
            id="verification-code-input"
            value={verificationCode}
            onChange={(event) => setVerificationCode(event.target.value)}
            maxLength={6}
          />
        </label>
      </form>
      <Button
        id="sign-in-button"
        type="submit"
        form="verification-code-form"
        isDisabled={verificationCode.length !== 6}
      >
        Confirm Verification Code
      </Button>
      <div className="sign-in-secondary-buttons-container">
        <Button id="cancel-button" onClick={() => navigate(0)} variant="ghost">
          Cancel
        </Button>
        <Button
          id="resend-button"
          onClick={() => handleResendCode()}
          variant="ghost"
        >
          Resend
        </Button>
      </div>
    </VStack>
  );
};
