import React, { useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  Input,
  List,
  ListItem,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import useSearchCommunities from 'hooks/useCommunities';
import useDebounce from 'hooks/useDebounce';
import { type Community } from 'types/community';

export type CommunitySearchProps = {
  multiSelect?: boolean;
  handleOnClick?: (communities: Community[]) => void;
};

export const CommunitySearch: React.FC<CommunitySearchProps> = ({
  multiSelect,
  handleOnClick,
}) => {
  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    []
  );
  const [query, setQuery] = useState<string>('');

  const debounceDelay = 500;
  const debouncedSearchQuery = useDebounce(query, debounceDelay);

  useEffect(() => {
    if (handleOnClick && selectedCommunities.length > 0) {
      handleOnClick(selectedCommunities);
    }
  }, [selectedCommunities, handleOnClick]);

  const handleSelectCommunity = (community: Community) => {
    const previouslySelectedCommunities = selectedCommunities;
    const communityExists = previouslySelectedCommunities.find(
      (u) => u.communityId === community.communityId
    );
    let newSelectedCommunities;
    if (multiSelect) {
      newSelectedCommunities = communityExists
        ? previouslySelectedCommunities.filter(
            (u) => u.communityId !== community.communityId
          )
        : [...previouslySelectedCommunities, community];
    } else {
      newSelectedCommunities = communityExists ? [] : [community];
    }
    setSelectedCommunities(newSelectedCommunities);
  };

  const { searchCommunitiesResults, searchCommunitiesLoading } =
    useSearchCommunities(debouncedSearchQuery);

  return (
    <Box width="100%" mx="auto" mt="10">
      <VStack spacing={4}>
        <Input
          placeholder="Search for community"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          width={'100%'}
        />
        {searchCommunitiesLoading && query && <Spinner size="xl" mt="4" />}
        <List spacing={3} mt="4" overflowY={'scroll'}>
          {searchCommunitiesResults &&
            searchCommunitiesResults.map((group) =>
              (group.communities || []).map((community) => (
                <ListItem
                  key={community.communityId}
                  borderWidth="1px"
                  borderRadius="md"
                  p="4"
                  textAlign="left"
                >
                  <Checkbox
                    isChecked={
                      selectedCommunities.find(
                        (u) => u.communityId === community.communityId
                      )
                        ? true
                        : false
                    }
                    onChange={() => handleSelectCommunity(community)}
                  >
                    <p>{community.title}</p>
                  </Checkbox>
                </ListItem>
              ))
            )}
        </List>
      </VStack>
    </Box>
  );
};

export default CommunitySearch;
