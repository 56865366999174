import { type Season } from 'types/sportsdata';

export const CFB_SEASONS: Season[] = [
  {
    seasonId: '2024REG',
    seasonYear: '2024',
    seasonEndDate: '2024-12-16T00:00:00Z',
    label: '2024 Regular Season',
    league: 'cfb',
  },
  {
    seasonId: '2024POST',
    seasonYear: '2024',
    seasonEndDate: '2025-01-22T00:00:00Z',
    label: '2024 Post Season',
    league: 'cfb',
  },
];

export const CURRENT_CFB_FUTURES_SEASON: Season = {
  seasonId: '2024',
  seasonYear: '2024',
  seasonEndDate: '2025-01-22T00:00:00Z',
  label: 'CFB 2024 Season',
  league: 'cfb',
};
