import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/Button';
import { FullScreenSpinner } from 'components/Spinner';
import { getApiUrls } from 'config/api';
import useTournamentDetails from 'hooks/useTournamentDetails';
import PoolPreviewCard from 'screens/Pools/components/PoolPreviewCard';
import { type TournamentsRound } from 'types/tournaments';
import { getDateTime } from 'utils/date';

import './style.css';

export const TournamentDetails = () => {
  const navigate = useNavigate();
  const { tournamentIdentifier } = useParams();
  const { SHARE_BASE_URL } = getApiUrls();

  const {
    tournamentResponse,
    tournamentLoading,
    tournamentError,
    isFetchingTournament,
  } = useTournamentDetails(tournamentIdentifier || '');

  if (tournamentLoading || isFetchingTournament) {
    return <FullScreenSpinner />;
  }

  if (!tournamentError && tournamentResponse) {
    // Destructure tournament information
    const {
      authorData,
      maxParticipants,
      currentParticipants,
      tournamentTitle,
      tournamentStatus,
      tournamentId,
      goLiveTime,
      closeTime,
      currentRound,
      numberOfRounds,
    } = tournamentResponse.tournament;
    const rounds = tournamentResponse.rounds;

    const tournamentLink =
      SHARE_BASE_URL + '/tournament/' + goLiveTime + '#' + tournamentId;
    const tournamentDetails = tournamentResponse.tournament;
    const maxParticipantsForRound =
      tournamentDetails.maxParticipants /
      Math.pow(2, tournamentDetails.currentRound - 1);
    const tournamentRoundRoute =
      '/addRound' +
      `/${encodeURIComponent(
        `${tournamentDetails.goLiveTime}#${tournamentDetails.tournamentId}`
      )}` +
      `/${tournamentDetails.currentRound}` +
      `/${maxParticipantsForRound}` +
      `/${tournamentDetails.authorData?.userId}`;

    return (
      <div className="tournament-details-container">
        <div className="tournament-info-container">
          <h2 className="tournament-title"> {tournamentTitle} </h2>
          <p className="tournament-details"> Status: {tournamentStatus} </p>

          <details className="more-info-container">
            <summary>Tournament Details</summary>
            <div className="more-info-details-container">
              <div className="tournament-details">
                <p className="info-label">Tournament ID:</p>
                <p>{tournamentId}</p>
              </div>

              <div className="tournament-details">
                <p className="info-label">Author:</p>
                <p>
                  {authorData?.firstName} {authorData?.lastName} (@
                  {authorData?.username})
                </p>
              </div>
              <div className="tournament-details">
                <p className="info-label">Open:</p>
                <p>{getDateTime(new Date(goLiveTime))}</p>
              </div>
              <div className="tournament-details">
                <p className="info-label">Close:</p>
                <p>{getDateTime(new Date(closeTime))}</p>
              </div>
              <div className="tournament-details">
                <p className="info-label">Max Number of Participants:</p>
                <p>{maxParticipants}</p>
              </div>
              <div className="tournament-details">
                <p className="info-label">Current Number of Participants:</p>
                <p>{currentParticipants}</p>
              </div>
              <div className="tournament-details">
                <p className="info-label">Number of Rounds:</p>
                <p>{numberOfRounds}</p>
              </div>
              <div className="tournament-details">
                <p className="info-label">Current Round:</p>
                <p>{currentRound}</p>
              </div>
              <div style={{ display: 'flex' }}>
                <Button
                  accessibilityLabel="add-pool-button"
                  type="primary"
                  text="Add Round"
                  handleOnClick={() => navigate(tournamentRoundRoute)}
                />
              </div>
              <div className="tournaments-container">
                <p className="tournament-title"> Rounds in tournament:</p>
                {!rounds || Number(rounds.length) === 0 ? (
                  <div> No rounds to display. </div>
                ) : (
                  rounds.map((round: TournamentsRound) => (
                    <details
                      className="more-info-container"
                      key={round.roundNumber}
                    >
                      <summary>Round {round.roundNumber}</summary>
                      <div
                        className="tournament-pred-card"
                        key={round.roundNumber}
                      >
                        {round.pool ? (
                          <div key={round.pool.poolId}>
                            <PoolPreviewCard
                              key={round.pool.poolId}
                              pool={round.pool}
                            />
                          </div>
                        ) : (
                          <p> No pool</p>
                        )}
                        <details className="more-info-container">
                          <summary>MatchUp Details</summary>
                          <div className="more-info-details-container">
                            <div className="tournament-details">
                              <pre>
                                {JSON.stringify(round?.matchUps || '', null, 2)}
                              </pre>
                            </div>
                          </div>
                        </details>
                      </div>
                    </details>
                  ))
                )}
              </div>
              <div className="tournament-details">
                <p className="info-label">Share Link:</p>
                <p>{tournamentLink}</p>
                <button
                  onClick={() => navigator.clipboard.writeText(tournamentLink)}
                >
                  Copy Link
                </button>
                <Button
                  accessibilityLabel="go-back-button"
                  type="primary"
                  text="Go Back"
                  handleOnClick={() => navigate('/viewTournaments')}
                />
              </div>
            </div>
          </details>
        </div>
        {null}
      </div>
    );
  } else {
    return (
      <div>
        <p>Error Loading Tournament.</p>
        <Button
          accessibilityLabel="go-back-button"
          type="primary"
          text="Go Back"
          handleOnClick={() => navigate('/viewTournaments')}
        />
      </div>
    );
  }
};

export default TournamentDetails;
