import { useQuery } from '@tanstack/react-query';
import { getPool } from 'api/pools';
import { type PoolDetails } from 'types/pools';

export default function usePoolDetails(poolIdentifier: string) {
  const queryResponse = useQuery<PoolDetails>({
    queryKey: ['pool', poolIdentifier],
    queryFn: async () => await getPool(poolIdentifier),
  });

  if (queryResponse.isError) {
    console.error('Error getting pool details: ', queryResponse.error);
  }

  return {
    poolResponse: queryResponse.data,
    poolLoading: queryResponse.isLoading,
    poolError: queryResponse.isError,
    refetchPool: queryResponse.refetch,
    isFetchingPool: queryResponse.isFetching,
  };
}
