import { useQuery } from '@tanstack/react-query';

import { getTeams } from 'api/sportsdata';
import { type League, type Team } from 'types/sportsdata';

export default function useTeams(league: League) {
  const queryResponse = useQuery<Team[]>({
    queryKey: ['teams', league],
    queryFn: async () => await getTeams(league),
  });

  return {
    teams: queryResponse.data,
    teamsLoading: queryResponse.isLoading,
    teamsError: queryResponse.isError,
  };
}
