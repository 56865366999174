import type { ComponentStyleConfig } from '@chakra-ui/react';

export const ButtonTheme: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    color: 'text.primary',
    fontWeight: 500,
  },
  variants: {
    primary: {
      bg: 'accent.300',
      _active: {
        bg: 'accent.100',
      },
      _hover: {
        bg: 'accent.200',
      },
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'accent.300',
    },
    secondary: {
      bg: 'transparent',
      _active: {
        bg: 'accent.100',
      },
      _hover: {
        bg: 'accent.200',
      },
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'accent.300',
    },
    ghost: {
      bg: 'transparent',
      color: 'accent.100',
      borderColor: 'transparent',
      _hover: {
        color: 'accent.200',
        bg: 'transparent',
      },
      _active: {
        color: 'accent.300',
        bg: 'transparent',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
