import axios from 'axios';
import { getApiUrls } from 'config/api';

const { API_URL } = getApiUrls();

export const ImageUploadType = {
  CommunityLogo: 'COMMUNITY_LOGO',
  UserImagePost: 'USER_IMAGE_POST',
  ProfilePicture: 'PROFILE_PICTURE',
} as const;

export type ImageUploadType =
  (typeof ImageUploadType)[keyof typeof ImageUploadType];

type BaseImageUploadUrlInput = {
  userId: string;
  imageUploadType: 'USER_IMAGE_POST' | 'PROFILE_PICTURE';
};

type GetCommunityLogoImageUploadUrlInput = {
  userId: string;
  imageUploadType: 'COMMUNITY_LOGO';
};

export type GetImageUploadUrlInput =
  | BaseImageUploadUrlInput
  | GetCommunityLogoImageUploadUrlInput;

export type GetImageUploadUrlOutput = {
  uploadUrl: string;
  imageKey: string;
};

export const getImageUploadUrl = async (input: GetImageUploadUrlInput) => {
  const { userId, imageUploadType } = input;

  const url = `${API_URL}/imageUploadUrl?userId=${userId}&imageType=${imageUploadType}`;

  const { data } = await axios.get(url);
  return data.object;
};
