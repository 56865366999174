import axios from 'axios';
import { getApiUrls } from 'config/api';

const { ADMIN_API_URL } = getApiUrls();

export const addAmbassadorCode = async (ambassadorCode: string) => {
  const url = `${ADMIN_API_URL}/admin/ambassadorCode`;
  const { data } = await axios.post(url, { code: ambassadorCode });
  return data;
};
