import { type Season } from 'types/sportsdata';

export const NHL_SEASONS: Season[] = [
  {
    seasonId: '2025PRE',
    seasonYear: '2025',
    seasonEndDate: '2024-10-07T00:00:00Z',
    label: '2024-2025 Preseason',
    league: 'nhl',
  },
  {
    seasonId: '2025REG',
    seasonYear: '2025',
    seasonEndDate: '2025-04-18T00:00:00Z',
    label: '2024-2025 Regular Season',
    league: 'nhl',
  },
];

export const CURRENT_NHL_FUTURES_SEASON: Season = {
  seasonId: '2025',
  seasonYear: '2025',
  seasonEndDate: '2025-06-30T00:00:00Z',
  label: 'NHL 2024-2025 Season',
  league: 'nhl',
};
