import { useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { Modal } from './components/Modal';
import { postAnnouncement } from 'api/announcements';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { type PostAnnouncement } from 'types/announcements';

export const Announcements = () => {
  const navigate = useNavigate();

  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementMessage, setAnnouncementMessage] = useState('');
  const [creatingAnnouncement, setCreatingAnnouncement] = useState(false);

  // Confirmation Modal
  const [showModal, setShowModal] = useState(false);

  const clearAnjouncement = () => {
    setAnnouncementTitle('');
    setAnnouncementMessage('');
  };

  const submitEnabled = () => {
    if (announcementTitle != '' && announcementMessage != '') {
      return true;
    }
    return false;
  };

  const submitAnnouncement = async () => {
    const postBody: PostAnnouncement = {
      title: announcementTitle,
      message: announcementMessage,
    };
    try {
      await postAnnouncement(postBody);
    } catch (err) {
      console.error('Error adding announcement.', err);
    }
    clearAnjouncement();
    navigate('/announcements');
  };

  return (
    <div className="add-announcement-container">
      <h2 className="add-announcement-text">Create Announcement</h2>
      <div className="announcement-input-container">
        <p>Announcement Title</p>
        <Input
          id="Value Field"
          value={announcementTitle}
          placeholder={'Announcement Title'}
          handleOnChange={setAnnouncementTitle}
          accessibilityLabel="Announcement Title Input"
        />
        <p>Announcement Message</p>
        <Input
          id="Value Field"
          value={announcementMessage}
          placeholder={'Announcement Message'}
          handleOnChange={setAnnouncementMessage}
          accessibilityLabel="Announcement Message Input"
        />
        <Button
          accessibilityLabel="Add Announcement Button"
          handleOnClick={() => {
            setCreatingAnnouncement(true);
            setShowModal(true);
          }}
          type="primary"
          text="Create Announcement"
          disabled={!submitEnabled() || creatingAnnouncement}
        />
      </div>
      {showModal ? (
        <Modal
          setShowModal={setShowModal}
          submitAnnouncement={submitAnnouncement}
          announcementTitle={announcementTitle}
          announcementMessage={announcementMessage}
          setCreatingAnnouncement={setCreatingAnnouncement}
        />
      ) : null}
    </div>
  );
};
