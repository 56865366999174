import type { UseToastOptions } from '@chakra-ui/react';

export const errorToast = (message: string): UseToastOptions => {
  return {
    title: 'Error',
    description: message,
    status: 'error',
    duration: 5000,
    isClosable: true,
  };
};
