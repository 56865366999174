// NOTE: This file mimics the values found in the index.css file.
// TODO [#42]: Optimize web styling to maintain css values in one place

export const Primary = {
  main: '#9461FF',
  secondary: '##5B3ADD',
};

export const Surface = {
  x10: 'rgba(92, 70, 128, 0.85)',
  x30: 'rgba(38, 12, 82, 0.85)',
  x50: 'rgba(21, 9, 51, 0.8)',
  x80: 'rgba(15, 6, 35, 0.9)',
  x100: '#090617',
};

export const SurfaceSolid = {
  x50: '#22134F',
};

export const OnSurface = {
  x10: '#BEB1CE',
  x50: '#887B98',
  x100: '#5C4680',
};

export const SurfaceVariant = {
  x10: '#B7ADE9',
  x30: '#9E8BF8',
  x50: '#8B73FF',
  x80: '#502EB0',
  x100: '#33138E',
};

export const Neutral = {
  x10: '#000000',
  x20: '#322F37',
  x50: '#887B98',
  x80: '#CAC4D0',
  x90: '#E0D0EC',
  x100: '#FFFFFF',
};

export const System = {
  error: '#FF7B7B',
  confirmation: '#63C97F',
};

export const BrandColors = {
  primary: '#7710D1',
  secondary: '#ededed',
  tertiary: '#bd93f9',
};
