import { useEffect, useState } from 'react';

import './style.css';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ConfirmDeactivate } from './components/ConfirmDeactivate';
import { deactivateHotTake } from 'api/hotTakes';
import { Button } from 'components/Button';
import { PredictionString } from 'components/PredictionString';
import { FullScreenSpinner } from 'components/Spinner';
import useHotTakes from 'hooks/useHotTakes';
import { type ComparisonHotTake, type HotTake } from 'types/hotTakes';
import { getDateTime } from 'utils/date';
import {
  getComparisonFromHotTake,
  getEntityStringFromHotTake,
  getMetricFromHotTake,
  getPredictedValueFromHotTake,
  isGameHotTake,
  isSeasonHotTake,
} from 'utils/hotTakes';
import { getPredGroupingFromType, isFuturePred } from 'utils/prediction';

const renderHotTake = (hotTake: HotTake) => {
  let isActiveString = 'false';
  if (hotTake.isActive) {
    isActiveString = 'true';
  }
  return (
    <div>
      <PredictionString
        grouping={getPredGroupingFromType(hotTake.predictionType)}
        entity={getEntityStringFromHotTake(hotTake)}
        metric={getMetricFromHotTake(hotTake)}
        comparison={getComparisonFromHotTake(hotTake)}
        value={getPredictedValueFromHotTake(hotTake)}
        date={
          isGameHotTake(hotTake)
            ? new Date(hotTake.gameDetails.gameStartTime)
            : undefined
        }
        season={isSeasonHotTake(hotTake) ? hotTake.seasonDetails : undefined}
        league={hotTake.league}
        negatedPrediction={
          isFuturePred(hotTake.content, hotTake.predictionType)
            ? hotTake.content.negatedPrediction
            : undefined
        }
        comparisonPredictionContent={(hotTake as ComparisonHotTake).content}
        comparisonPredictionType={(hotTake as ComparisonHotTake).predictionType}
      />
      <p>
        Agrees: {hotTake.agreeCount} Disagrees: {hotTake.disagreeCount}
      </p>
      <div>
        <p>IsActive: {isActiveString}</p>
        <p>
          StartTime: {getDateTime(new Date(hotTake.hotTakeStartTime))}
          <br />
          EndTime: {getDateTime(new Date(hotTake.hotTakeEndTime))}
        </p>
      </div>
    </div>
  );
};

export const HotTakes = () => {
  const [includeAll, setIncludeAll] = useState(false);
  const [deactivatingHotTake, setDeactivatingHotTake] = useState(false);
  const [hotTakeToDeactivate, setHotTakeToDeactivate] = useState<
    HotTake | undefined
  >(undefined);

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const {
    hotTakes,
    hotTakesLoading,
    hotTakesError,
    refetchHotTakes,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useHotTakes(includeAll);

  useEffect(() => {
    refetchHotTakes();
  }, [includeAll]);

  if (hotTakesLoading || isFetching || isFetchingNextPage) {
    return <FullScreenSpinner />;
  }

  const removeHotTake = async (hotTakeId: string, createdAt: string) => {
    setDeactivatingHotTake(true);
    const encodedHotTakeIdentifier = encodeURIComponent(
      `${createdAt}#${hotTakeId}`
    );
    try {
      await deactivateHotTake(encodedHotTakeIdentifier);
    } catch (err) {
      console.error('Error deleting hot take.', err);
    }
    setDeactivatingHotTake(false);
    queryClient.invalidateQueries({ queryKey: ['hotTakes'] });
  };

  if (!hotTakesError) {
    return (
      <div className="view-hot-takes-container">
        <div style={{ display: 'flex' }}>
          <Button
            accessibilityLabel="add-hot-takes-button"
            type="primary"
            text="Add Hot Take"
            handleOnClick={() => navigate('/addHotTake')}
          />
        </div>
        <h2 className="view-hot-takes-text">View Hot Takes</h2>
        <div>
          <label>
            <input
              type="radio"
              value="active"
              checked={!includeAll}
              onChange={() => {
                setIncludeAll(false);
              }}
            />
            Active Hot Takes
          </label>
          <label>
            <input
              type="radio"
              value="all"
              checked={includeAll}
              onChange={() => {
                setIncludeAll(true);
              }}
            />
            All Hot Takes
          </label>
        </div>
        {hotTakes?.map((group, index) => (
          <div key={index}>
            {group?.HotTakes.length == 0 ? (
              <div> No Hot Takes to display. </div>
            ) : (
              group?.HotTakes.map((hotTake) => (
                <div key={hotTake.hotTakeId}>
                  <div
                    key={hotTake.hotTakeId}
                    style={{
                      backgroundColor: '#5B3ADD',
                      borderRadius: 20,
                      flex: 1,
                    }}
                  >
                    {renderHotTake(hotTake)}
                    <div
                      style={{
                        alignContent: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <Button
                        accessibilityLabel={`deactivate-button-${hotTake.hotTakeId}`}
                        type="primary"
                        text="Deactivate Hot Take"
                        disabled={deactivatingHotTake}
                        handleOnClick={() => {
                          setDeactivatingHotTake(true);
                          setHotTakeToDeactivate(hotTake);
                        }}
                      />
                    </div>
                  </div>
                  <div key={hotTake.hotTakeId}>
                    {hotTakeToDeactivate?.hotTakeId == hotTake.hotTakeId ? (
                      <ConfirmDeactivate
                        setHotTakeToDeactivate={setHotTakeToDeactivate}
                        deleteHotTake={() =>
                          removeHotTake(
                            hotTakeToDeactivate.hotTakeId,
                            hotTakeToDeactivate.createdAt
                          )
                        }
                        setDeactivatingHotTake={setDeactivatingHotTake}
                      />
                    ) : null}
                  </div>
                </div>
              ))
            )}
          </div>
        ))}
        {hasNextPage && (
          <Button
            accessibilityLabel="load more hot takes"
            type="primary"
            text="Load More"
            handleOnClick={fetchNextPage}
          />
        )}
      </div>
    );
  } else {
    return <p>Error Loading Hot Takes.</p>;
  }
};
