import { useEffect } from 'react';

import { Dropdown } from '../components/Dropdown';
import { renderEntityItem, renderMetricItem } from '../components/RenderItems';
import { type Future, type MetricFormProps } from '../types';
import {
  CURRENT_CBB_FUTURES_SEASON,
  CURRENT_CFB_FUTURES_SEASON,
  CURRENT_CWBB_FUTURES_SEASON,
  CURRENT_MLB_FUTURES_SEASON,
  CURRENT_NBA_FUTURES_SEASON,
  CURRENT_NFL_FUTURES_SEASON,
  CURRENT_NHL_FUTURES_SEASON,
  CURRENT_WNBA_FUTURES_SEASON,
} from 'constants/.';
import { type Entity, type League, type Season } from 'types/sportsdata';
import {
  filterEntities,
  filterMetrics,
  setEntityLabel,
  setMetricLabel,
} from 'utils/addPred';

type EntityFormProps = {
  entity: Entity | undefined;
  entityArray: Entity[];
  setEntity: (item?: Entity) => void;
  entitiesLoading: boolean;
  entitiesError: boolean;
} & MetricFormProps;

type FutureFormProps = {
  placingPrediction: boolean;
  league: { id: League };
  future?: Future;
  setSeason: (season: Season | undefined) => void;
} & EntityFormProps &
  MetricFormProps;

export const FutureForm = ({
  league,
  placingPrediction,
  entity,
  entityArray,
  setEntity,
  entitiesLoading,
  metricItems,
  metric,
  setMetric,
  setSeason,
}: FutureFormProps) => {
  useEffect(() => {
    if (league.id == 'nfl') {
      setSeason(CURRENT_NFL_FUTURES_SEASON);
    } else if (league.id == 'nba') {
      setSeason(CURRENT_NBA_FUTURES_SEASON);
    } else if (league.id == 'cbb') {
      setSeason(CURRENT_CBB_FUTURES_SEASON);
    } else if (league.id == 'mlb') {
      setSeason(CURRENT_MLB_FUTURES_SEASON);
    } else if (league.id == 'cfb') {
      setSeason(CURRENT_CFB_FUTURES_SEASON);
    } else if (league.id == 'wnba') {
      setSeason(CURRENT_WNBA_FUTURES_SEASON);
    } else if (league.id == 'cwbb') {
      setSeason(CURRENT_CWBB_FUTURES_SEASON);
    } else if (league.id == 'nhl') {
      setSeason(CURRENT_NHL_FUTURES_SEASON);
    }
    setMetric(undefined);
  }, [entity, league]);

  return (
    <div>
      <div>
        <p> Select Player or Team </p>
        <Dropdown
          accessibilityLabel="Entity Picker"
          placeholder="Select Player or Team"
          items={entityArray}
          value={entity}
          renderItem={renderEntityItem}
          onItemSelect={setEntity}
          filterFn={filterEntities}
          setItemLabel={setEntityLabel}
          clearDisabled={placingPrediction}
          loading={entitiesLoading}
          showItemsOnClick={false}
        />
      </div>
      <div>
        <p>Future</p>
        <Dropdown
          accessibilityLabel="Future Picker"
          placeholder="Select Future"
          items={metricItems}
          value={metric}
          renderItem={renderMetricItem}
          onItemSelect={setMetric}
          filterFn={filterMetrics}
          setItemLabel={setMetricLabel}
          clearDisabled={placingPrediction}
          showItemsOnClick={true}
        />
      </div>
    </div>
  );
};
