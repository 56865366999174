import { Spinner, Button, HStack, Text } from '@chakra-ui/react';
import type { GetCommunityUserOption } from 'api/community';
import { useAuth } from 'contexts/AuthContext';
import { useCommunityUserActions } from 'hooks/useCommunityUserActions';
import type { User, UserSummary } from 'types/user';

export type CommunityUserActionsProps = {
  user: User;
  userFilter: GetCommunityUserOption;
  adminData: UserSummary[];
  communityId: string;
};

export const CommunityUserActions: React.FC<CommunityUserActionsProps> = ({
  user,
  userFilter,
  adminData,
  communityId,
}) => {
  const { firebaseUser: currentUser } = useAuth();
  const {
    updateCommunityDetails,
    updateCommunityDetailsMutation,
    respondToRequest,
    respondToRequestMutation,
    inviteUsersMutation,
    handleBlockStatus,
    blockStatusMutation,
  } = useCommunityUserActions({ communityId });

  if (
    updateCommunityDetailsMutation.isPending ||
    respondToRequestMutation.isPending ||
    inviteUsersMutation.isPending ||
    blockStatusMutation.isPending
  ) {
    return <Spinner />;
  }

  if (userFilter === 'users') {
    const isAdmin = adminData.map((data) => data.userId).includes(user.userId);

    if (isAdmin) {
      return updateCommunityDetailsMutation.isSuccess ? (
        <Text>Admin Removed.</Text>
      ) : (
        <Button
          size="sm"
          onClick={() =>
            updateCommunityDetails({ communityId, adminToRemove: user.userId })
          }
        >
          Remove Admin
        </Button>
      );
    } else {
      if (updateCommunityDetailsMutation.isSuccess) {
        return <Text>Admin Added.</Text>;
      }
      if (blockStatusMutation.isSuccess) {
        return <Text>User Blocked.</Text>;
      }
      return (
        <HStack>
          <Button
            size="sm"
            onClick={() =>
              handleBlockStatus({ userId: user.userId, reaction: 'block' })
            }
          >
            Block User
          </Button>
          <Button
            size="sm"
            onClick={() =>
              updateCommunityDetails({ communityId, adminToAdd: user.userId })
            }
          >
            Make Admin
          </Button>
        </HStack>
      );
    }
  } else if (userFilter === 'invited') {
    return <></>;
  } else if (userFilter === 'requests') {
    if (respondToRequestMutation.isSuccess) {
      if (respondToRequestMutation.variables.response === 'Accept') {
        return <Text>Request Accepted.</Text>;
      } else {
        return <Text>Request Declined.</Text>;
      }
    }
    return (
      <HStack>
        <Button
          size="sm"
          colorScheme="green"
          variant="solid"
          onClick={() =>
            respondToRequest({
              requestingUserId: user.userId,
              respondingUserId: currentUser?.uid ?? '',
              response: 'Accept',
            })
          }
        >
          Accept
        </Button>
        <Button
          size="sm"
          colorScheme="red"
          variant="solid"
          onClick={() => {
            respondToRequest({
              requestingUserId: user.userId,
              respondingUserId: currentUser?.uid ?? '',
              response: 'Decline',
            });
          }}
        >
          Decline
        </Button>
      </HStack>
    );
  } else if (userFilter === 'blocked') {
    if (blockStatusMutation.isSuccess) {
      return <Text>User Unblocked.</Text>;
    }
    return (
      <Button
        size="sm"
        onClick={() =>
          handleBlockStatus({ userId: user.userId, reaction: 'unblock' })
        }
      >
        Unblock
      </Button>
    );
  }
};
