import './style.css';
import { useNavigate } from 'react-router-dom';
import { type PoolTemplateSummary } from 'types/pools';
import { getDateTime } from 'utils/date';

type PoolTemplatePreviewCardProps = {
  poolTemplate: PoolTemplateSummary;
};

export const PoolTemplatePreviewCard = ({
  poolTemplate,
}: PoolTemplatePreviewCardProps) => {
  const navigate = useNavigate();
  const encodedPoolTemplateIdentifier = encodeURIComponent(
    `${poolTemplate.poolTemplateCloseTime}#${poolTemplate.poolTemplateId}`
  );
  return (
    <div className="pool-preview-container">
      <div
        className="pool-preview-click-area"
        onClick={() => {
          navigate('/poolTemplate/' + encodedPoolTemplateIdentifier);
        }}
      >
        <p className="pool-template-title">
          {' '}
          {poolTemplate.poolTemplateTitle}{' '}
        </p>
        <div>
          <p className="pool-template-info">
            # of predictions: {poolTemplate.totalPredictions}
            <br />
            leagues: {poolTemplate.leagues}
          </p>
        </div>
        <div>
          <p className="pool-template-details">
            pool template open time:{' '}
            {getDateTime(new Date(poolTemplate.goLiveTime))}
            <br />
            pool template close time:{' '}
            {getDateTime(new Date(poolTemplate.poolTemplateCloseTime))}
            <br />
            pool close time: {getDateTime(new Date(poolTemplate.poolCloseTime))}
            <br />
            number of times used: {poolTemplate.templateUsedCount}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PoolTemplatePreviewCard;
