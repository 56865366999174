import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { addAmbassadorCode } from 'api/ambassador';
import { markUsersPaid } from 'api/user';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Spinner } from 'components/Spinner';
import useUsersWithCode from 'hooks/useUsersWithCode';
import { type TrackingUserInfo } from 'types/user';

import './style.css';

export const AmbassadorTracking = () => {
  const [inputValue, setInputValue] = useState('');
  const [trackingCode, setTrackingCode] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<TrackingUserInfo[]>([]);
  const [markingUsersPaid, setMarkingUsersPaid] = useState(false);
  const [newAmbassadorCode, setNewAmbassadorCode] = useState('');
  const [newCodeLoading, setNewCodeLoading] = useState(false);

  const queryClient = useQueryClient();

  const { users, usersLoading } = useUsersWithCode(trackingCode);

  const addNewAmbassadorCode = async () => {
    setNewCodeLoading(true);
    await addAmbassadorCode(newAmbassadorCode);
    setNewAmbassadorCode('');
    setNewCodeLoading(false);
  };

  const renderUsers = () => {
    if (usersLoading) {
      return <Spinner />;
    } else if (users && users.length > 0) {
      return (
        <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
                <th>Active Subscription</th>
                <th>Free Trial Ends</th>
                <th>Subscription Expires</th>
                <th>Paid Ambassador for User</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.username}</td>
                  <td>{user.hasActiveSubscription ? 'Yes' : 'No'}</td>
                  <td>{user.freeTrialEndDate || 'N/A'}</td>
                  <td>{user.subscriptionExpirationDate || 'N/A'}</td>
                  <td>{user.isPaid ? 'Yes' : 'No'}</td>
                  <td>
                    <input
                      type="checkbox"
                      aria-label={`select-user-${user.userId}`}
                      id={`select-user-${user.userId}`}
                      name={`select-user-${index}`}
                      checked={selectedUsers.includes(user)}
                      onChange={(e) =>
                        handleCheckboxChange(user, e.target.checked)
                      }
                      disabled={user.isPaid || selectedUsers.length >= 100}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <h3>Selected Users:</h3>
            <ul>
              {selectedUsers.map((user, index) => (
                <li key={index}>{user.username}</li>
              ))}
            </ul>
            <Button
              accessibilityLabel="Mark Users Paid Button"
              type="primary"
              text="Mark Selected Users as Paid"
              loading={markingUsersPaid}
              disabled={selectedUsers.length === 0 || markingUsersPaid}
              handleOnClick={async () => {
                setMarkingUsersPaid(true);
                await markUsersPaid(selectedUsers.map((user) => user.userId));
                await queryClient.invalidateQueries({
                  queryKey: ['usersWithCode'],
                });
                setMarkingUsersPaid(false);
                setSelectedUsers([]);
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div aria-label="no-users-text">
        No users found with this tracking code.
      </div>
    );
  };

  const handleCheckboxChange = (user: TrackingUserInfo, isChecked: boolean) => {
    if (isChecked) {
      // Add username to the list
      const newList = [...selectedUsers, user];
      setSelectedUsers(newList);
    } else {
      // Remove username from the list
      const newList = selectedUsers.filter(
        (existingUsername) => existingUsername !== user
      );
      setSelectedUsers(newList);
    }
  };

  return (
    <div className="ambassador-tracking-container">
      <h2 className="header-text">Ambassador Tracking</h2>
      <div className="tracking-code-input-container">
        <Input
          id="newCodeInput"
          value={newAmbassadorCode}
          placeholder="Enter New Ambassador Code"
          handleOnChange={setNewAmbassadorCode}
          accessibilityLabel="New Code Input"
        />
        <Button
          text="Add Code"
          accessibilityLabel="add code button"
          type="primary"
          handleOnClick={addNewAmbassadorCode}
          loading={newCodeLoading}
        />
      </div>
      <label htmlFor="trackingCodeInput" className="input-label">
        Tracking Code
      </label>
      <div className="tracking-code-input-container">
        <Input
          id="trackingCodeInput"
          value={inputValue}
          placeholder="Enter Tracking Code"
          handleOnChange={setInputValue}
          accessibilityLabel="Tracking Code Input"
        />
        <Button
          accessibilityLabel="Use Tracking Code Button"
          type="primary"
          text="Get Users"
          handleOnClick={() => setTrackingCode(inputValue)}
          loading={usersLoading}
        />
      </div>
      <h3 className="header-text">Users</h3>
      {renderUsers()}
    </div>
  );
};
