import React, { type Dispatch, type SetStateAction } from 'react';

import { DURATION_OPTIONS } from 'constants/options';
import { type DurationOption } from 'types/addPrediction';

import './selector.css';

type DurationSelectorProps = {
  duration: DurationOption;
  setDuration: Dispatch<SetStateAction<DurationOption>>;
};

export const DurationSelector = ({
  duration,
  setDuration,
}: DurationSelectorProps) => {
  return (
    <div className="selector-container">
      <p className="selector-label"> Select Duration: {duration.label}</p>
      <div className="options-row">
        {DURATION_OPTIONS.map((durationOption) => {
          return (
            <button
              key={durationOption.value.id}
              className={
                duration.value.id === durationOption.value.id
                  ? 'option-selected'
                  : 'option'
              }
              onClick={() => setDuration(durationOption)}
            >
              {durationOption.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
