import { useEffect, useState } from 'react';

import { DateInput } from 'components/AddPredictionForm/components/DateInput';
import { Spinner } from 'components/Spinner';
import useGames from 'hooks/useGames';
import { type Game, type League } from 'types/sportsdata';
import {
  getSignedNumberMod5,
  isValidGame,
  parseDateToString,
} from 'utils/addPred';

const getMoneyLine = (line: number | undefined) => {
  if (line) {
    return `(${getSignedNumberMod5(line)})`;
  } else {
    return '';
  }
};

type GameSelectorProps = {
  league: League;
  teamId: string;
  disabled: boolean;
  onGameSelect: (game: Game | undefined) => void;
  selectedGame: Game | undefined;
};

export const GameSelector = ({
  league,
  teamId,
  disabled,
  onGameSelect,
  selectedGame,
}: GameSelectorProps) => {
  const [date, setDate] = useState<Date>(new Date(Date.now()));

  const { games, gamesLoading, gamesError } = useGames(date, teamId, league);
  useEffect(() => {
    const validIndex = games?.findIndex((game) => isValidGame(game));
    if (games && validIndex != undefined && validIndex != -1) {
      onGameSelect(games[validIndex]);
    } else {
      onGameSelect(undefined);
    }
  }, [games]);

  return (
    <div>
      <div>
        <DateInput date={date} setDate={setDate} disabled={disabled} />
      </div>
      {teamId && gamesLoading && <Spinner />}
      {gamesError && (
        <p>Something went wrong fetching the schedule. Please try again!</p>
      )}
      {games?.length == 0 && <p>No Games. Select another Date</p>}
      {games?.map((game: Game, index: number) => (
        <div key={index}>
          <label key={index}>
            <input
              type="radio"
              value={game.GlobalGameID}
              checked={game.GlobalGameID === selectedGame?.GlobalGameID}
              onChange={() => {
                onGameSelect(game);
              }}
            />
            {game.AwayTeam} {getMoneyLine(game.AwayTeamMoneyLine)} @{' '}
            {game.HomeTeam} {getMoneyLine(game.HomeTeamMoneyLine)}{' '}
            {game.DateTimeUTC
              ? parseDateToString(`${game.DateTimeUTC}Z`)
              : parseDateToString(game.DateTime)}
          </label>
        </div>
      ))}
    </div>
  );
};
