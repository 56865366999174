import { type MetricOption } from 'types/addPrediction';

export const NBA_PLAYER_FUTURES: MetricOption[] = [
  {
    value: 'MVPWinner',
    label: 'MVP Winner',
  },
  {
    value: 'RookieoftheYear',
    label: 'Rookie of the Year',
  },
  {
    value: '6thManoftheYear',
    label: '6th Man of the Year',
  },
  {
    value: 'DefensivePlayeroftheYear',
    label: 'Defensive Player of the Year',
  },
  {
    value: 'MostImprovedPlayer',
    label: 'Most Improved Player',
  },
  {
    value: 'LeadingScorerPointsPerGame',
    label: 'Leading Scorer - Points Per Game',
  },
  {
    value: 'ReboundsLeaderReboundsPerGame',
    label: 'Rebounds Leader - Rebounds Per Game',
  },
  {
    value: 'AssistsLeaderAssistsPerGame',
    label: 'Assists Leader - Assists Per Game',
  },
  {
    value: 'AveragePointsPerGame',
    label: 'Most Points Per Game',
  },
  {
    value: '3PointersMadePerGameLeader',
    label: '3-Pointers Made Per Game Leader',
  },
  {
    value: 'Most3PointersMade',
    label: 'Most 3-Pointers Made',
  },
  {
    value: 'ScoringTitle',
    label: 'Scoring Title - Most Points Scored',
  },
  {
    value: 'AssistsTitle',
    label: 'Assists Title - Most Assists',
  },
  {
    value: 'ReboundsTitle',
    label: 'Rebounds Title - Most Rebounds',
  },
  {
    value: 'StealsTitle',
    label: 'Steals Title - Most Steals',
  },
  {
    value: 'BlocksTitle',
    label: 'Blocks Title - Most Blocks',
  },
  {
    value: 'BlocksLeaderBlocksPerGame',
    label: 'Blocks Leader - Blocks Per Game',
  },
  {
    value: 'StealsLeaderStealsPerGame',
    label: 'Steals Leader - Steals Per Game',
  },
  {
    value: 'AverageAssistsPerGame',
    label: 'Most Assists Per Game',
  },
  {
    value: 'AverageReboundsPerGame',
    label: 'Most Rebounds Per Game',
  },
  {
    value: 'MostPointsPlayoffs',
    label: 'Most Points during the Playoffs',
  },
  {
    value: 'MostAssistsPlayoffs',
    label: 'Most Assists during the Playoffs',
  },
  {
    value: 'MostReboundsPlayoffs',
    label: 'Most Rebounds during the Playoffs',
  },
  {
    value: 'MostStealsPlayoffs',
    label: 'Most Steals during the Playoffs',
  },
  {
    value: 'MostBlocksPlayoffs',
    label: 'Most Blocks during the Playoffs',
  },
  {
    value: 'Most3PointersMadePlayoffs',
    label: 'Most 3-Pointers Made during the Playoffs',
  },
  {
    value: 'MostPointsFinals',
    label: 'Most Points during the Finals',
  },
  {
    value: 'MostAssistsFinals',
    label: 'Most Assists during the Finals',
  },
  {
    value: 'MostReboundsFinals',
    label: 'Most Rebounds during the Finals',
  },
  {
    value: 'MostStealsFinals',
    label: 'Most Steals during the Finals',
  },
  {
    value: 'MostBlocksFinals',
    label: 'Most Blocks during the Finals',
  },
  {
    value: 'Most3PointersMadeFinals',
    label: 'Most 3-Pointers Made during the Finals',
  },
  {
    value: 'FinalsMVPWinner',
    label: 'Finals MVP Winner',
  },
];
