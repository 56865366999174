export const OUTCOME_GAME_METRICS = [
  { label: 'Win', value: 'win', description: 'Selected team will win' },
  { label: 'Lose', value: 'lose', description: 'Selected team will lose' },
  {
    label: 'Cover',
    value: 'spread',
    description: 'Selected team will cover the spread provided',
  },
];

export const HIDDEN_OUTCOME_GAME_METRICS = [
  {
    label: 'Not Cover',
    value: 'negated_spread',
    description: 'Selected team will not cover the spread provided',
  },
];

export const OUTCOME_SEASON_METRICS = [
  {
    label: 'Wins',
    value: 'Wins',
    description: 'Selected team will have x wins',
  },
  {
    label: 'Losses',
    value: 'Losses',
    description: 'Selected team will have x losses',
  },
];
