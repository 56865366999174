import React from 'react';

import { PredictionString } from 'components/PredictionString';
import {
  type PostComparisonPoolPrediction,
  type PostGamePoolPrediction,
  type PostPoolPrediction,
  type PostSeasonPoolPrediction,
} from 'types/pools';
import {
  GAME_PRED_TYPES,
  getComparisonFromPrediction,
  getEntityStringFromPred,
  getMetricFromPrediction,
  getPredGroupingFromType,
  getPredictedValueFromPrediction,
  isFuturePred,
  SEASON_PRED_TYPES,
} from 'utils/prediction';

export type PoolPredictionsListProps = {
  predictions: PostPoolPrediction[];
};

const PoolPredictionsList = ({ predictions }: PoolPredictionsListProps) => {
  const renderPoolPrediction = (prediction: PostPoolPrediction) => {
    const predictionType = prediction.predictionType;
    return (
      <PredictionString
        grouping={getPredGroupingFromType(predictionType)}
        entity={getEntityStringFromPred(predictionType, prediction.content)}
        metric={getMetricFromPrediction(predictionType, prediction.content)}
        comparison={getComparisonFromPrediction(
          predictionType,
          prediction.content
        )}
        value={getPredictedValueFromPrediction(
          predictionType,
          prediction.content
        )}
        date={
          GAME_PRED_TYPES.includes(predictionType)
            ? new Date(
                (prediction as PostGamePoolPrediction).gameDetails.gameStartTime
              )
            : undefined
        }
        season={
          SEASON_PRED_TYPES.includes(predictionType)
            ? (prediction as PostSeasonPoolPrediction).seasonDetails
            : undefined
        }
        league={prediction.league}
        negatedPrediction={
          isFuturePred(prediction.content, predictionType)
            ? prediction.content.negatedPrediction
            : undefined
        }
        comparisonPredictionContent={
          (prediction as PostComparisonPoolPrediction).content
        }
        comparisonPredictionType={
          (prediction as PostComparisonPoolPrediction).predictionType
        }
      />
    );
  };

  return (
    <>
      <div>
        {predictions?.length == 0 ? (
          <div> No predictions to display. </div>
        ) : (
          predictions.map((prediction, index: number) => (
            <div className="pool-prediction" key={index}>
              {renderPoolPrediction(prediction)}
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default PoolPredictionsList;
