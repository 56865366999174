import { useQuery } from '@tanstack/react-query';
import { checkAdmin } from 'api/admin';

export type CheckAdminResult = {
  isAdmin: boolean;
};

export default function useCheckAdmin(userId: string | undefined) {
  const queryResponse = useQuery<CheckAdminResult>({
    queryKey: ['adminCheck', userId],
    queryFn: async () => await checkAdmin(userId || ''),
    enabled: !!userId,
  });

  if (queryResponse.isError) {
    console.error('Error checking admin: ', queryResponse.error);
  }

  return {
    isAdmin: queryResponse.data?.isAdmin,
    isAdminLoading: queryResponse.isLoading,
    isAdminError: queryResponse.isError,
    fetchingIsAdmin: queryResponse.isFetching,
  };
}
