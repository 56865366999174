import React, { useEffect, useState } from 'react';

import { Dropdown } from 'components/AddPredictionForm/components/Dropdown';
import { renderMetricItem } from 'components/AddPredictionForm/components/RenderItems';
import { type WizardStepProps } from 'screens/AddPrediction/types';
import { type MetricOption } from 'types/addPrediction';
import { filterMetrics, getMetricItems, setMetricLabel } from 'utils/addPred';

// TODO [#79]: Refactor AddPredictionForm to use Wizard

export const MetricSelector = ({
  predContent,
  setPredContent,
}: WizardStepProps) => {
  // Manage Prediction Content State
  const [metric, setMetric] = useState<MetricOption | undefined>(
    predContent.metric
  );

  useEffect(() => {
    setPredContent({
      ...predContent,
      metric,
    });
  }, [metric]);

  return (
    <div className="form-dropdown-container">
      <p>Select Metric</p>
      <Dropdown
        accessibilityLabel="Metric Picker"
        placeholder="Select Metric"
        items={getMetricItems(
          predContent.league,
          predContent.category,
          predContent.entity,
          predContent.duration
        )}
        value={metric}
        renderItem={renderMetricItem}
        onItemSelect={setMetric}
        filterFn={filterMetrics}
        setItemLabel={setMetricLabel}
        clearDisabled={false}
        loading={false}
        showItemsOnClick={false}
      />
    </div>
  );
};
