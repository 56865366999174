import {
  isCollegePlayer,
  isMlbPlayer,
  isNbaPlayer,
  isNflPlayer,
  isNhlPlayer,
} from './player';
import { isCollegeTeam } from './teams';
import { type PlayerInfo, type TeamInfo } from 'types/prediction';
import { type Player, type Team } from 'types/sportsdata';

export const convertSportsDataPlayerToPlayerInfo = (
  player: Player
): PlayerInfo => {
  return {
    playerID: `${player.PlayerID}`,
    team: player.Team,
    teamID: `${player.TeamID}`,
    firstName: player.FirstName,
    lastName: player.LastName,
    positionCategory: !isCollegePlayer(player)
      ? player.PositionCategory
      : undefined,
    position: player.Position,
    status: !isCollegePlayer(player) ? player.Status : undefined,
    height: player.Height.toString(),
    weight: `${player.Weight}`,
    photoUrl: !isCollegePlayer(player) ? player.PhotoUrl : undefined,
    college: !isCollegePlayer(player) ? player.College : undefined,
    experience: !isCollegePlayer(player) ? `${player.Experience}` : undefined,
    injuryStatus: player.InjuryStatus,
    injuryBodyPart: player.InjuryBodyPart,
    injuryStartDate: player.InjuryStartDate,
    injuryNotes: player.InjuryNotes,
    jersey:
      isNbaPlayer(player) || isMlbPlayer(player) || isNhlPlayer(player)
        ? `${player.Jersey}`
        : undefined,
    highSchool:
      isNbaPlayer(player) || isMlbPlayer(player)
        ? player.HighSchool
        : undefined,
    number: isNflPlayer(player) ? `${player.Number}` : undefined,
  };
};

export const convertSportsDataTeamToTeamInfo = (team: Team): TeamInfo => {
  return {
    teamID: `${team.TeamID}`,
    key: team.Key,
    city: !isCollegeTeam(team) ? team.City : undefined,
    name: team.Name,
    school: isCollegeTeam(team) ? team.School : undefined,
    shortDisplayName: isCollegeTeam(team) ? team.ShortDisplayName : undefined,
    teamLogoUrl: isCollegeTeam(team) ? team.TeamLogoUrl : undefined,
    stadiumID: !isCollegeTeam(team) ? `${team.StadiumID}` : undefined,
    primaryColor: !isCollegeTeam(team) ? team.PrimaryColor : undefined,
    secondaryColor: !isCollegeTeam(team) ? team.SecondaryColor : undefined,
    tertiaryColor: !isCollegeTeam(team) ? team.TertiaryColor : undefined,
    quaternaryColor: !isCollegeTeam(team) ? team.QuaternaryColor : undefined,
    globalTeamID: `${team.GlobalTeamID}`,
  };
};
