import { isPlayer } from './player';
import { ALL_LEAGUE_METRICS, ALL_LEAGUE_SEASONS } from 'constants/.';
import { type PredictionCategory } from 'types/addPrediction';
import {
  type ComparisonChildStatisticPredictionContent,
  type ComparisonPredictionContent,
  type FuturePredictionContent,
  type GameDetails,
  type GamePrediction,
  type MetricPredictionContent,
  type OutcomePredictionContent,
  type Prediction,
  type PredictionGrouping,
  type PredictionIdentifier,
  type SeasonDetails,
  type SeasonPrediction,
  type TeamInfo,
} from 'types/prediction';
import {
  type PredictionContent,
  type PredictionType,
  type StatisticPredictionContent,
} from 'types/prediction';
import { type League, type Player, type Team } from 'types/sportsdata';

// Entity breakdown
const PLAYER_PRED_TYPES: PredictionType[] = [
  'PlayerGameStatistic',
  'PlayerSeasonStatistic',
  'PlayerSeasonFuture',
];

const TEAM_PRED_TYPES: PredictionType[] = [
  'TeamGameStatistic',
  'TeamSeasonStatistic',
  'TeamGameOutcome',
  'TeamSeasonOutcome',
  'TeamSeasonFuture',
];

//Duration breakdown
export const GAME_PRED_TYPES: PredictionType[] = [
  'PlayerGameStatistic',
  'TeamGameStatistic',
  'TeamGameOutcome',
];

export const SEASON_PRED_TYPES: PredictionType[] = [
  'PlayerSeasonStatistic',
  'TeamSeasonStatistic',
  'TeamSeasonOutcome',
  'PlayerSeasonFuture',
  'TeamSeasonFuture',
];

// Source breakdown
const STAT_PRED_TYPES: PredictionType[] = [
  'TeamGameStatistic',
  'TeamSeasonStatistic',
  'PlayerGameStatistic',
  'PlayerSeasonStatistic',
];

const OUTCOME_PRED_TYPES: PredictionType[] = [
  'TeamGameOutcome',
  'TeamSeasonOutcome',
];

const FUTURE_PRED_TYPES: PredictionType[] = [
  'PlayerSeasonFuture',
  'TeamSeasonFuture',
];

export const isStatisticPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is StatisticPredictionContent => {
  return STAT_PRED_TYPES.includes(predType);
};

export const isComparisonPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is StatisticPredictionContent => {
  return (
    isStatisticPred(predContent, predType) || predType === 'TeamSeasonOutcome'
  );
};

export const isPlayerGameStatisticPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is StatisticPredictionContent => {
  return predType === 'PlayerGameStatistic';
};

export const isTeamGameStatisticPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is StatisticPredictionContent => {
  return predType === 'TeamGameStatistic';
};

export const isPlayerSeasonStatisticPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is StatisticPredictionContent => {
  return predType === 'PlayerSeasonStatistic';
};

export const isTeamSeasonStatisticPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is StatisticPredictionContent => {
  return predType === 'TeamSeasonStatistic';
};

export const isOutcomePred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is OutcomePredictionContent => {
  return OUTCOME_PRED_TYPES.includes(predType);
};

export const isPlayerPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is StatisticPredictionContent => {
  return PLAYER_PRED_TYPES.includes(predType);
};

export const isTeamPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is StatisticPredictionContent => {
  return TEAM_PRED_TYPES.includes(predType);
};

export const isGamePred = (
  prediction: Prediction
): prediction is GamePrediction => {
  return GAME_PRED_TYPES.includes(prediction.metaData.type);
};

export const isSeasonPred = (
  prediction: Prediction
): prediction is SeasonPrediction => {
  return SEASON_PRED_TYPES.includes(prediction.metaData.type);
};

export const isFuturePred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is FuturePredictionContent => {
  return FUTURE_PRED_TYPES.includes(predType);
};

export const isPlayerFuture = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is FuturePredictionContent => {
  return predType === 'PlayerSeasonFuture';
};

export const isTeamFuture = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is FuturePredictionContent => {
  return predType === 'TeamSeasonFuture';
};

export const isCompareParentPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is ComparisonPredictionContent => {
  return predType === 'Comparison';
};

export const isCompareChildPred = (
  predContent: PredictionContent,
  predType: PredictionType
): predContent is ComparisonChildStatisticPredictionContent => {
  return predType === 'Comparison';
};

export const getEntityString = (entity: Team | Player | undefined) => {
  if (!entity) {
    return '';
  }

  if (isPlayer(entity)) {
    return `${entity.FirstName} ${entity.LastName}`;
  } else {
    return entity.Name;
  }
};

export const getEntityStringFromPred = (
  type: PredictionType,
  content: PredictionContent
) => {
  switch (type) {
    case 'PlayerGameStatistic':
    case 'PlayerSeasonStatistic':
      return (content as StatisticPredictionContent).player;
    case 'TeamGameStatistic':
    case 'TeamSeasonStatistic':
      return (content as StatisticPredictionContent).team;
    case 'TeamGameOutcome':
    case 'TeamSeasonOutcome':
      return (content as OutcomePredictionContent).teamInfo.name;
    case 'PlayerSeasonFuture': {
      const playerInfo = (content as FuturePredictionContent).playerInfo;
      return `${playerInfo?.firstName} ${playerInfo?.lastName}`;
    }
    case 'TeamSeasonFuture': {
      const teamInfo = (content as FuturePredictionContent).teamInfo;
      if (!teamInfo?.city) {
        return `${teamInfo?.name}`;
      }
      return `${teamInfo?.city} ${teamInfo?.name}`;
    }
  }
};

export const getMetricString = (metric?: string, perGame = false) => {
  const metricObj = ALL_LEAGUE_METRICS.find((item) => {
    const valMatch = item.value.toLowerCase() === metric?.toLowerCase();
    if (perGame) {
      return valMatch && item.perGame == perGame;
    }
    return valMatch;
  });

  return metricObj?.label ? metricObj.label : metric?.toLowerCase();
};

export const getMetricStringFromContent = (
  content: MetricPredictionContent,
  type: PredictionType
) => {
  if (isStatisticPred(content, type) || isCompareChildPred(content, type)) {
    return getMetricString(content.metric, content.perGame);
  } else {
    return getMetricString(content.metric);
  }
};

export function getComparisonString(comparison: string): string {
  switch (comparison) {
    case '<':
      return 'will have less than';
    case '>':
      return 'will have more than';
    case '=':
      return 'will have';
    case '>=':
      return 'will have greater than or equal to';
    case '<=':
      return 'will have less than or equal to';
    case '!=':
      return 'will not have';
    default:
      return 'invalid comparison';
  }
}

export const getOpponentString = (
  gameDetails?: GameDetails,
  teamInfo?: TeamInfo
) => {
  if (!teamInfo) {
    return '';
  }
  return teamInfo?.key == gameDetails?.homeTeam
    ? `vs ${gameDetails?.awayTeam}`
    : `@ ${gameDetails?.homeTeam}`;
};

export const getSeasonString = (season: SeasonDetails, league: League) => {
  const seasonObj = ALL_LEAGUE_SEASONS.find(
    (item) => item.seasonId == season.seasonId && item.league == league
  );

  return seasonObj?.label;
};

export const getPredGroupingFromType = (
  predType: PredictionType
): PredictionGrouping => {
  switch (predType) {
    case 'TeamGameStatistic':
    case 'PlayerGameStatistic':
      return 'GameStatistic';
    case 'PlayerSeasonStatistic':
    case 'TeamSeasonStatistic':
      return 'SeasonStatistic';
    case 'TeamGameOutcome':
      return 'GameOutcome';
    case 'TeamSeasonOutcome':
      return 'SeasonOutcome';
    case 'TeamSeasonFuture':
    case 'PlayerSeasonFuture':
      return 'SeasonFuture';
    case 'Comparison':
      return 'Comparison';
  }
};
export const getPredCategoryFromType = (
  predType: PredictionType
): PredictionCategory => {
  switch (predType) {
    case 'TeamGameStatistic':
    case 'PlayerGameStatistic':
    case 'PlayerSeasonStatistic':
    case 'TeamSeasonStatistic':
      return 'statistic';
    case 'TeamGameOutcome':
    case 'TeamSeasonOutcome':
      return 'outcome';
    case 'TeamSeasonFuture':
    case 'PlayerSeasonFuture':
      return 'future';
    case 'Comparison':
      return 'comparison';
  }
};

export function getPredictionIdentifier(
  prediction: Prediction
): PredictionIdentifier {
  return {
    predictionId: prediction.predictionId,
    predictionUserId: prediction.metaData.authorData.userId,
    createdAt: prediction.metaData.createdAt,
  };
}

export const getMetricFromPrediction = (
  type: PredictionType,
  content: PredictionContent
) => {
  if (isFuturePred(content, type)) {
    return getMetricString(content.futureId.split('#')[2]);
  }
  if (!isCompareParentPred(content, type)) {
    if (isStatisticPred(content, type) || isCompareChildPred(content, type)) {
      return getMetricString(content.metric, content.perGame);
    }
    return getMetricString(content.metric);
  }
  return '';
};

export const getComparisonFromPrediction = (
  type: PredictionType,
  content: PredictionContent
) => {
  if (isComparisonPred(content, type)) {
    return getComparisonString(content.comparison);
  }
  if (isCompareParentPred(content, type)) {
    return content.comparison;
  }
  return '';
};

export const getPredictedValueFromPrediction = (
  type: PredictionType,
  content: PredictionContent
) => {
  if (
    !isFuturePred(content, type) &&
    !(isCompareParentPred(content, type) || isCompareChildPred(content, type))
  ) {
    return content.predictedValue as string;
  }
  return '';
};
