import React from 'react';

import { PredictionString } from 'components/PredictionString';
import { type PoolPrediction } from 'types/pools';
import { type ComparisonPrediction } from 'types/prediction';
import {
  GAME_PRED_TYPES,
  getComparisonFromPrediction,
  getEntityStringFromPred,
  getMetricFromPrediction,
  getPredGroupingFromType,
  getPredictedValueFromPrediction,
  isFuturePred,
  SEASON_PRED_TYPES,
} from 'utils/prediction';

type PoolPredictionPreviewProps = {
  prediction: PoolPrediction;
};

export const PoolPredictionPreview = ({
  prediction,
}: PoolPredictionPreviewProps) => {
  const predictionType = prediction.metaData.type;

  return (
    <PredictionString
      grouping={getPredGroupingFromType(predictionType)}
      entity={getEntityStringFromPred(predictionType, prediction.content)}
      metric={getMetricFromPrediction(predictionType, prediction.content)}
      comparison={getComparisonFromPrediction(
        predictionType,
        prediction.content
      )}
      value={getPredictedValueFromPrediction(
        predictionType,
        prediction.content
      )}
      date={
        GAME_PRED_TYPES.includes(predictionType) && prediction.gameDetails
          ? new Date(prediction.gameDetails.gameStartTime)
          : undefined
      }
      season={
        SEASON_PRED_TYPES.includes(predictionType)
          ? prediction.seasonDetails
          : undefined
      }
      league={prediction.league}
      negatedPrediction={
        isFuturePred(prediction.content, predictionType)
          ? prediction.content.negatedPrediction
          : undefined
      }
      comparisonPredictionContent={(prediction as ComparisonPrediction).content}
      comparisonPredictionType={
        (prediction as ComparisonPrediction).metaData.type
      }
    />
  );
};

export default PoolPredictionPreview;
