import { CBB_PLAYER_FUTURES } from './futures/cbb/cbbPlayerFutures';
import { CBB_TEAM_FUTURES } from './futures/cbb/cbbTeamFutures';
import { CFB_PLAYER_FUTURES } from './futures/cfb/cfbPlayerFutures';
import { CFB_TEAM_FUTURES } from './futures/cfb/cfbTeamFutures';
import { CWBB_TEAM_FUTURES } from './futures/cwbb/cwbbTeamFutures';
import { MLB_PLAYER_FUTURES } from './futures/mlb/mlbPlayerFutures';
import { MLB_TEAM_FUTURES } from './futures/mlb/mlbTeamFutures';
import { NBA_PLAYER_FUTURES } from './futures/nba/nbaPlayerFutures';
import { NBA_TEAM_FUTURES } from './futures/nba/nbaTeamFutures';
import { NFL_PLAYER_FUTURES } from './futures/nfl/nflPlayerFutures';
import { NFL_TEAM_FUTURES } from './futures/nfl/nflTeamFutures';
import { NHL_PLAYER_FUTURES } from './futures/nhl/nhlPlayerFutures';
import { NHL_TEAM_FUTURES } from './futures/nhl/nhlTeamFutures';
import { WNBA_TEAM_FUTURES } from './futures/wnba/wnbaTeamFutures';
import { CBB_METRICS, CBB_PER_GAME_METRICS } from './metrics/cbb';
import { CFB_TEAM_METRICS } from './metrics/cfb';
import { CWBB_METRICS } from './metrics/cwbb';
import { MLB_ALL_METRICS } from './metrics/mlb';
import { NBA_METRICS, NBA_PER_GAME_METRICS } from './metrics/nba';
import { NFL_ALL_METRICS } from './metrics/nfl';
import { NHL_ALL_METRICS } from './metrics/nhl';
import {
  HIDDEN_OUTCOME_GAME_METRICS,
  OUTCOME_GAME_METRICS,
  OUTCOME_SEASON_METRICS,
} from './metrics/outcome';
import { WNBA_METRICS } from './metrics/wnba';
import { CBB_SEASONS, CURRENT_CBB_FUTURES_SEASON } from './seasons/cbb';
import { CFB_SEASONS, CURRENT_CFB_FUTURES_SEASON } from './seasons/cfb';
import { CURRENT_CWBB_FUTURES_SEASON, CWBB_SEASONS } from './seasons/cwbb';
import { CURRENT_MLB_FUTURES_SEASON, MLB_SEASONS } from './seasons/mlb';
import { CURRENT_NBA_FUTURES_SEASON, NBA_SEASONS } from './seasons/nba';
import { CURRENT_NFL_FUTURES_SEASON, NFL_SEASONS } from './seasons/nfl';
import { CURRENT_NHL_FUTURES_SEASON, NHL_SEASONS } from './seasons/nhl';
import { CURRENT_WNBA_FUTURES_SEASON, WNBA_SEASONS } from './seasons/wnba';
import { type MetricOption } from 'types/addPrediction';

export * from './seasons/mlb';
export * from './seasons/nfl';
export * from './seasons/nba';
export * from './seasons/cbb';
export * from './seasons/cfb';
export * from './seasons/wnba';
export * from './seasons/cwbb';
export * from './seasons/nhl';
export const ALL_LEAGUE_SEASONS = [
  ...MLB_SEASONS,
  ...NFL_SEASONS,
  ...NBA_SEASONS,
  ...CBB_SEASONS,
  ...CFB_SEASONS,
  ...CWBB_SEASONS,
  ...WNBA_SEASONS,
  ...NHL_SEASONS,
  CURRENT_NFL_FUTURES_SEASON,
  CURRENT_NBA_FUTURES_SEASON,
  CURRENT_CBB_FUTURES_SEASON,
  CURRENT_MLB_FUTURES_SEASON,
  CURRENT_CFB_FUTURES_SEASON,
  CURRENT_CWBB_FUTURES_SEASON,
  CURRENT_WNBA_FUTURES_SEASON,
  CURRENT_NHL_FUTURES_SEASON,
];

export * from './metrics/mlb';
export * from './metrics/nba';
export * from './metrics/nfl';
export * from './metrics/cbb';
export * from './metrics/cfb';
export * from './metrics/wnba';
export * from './metrics/cwbb';
export * from './metrics/outcome';
export const ALL_LEAGUE_METRICS: MetricOption[] = [
  ...NBA_METRICS,
  ...WNBA_METRICS,
  ...NBA_PER_GAME_METRICS,
  ...MLB_ALL_METRICS,
  ...NFL_ALL_METRICS,
  ...CFB_TEAM_METRICS,
  ...OUTCOME_GAME_METRICS,
  ...HIDDEN_OUTCOME_GAME_METRICS,
  ...OUTCOME_SEASON_METRICS,
  ...NFL_PLAYER_FUTURES,
  ...NFL_TEAM_FUTURES,
  ...NBA_PLAYER_FUTURES,
  ...NBA_TEAM_FUTURES,
  ...CBB_METRICS,
  ...CBB_PER_GAME_METRICS,
  ...CBB_PLAYER_FUTURES,
  ...CBB_TEAM_FUTURES,
  ...MLB_PLAYER_FUTURES,
  ...MLB_TEAM_FUTURES,
  ...CFB_TEAM_FUTURES,
  ...CFB_PLAYER_FUTURES,
  ...CWBB_TEAM_FUTURES,
  ...WNBA_TEAM_FUTURES,
  ...NHL_PLAYER_FUTURES,
  ...NHL_TEAM_FUTURES,
  ...NHL_ALL_METRICS,
  ...CWBB_METRICS,
];

export const NUM_AND_PUNC_METRICS = ['PlusMinus'];

export const CONFIRMATION_TEXT = {
  message: 'Are you sure you want to make this take?',
  confirm: 'Confirm Take',
  cancel: 'Edit',
};
