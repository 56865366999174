import { Button } from 'components/Button';
import { Spinner } from 'components/Spinner';
import useDebounce from 'hooks/useDebounce';
import useSearchUsers from 'hooks/useUsernames';
import { type UserSummary } from 'types/user';

export type UserListProps = {
  chosenAuthor?: UserSummary;
  setChosenAuthor: (user?: UserSummary) => void;
  query: string;
};

const UserList = ({
  chosenAuthor,
  setChosenAuthor,
  query = '',
}: UserListProps) => {
  const debounceDelay = 500;
  const debouncedSearchQuery = useDebounce(query, debounceDelay);

  const {
    searchUserResults,
    searchUsersError,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useSearchUsers(debouncedSearchQuery);

  if (chosenAuthor) {
    return <></>;
  }
  if (isFetching) {
    return <Spinner />;
  }

  if (searchUsersError) {
    return (
      <div>
        <p>Error getting users</p>
      </div>
    );
  }

  return (
    <div>
      {searchUserResults && (
        <div style={{ overflowY: 'scroll' }}>
          {searchUserResults.map((group, index) => (
            <div key={index}>
              {(group.users || []).map((user) => (
                <div key={user.userId} onClick={() => setChosenAuthor(user)}>
                  <div> {`${user.firstName} ${user.lastName}`} </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {hasNextPage && (
        <Button
          accessibilityLabel="load more users"
          type="primary"
          text="Load More"
          handleOnClick={fetchNextPage}
        />
      )}
    </div>
  );
};

export default UserList;
