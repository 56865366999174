import {
  type ComparisonOption,
  type DurationOption,
  type LeagueOption,
  type PredictionCategoryOption,
} from 'types/addPrediction';
import { getComparisonString } from 'utils/prediction';

export const LEAGUE_OPTIONS: LeagueOption[] = [
  {
    label: 'NFL',
    value: { id: 'nfl' },
  },
  {
    label: 'NBA',
    value: { id: 'nba' },
  },
  {
    label: 'MLB',
    value: { id: 'mlb' },
  },
  {
    label: 'NCAA Football',
    value: { id: 'cfb' },
  },
  {
    label: 'NCAA Basketball',
    value: { id: 'cbb' },
  },
  {
    label: 'WNBA',
    value: { id: 'wnba' },
  },
  {
    label: 'NCAA Womens Basketball',
    value: { id: 'cwbb' },
  },
  {
    label: 'NHL',
    value: { id: 'nhl' },
  },
];

export const CATEGORY_OPTIONS: PredictionCategoryOption[] = [
  {
    label: 'Win/Loss/Cover',
    value: { id: 'outcome' },
  },
  {
    label: 'Stats',
    value: { id: 'statistic' },
  },
  {
    label: 'Futures',
    value: { id: 'future' },
  },
  {
    label: 'Compare',
    value: { id: 'comparison' },
  },
];

export const COMPARISON_OPTIONS: ComparisonOption[] = [
  {
    label: 'more than',
    value: { id: '>', operator: '>', stringValue: getComparisonString('>') },
  },
  {
    label: 'less than',
    value: { id: '<', operator: '<', stringValue: getComparisonString('<') },
  },
  {
    label: 'equal to',
    value: { id: '=', operator: '=', stringValue: getComparisonString('=') },
  },
];

export const DURATION_OPTIONS: DurationOption[] = [
  {
    label: 'Game',
    value: { id: 'game' },
  },
  {
    label: 'Season',
    value: { id: 'season' },
  },
];
