import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';

import { type ChallengeContent } from '../types';
import { Input } from 'components/Input';
import { UserSearch } from 'components/UserSearch';
import { type UserSummary } from 'types/user';

type ChallengeInputDetailsProps = {
  challengeContent: ChallengeContent;
  setChallengeContent: Dispatch<SetStateAction<ChallengeContent>>;
};

export const ChallengeInputDetails = ({
  challengeContent,
  setChallengeContent,
}: ChallengeInputDetailsProps) => {
  const [isAdminChallenge, setIsAdminChallenge] = useState(
    challengeContent.isAdminChallenge
  );
  const [challenger, setChallenger] = useState<UserSummary>();
  const [challengePoints, setChallengePoints] = useState(
    challengeContent.challengePoints
  );
  const [challengeName, setChallengeName] = useState(
    challengeContent.challengeName || ''
  );

  useEffect(() => {
    setChallengeContent({
      ...challengeContent,
      challengerId: challenger
        ? challenger.userId
        : challengeContent.challengerId,
      challengePoints: challengePoints
        ? challengePoints
        : challengeContent.challengePoints,
      challengeName: challengeName
        ? challengeName
        : challengeContent.challengeName,
      isAdminChallenge: !!isAdminChallenge,
    });
  }, [challenger, challengePoints, challengeName, isAdminChallenge]);

  return (
    <div className="challenge-input-container">
      <div className="challenge-input-row">
        <label style={{ flex: 1 }}>Challenger:</label>
        <div style={{ flex: 3 }}>
          <UserSearch
            chosenAuthor={challenger}
            setChosenAuthor={setChallenger}
          />
        </div>
      </div>
      <div className="challenge-input-row">
        <label style={{ flex: 1 }}>Challenge Points: </label>
        <Input
          id="challengePointsInput"
          accessibilityLabel="challenge points input"
          value={String(challengePoints)}
          handleOnChange={(val) => setChallengePoints(Number(val))}
          containerStyle={{ flex: 1 }}
          placeholder="Challenge Points"
        />
        <input
          type="range"
          id="challengePoints"
          min="0"
          max="100"
          value={challengePoints}
          step="1"
          onChange={(e) => setChallengePoints(Number(e.target.value))}
          style={{ flex: 2, marginLeft: '-12px', background: 'purple' }}
        />
      </div>
      <div className="challenge-input-row">
        <label style={{ flex: 1 }}>
          Admin Challenge:
          <input
            data-testid="adminChallenge"
            id="adminChallenge"
            type="checkbox"
            checked={isAdminChallenge}
            onChange={(e) => setIsAdminChallenge(e.target.checked)}
          />
        </label>
      </div>
      <div className="challenge-input-row">
        <label style={{ flex: 1 }}>Challenge Name (optional): </label>
        <Input
          id="challenge name"
          accessibilityLabel="challenge name input"
          value={challengeName}
          handleOnChange={setChallengeName}
          containerStyle={{ flex: 3 }}
          placeholder="Challenge Name"
        />
      </div>
    </div>
  );
};
