import './style.css';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button';
import { useAuth } from 'contexts/AuthContext';

export const Unauthorized = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <div className="unauthorized-container" data-testid="unauthorized-screen">
      <h2 className="unauthorized-text">
        User is not an authorized admin. Please email support@vlted.com to
        request access.
      </h2>
      <Button
        accessibilityLabel="logout-button"
        type="primary"
        text="Logout"
        handleOnClick={() => auth.logout(() => navigate('/login'))}
      />
    </div>
  );
};
