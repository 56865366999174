import { type Season } from 'types/sportsdata';

export const NFL_SEASONS: Season[] = [
  {
    seasonId: '2024PRE',
    seasonYear: '2024',
    seasonEndDate: '2024-08-27T00:00:00Z',
    label: '2024-2025 Preseason',
    league: 'nfl',
  },
  {
    seasonId: '2024REG',
    seasonYear: '2024',
    seasonEndDate: '2025-01-09T00:00:00Z',
    label: '2024-2025 Regular Season',
    league: 'nfl',
  },
  {
    seasonId: '2024POST',
    seasonYear: '2024',
    seasonEndDate: '2025-02-16T00:00:00Z',
    label: '2024-2025 Post Season',
    league: 'nfl',
  },
];

export const CURRENT_NFL_FUTURES_SEASON: Season = {
  seasonId: '2024',
  seasonYear: '2024',
  seasonEndDate: '2025-02-11T00:00:00Z',
  label: 'NFL 2024-2025 Season',
  league: 'nfl',
};
