import axios from 'axios';
import { getApiUrls } from 'config/api';

const { ADMIN_API_URL, API_URL } = getApiUrls();

export const searchUsers = async (searchQuery: string, pageParam = '') => {
  const searchQueries = searchQuery.split(' ');
  let url = `${API_URL}/searchUsers?`;
  let queries = '';
  for (const query of searchQueries) {
    if (queries) {
      queries = queries + '&';
    }
    queries = queries + `searchQuery=${query}`;
  }
  url = url + queries;
  if (pageParam) {
    url = url + `&cursor=${pageParam}`;
  }
  const { data } = await axios.get(url);
  return data.object;
};

export const getUsersByTrackingCode = async (trackingCode: string) => {
  const url = `${ADMIN_API_URL}/admin/users/${trackingCode}`;
  const { data } = await axios.get(url);
  return data.object;
};

export const markUsersPaid = async (userIds: string[]) => {
  const url = `${ADMIN_API_URL}/admin/markUsersPaid`;
  const { data } = await axios.patch(url, { userIds });
  return data.object;
};
