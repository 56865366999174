import { useQuery } from '@tanstack/react-query';

import { getSchedule } from 'api/sportsdata';
import { type Game, type League } from 'types/sportsdata';
import { getSportsDataDateString } from 'utils/date';

export default function useGames(
  date: Date,
  teamId: string | undefined,
  league: League
) {
  const dateString = getSportsDataDateString(date);
  const queryResponse = useQuery<Game[]>({
    queryKey: ['games', teamId, dateString],
    queryFn: async () => await getSchedule(league, dateString, teamId),
    // TODO: Add option for timeout cache invalidation
    enabled: !!teamId,
  });

  return {
    games: queryResponse.data,
    gamesLoading: queryResponse.isLoading,
    gamesError: queryResponse.isError,
  };
}
