import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

export type Environment = 'Preview' | 'Testflight' | 'Prod';

export interface EnvironmentContextProps {
  environment: Environment;
  setEnvironment: (env: Environment) => void;
}

const EnvironmentContext = createContext<EnvironmentContextProps | undefined>(
  undefined
);

export const EnvironmentProvider = ({ children }: { children: ReactNode }) => {
  //  get initial from process
  const [environment, setEnvironment] = useState<Environment>(() => {
    // Always return prod if the app is running in prod as we dont need environment swithes.
    if (process.env.REACT_APP_ENV === 'prod') {
      return 'Prod';
    }
    // Retrieve the saved environment from local storage or default to 'dev'
    return (localStorage.getItem('environment') as Environment) || 'Preview';
  });

  useEffect(() => {
    // Save the current environment to local storage whenever it changes
    localStorage.setItem('environment', environment);
  }, [environment]);

  return (
    <EnvironmentContext.Provider value={{ environment, setEnvironment }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = (): EnvironmentContextProps => {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error(
      'useEnvironment must be used within an EnvironmentProvider'
    );
  }
  return context;
};
