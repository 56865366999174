import axios from 'axios';
import { getApiUrls } from 'config/api';
import { type PostAnnouncement } from 'types/announcements';

const { API_URL } = getApiUrls();

export const postAnnouncement = async (body: PostAnnouncement) => {
  const { data } = await axios.post(`${API_URL}/announcement`, body);
  return data;
};
