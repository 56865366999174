export const Accents = {
  p1: '#AC8AF8',
  p2: '#754FF6',
  p3: '#5D24F5',
  p4: '#590DE1',
} as const;

export const Primary = {
  p900: '#2E1C8C',
  p800: '#41289A',
  p700: '#4C2FA2',
  p600: '#5837AB',
  p500: '#613CB0',
  p400: '#7859BC',
  p300: '#9076C7',
  p200: '#AF9ED7',
  p100: '#CFC4E6',
  p50: '#ECE7F5',
} as const;

export const Neutral = {
  n900: '#121212',
  n800: '#1E1E1E',
  n700: '#232323',
  n600: '#252525',
  n500: '#272727',
  n400: '#3C3C3C',
  n300: '#6C6C6C',
  n200: '#8F8F8F',
  n100: '#BCBCBC',
  n50: '#F9F9F9',
} as const;

export const Error = {
  dark: '#A93D3D',
  mid: '#FF7B7B',
  light: '#FFE0E0',
} as const;

export const Success = {
  dark: '#00241B',
  mid: '#63C97F',
  light: '#054D3C',
} as const;

export const Text = {
  primary: '#F9F9F9',
  secondary: '#BCBCBC',
  disabled: '#6C6C6C',
} as const;

export const Action = {
  default: '#1561EF',
  hover: '#225BC7',
  press: '#1A4596',
  disabled: '#D8E0EB',
  error: '#CE2D22',
  success: '#06745B',
} as const;

export const Alert = {
  info: {
    bg: '#E4EEFF',
    text: '#225BC7',
  },
  error: {
    bg: '#FFEBEA',
    text: '#B22318',
  },
  warning: {
    bg: '#E4F5F1',
    text: '#054D3C',
  },
} as const;

export const Background = {
  dark: '#121212',
  overlay: 'rgba(0, 0, 0, 0.6)',
} as const;

export const themeColors = {
  primary: {
    50: Primary.p50,
    100: Primary.p100,
    200: Primary.p200,
    300: Primary.p300,
    400: Primary.p400,
    500: Primary.p500,
    600: Primary.p600,
    700: Primary.p700,
    800: Primary.p800,
    900: Primary.p900,
  },
  neutral: {
    50: Neutral.n50,
    100: Neutral.n100,
    200: Neutral.n200,
    300: Neutral.n300,
    400: Neutral.n400,
    500: Neutral.n500,
    600: Neutral.n600,
    700: Neutral.n700,
    800: Neutral.n800,
    900: Neutral.n900,
  },
  accent: {
    100: Accents.p1,
    200: Accents.p2,
    300: Accents.p3,
    400: Accents.p4,
  },
  button: {
    50: Accents.p1,
    100: Accents.p1,
    200: Accents.p2,
    300: Accents.p2,
    400: Accents.p3,
    500: Accents.p3,
    600: Accents.p4,
    700: Accents.p1,
    800: Accents.p1,
    900: Accents.p1,
  },
  background: Background.dark,
  overlay: Background.overlay,
  text: {
    primary: Text.primary,
    secondary: Text.secondary,
    disabled: Text.disabled,
  },
  action: {
    default: Action.default,
    hover: Action.hover,
    press: Action.press,
    disabled: Action.disabled,
    error: Action.error,
    success: Action.success,
  },
  alert: {
    info: {
      bg: Alert.info.bg,
      text: Alert.info.text,
    },
    error: {
      bg: Alert.error.bg,
      text: Alert.error.text,
    },
    warning: {
      bg: Alert.warning.bg,
      text: Alert.warning.text,
    },
  },
  error: {
    dark: Error.dark,
    mid: Error.mid,
    light: Error.light,
  },
  success: {
    dark: Success.dark,
    mid: Success.mid,
    light: Success.light,
  },
};
