import { initializeApp } from 'firebase/app';

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIRE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIRE_APP_ID || '',
  measurementId: process.env.REACT_APP_FIRE_MEASUREMENT_ID || '',
};

export const firebaseInit = (): void => {
  // Initialize Firebase
  try {
    initializeApp(firebaseConfig);
  } catch {
    // ignore app already initialized error
  }
};
