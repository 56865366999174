import type { ComponentStyleConfig } from '@chakra-ui/react';

export const TextTheme: ComponentStyleConfig = {
  baseStyle: {
    color: 'text.primary',
    fontWeight: 400,
  },
  variants: {
    heading: {
      fontWeight: 900,
    },
    subheading: {
      fontWeight: 500,
    },
    body: {
      fontWeight: 400,
    },
    eyebrow: {
      fontWeight: 500,
      color: 'text.secondary',
      fontSize: '14px',
    },
  },
  defaultProps: {},
};
