import { type MetricOption } from 'types/addPrediction';

export const NHL_TEAM_FUTURES: MetricOption[] = [
  {
    value: 'ChampionshipWinner',
    label: 'Championship Winner',
  },
  {
    value: 'WesternConferenceChampion',
    label: 'Western Conference Champion',
  },
  {
    value: 'EasternConferenceChampion',
    label: 'Eastern Conference Champion',
  },
  {
    value: 'WesternConferenceFinals',
    label: 'To Make Western Conference Finals',
  },
  {
    value: 'EasternConferenceFinals',
    label: 'To Make Eastern Conference Finals',
  },
  {
    value: 'SecondRound',
    label: 'To Make The Second Round',
  },
  {
    value: 'PlayoffsAppearance',
    label: 'To Make The Playoffs',
  },
  {
    value: 'WildcardPlayoffs',
    label: 'Be a Wildcard Playoffs Team',
  },
  {
    value: 'MissthePlayoffs',
    label: 'Miss the Playoffs',
  },
  {
    value: 'FirstPlaceDivision',
    label: 'First Place in their division',
  },
  {
    value: 'LastPlaceDivision',
    label: 'Last Place in their division',
  },
  {
    value: 'PresidentsTrophyWinner',
    label: 'Presidents Trophy Winner',
  },
  {
    value: 'BestPowerPlayTeam',
    label: 'Best Power Play Team',
  },
  {
    value: 'BestPowerKillTeam',
    label: 'Best Power Kill Team',
  },
];
