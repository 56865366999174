export const LIVE_REFRESH_INTERVAL = 15000;

const ADMIN_DEV_URL =
  'https://6r3x4k8ztd.execute-api.us-west-2.amazonaws.com/dev';
const DEV_URL =
  'https://g4yekf2946.execute-api.us-west-2.amazonaws.com/dev/admin';
const STAGING_URL =
  'https://c3f8jlllgh.execute-api.us-west-2.amazonaws.com/staging/admin';
const ADMIN_STAGING_URL =
  'https://5fwd80kfd0.execute-api.us-west-2.amazonaws.com/staging';
const ADMIN_PROD_URL =
  'https://864wd7ynm4.execute-api.us-west-2.amazonaws.com/prod';
const PROD_URL =
  'https://qkm1j8ip18.execute-api.us-west-2.amazonaws.com/prod/admin';
const DEV_SHARE_BASE_URL = 'https://dev-app.vlted.com';
const PROD_SHARE_BASE_URL = 'https://app.vlted.com';

type Environment = 'Preview' | 'Testflight' | 'Prod';

const environment: Environment =
  (localStorage.getItem('environment') as Environment) || 'Preview';

export const getApiUrls = () => {
  // const environment = getEnvironment();

  let url = DEV_URL;
  let adminUrl = ADMIN_DEV_URL;
  let shareBaseUrl = DEV_SHARE_BASE_URL;

  if (environment === 'Testflight') {
    url = STAGING_URL;
    adminUrl = ADMIN_STAGING_URL;
    // Note - Sharing will not work in testflight unless we set up that configuration
    shareBaseUrl = DEV_SHARE_BASE_URL;
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    url = PROD_URL;
    adminUrl = ADMIN_PROD_URL;
    shareBaseUrl = PROD_SHARE_BASE_URL;
  }

  return {
    API_URL: url,
    ADMIN_API_URL: adminUrl,
    SHARE_BASE_URL: shareBaseUrl,
  };
};
