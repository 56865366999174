import { useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { postHotTake } from 'api/hotTakes';
import { AddPredictionForm } from 'components/AddPredictionForm';
import { DateInput } from 'components/AddPredictionForm/components/DateInput';
import { type CreateComparisonPrediction } from 'components/AddPredictionForm/types';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import {
  CATEGORY_OPTIONS,
  COMPARISON_OPTIONS,
  DURATION_OPTIONS,
  LEAGUE_OPTIONS,
} from 'constants/options';
import { Modal } from 'screens/AddHotTake/components/Modal';
import { type MetricOption } from 'types/addPrediction';
import { type PostHotTake } from 'types/hotTakes';
import { type PredictionType } from 'types/prediction';
import {
  type Entity,
  type Game,
  type League,
  type Season,
  type Team,
} from 'types/sportsdata';
import {
  getComparisonPredictionContent,
  getFuturePredContent,
  getGameDetails,
  getGameOutcomePredContent,
  getPredictionGrouping,
  getPredictionType,
  getSeasonDetails,
  getSeasonOutcomePredContent,
  getStatPredContent,
  isValidSpread,
  standardizeUTCDate,
} from 'utils/addPred';
import { getEntityString, getMetricString } from 'utils/prediction';

export const AddHotTake = () => {
  const navigate = useNavigate();

  // Prediction Content
  const [league, setLeague] = useState(LEAGUE_OPTIONS[0].value);
  const [leagueTeams, setLeagueTeams] = useState<Team[]>([]);
  const [entity, setEntity] = useState<Entity>();
  const [category, setCategory] = useState(CATEGORY_OPTIONS[0].value);
  const [duration, setDuration] = useState(DURATION_OPTIONS[0].value);
  const [metric, setMetric] = useState<MetricOption>();
  const [metricItems, setMetricItems] = useState<MetricOption[]>([]);
  const [value, setValue] = useState<string>('');
  const [game, setGame] = useState<Game>();
  const [comparison, setComparison] = useState(COMPARISON_OPTIONS[0].value);
  const [season, setSeason] = useState<Season>();
  const [hasPoints, setHasPoints] = useState(false);
  const [agreePoints, setAgreePoints] = useState(0);
  const [disagreePoints, setDisagreePoints] = useState(0);
  const [comparisonPrediction, setComparisonPrediction] =
    useState<CreateComparisonPrediction>({
      // Initialize the duration for both predictions to game this will need to change
      // when we have more than 2 comparison predictions
      predictions: {
        '1': {
          duration: {
            id: 'game',
          },
        },
        '2': {
          duration: {
            id: 'game',
          },
        },
      },
    } as CreateComparisonPrediction);

  // Page Status
  const [placingPrediction, setPlacingPrediction] = useState(false);

  // Confirmation Modal
  const [showModal, setShowModal] = useState(false);

  // Hot Take Content
  const [hotTakeStartTime, setHotTakeStartTime] = useState<Date>(
    new Date(Date.now())
  );
  const [hotTakeEndTime, setHotTakeEndTime] = useState<Date>(
    new Date(Date.now())
  );

  const comparisonPredictionSubmitEnabled = () => {
    const comparisonPredictions = comparisonPrediction.predictions;
    let enabled = true;
    for (const childPrediction of Object.values(comparisonPredictions)) {
      enabled = !!(
        childPrediction?.entity &&
        childPrediction?.metric?.value &&
        (childPrediction?.game || childPrediction?.season) &&
        comparison
      );
      if (!enabled) {
        return enabled;
      }
    }
    return enabled;
  };

  const submitEnabled = () => {
    const predictionType = getPredictionType(
      category.id,
      duration.id,
      entity as Entity
    );
    switch (predictionType) {
      case 'PlayerGameStatistic':
      case 'TeamGameStatistic':
        return !!(entity && metric?.value && comparison && value && game);
      case 'PlayerSeasonStatistic':
      case 'TeamSeasonStatistic':
      case 'TeamSeasonOutcome':
        return !!(entity && metric?.value && comparison && value && season);
      case 'TeamGameOutcome':
        if (metric?.value === 'spread') {
          return !!(entity && metric?.value && isValidSpread(value) && game);
        } else {
          return !!(entity && metric?.value && game);
        }
      case 'PlayerSeasonFuture':
      case 'TeamSeasonFuture':
        return !!(entity && metric?.value);
      case 'Comparison':
        return comparisonPredictionSubmitEnabled();
      default:
        return false;
    }
  };

  const getPredPostBody = (): PostHotTake => {
    const predictionType = getPredictionType(
      category.id,
      duration.id,
      entity as Entity
    );

    type postBodyBaseType = {
      predictionType: PredictionType;
      hotTakeStartTime: string;
      hotTakeEndTime: string;
      league?: League;
    };

    const postBodyBase: postBodyBaseType = {
      predictionType,
      hotTakeStartTime: hotTakeStartTime.toISOString(),
      hotTakeEndTime: hotTakeEndTime.toISOString(),
      league: league.id,
    };

    let content;
    let gameDetails;
    let seasonDetails;
    let postBody: PostHotTake;
    switch (predictionType) {
      case 'PlayerGameStatistic':
      case 'TeamGameStatistic':
        content = getStatPredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          comparison,
          value,
          leagueTeams,
        });
        gameDetails = getGameDetails(game as Game);
        postBody = {
          ...postBodyBase,
          content,
          gameDetails,
        };
        break;
      case 'PlayerSeasonStatistic':
      case 'TeamSeasonStatistic':
        content = getStatPredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          comparison,
          value,
          leagueTeams,
        });
        seasonDetails = getSeasonDetails(season as Season);
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'TeamGameOutcome':
        content = getGameOutcomePredContent({
          team: entity as Team,
          metric: metric as MetricOption,
          value,
          leagueTeams,
          game: game as Game,
        });
        gameDetails = getGameDetails(game as Game);
        postBody = {
          ...postBodyBase,
          content,
          gameDetails,
        };
        break;
      case 'TeamSeasonOutcome':
        content = getSeasonOutcomePredContent({
          team: entity as Team,
          metric: metric as MetricOption,
          comparison,
          value,
        });
        seasonDetails = getSeasonDetails(season as Season);
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'PlayerSeasonFuture':
      case 'TeamSeasonFuture':
        seasonDetails = getSeasonDetails(season as Season);
        content = getFuturePredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          leagueTeams,
          seasonId: season?.seasonId || '',
          league: league.id as League,
        });
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'Comparison':
        content = getComparisonPredictionContent({
          comparisonPrediction: comparisonPrediction,
          comparison: comparison,
          leagueTeams: leagueTeams,
          league: league.id as League,
        });
        postBody = {
          ...postBodyBase,
          content,
        };
        break;
    }
    if (hasPoints) {
      postBody.hotTakePointsInfo = {
        hotTakePointsAgree: agreePoints,
        hotTakePointsDisagree: disagreePoints,
      };
    }
    return postBody;
  };

  const submitPrediction = async () => {
    const postBody = getPredPostBody();

    // TODO: Optimistic Mutation?
    setPlacingPrediction(true);
    try {
      await postHotTake(postBody);
    } catch (err) {
      console.error('Error adding hot take.', err);
    }
    // TODO: Invalidate HotTake query once view hot takes its completed.
    setPlacingPrediction(false);
    navigate('/viewHotTakes');
  };

  return (
    <div className="add-hot-take-container">
      <h2>Add Hot Take</h2>
      <AddPredictionForm
        league={league}
        setLeague={setLeague}
        category={category}
        setCategory={setCategory}
        entity={entity}
        setEntity={setEntity}
        leagueTeams={leagueTeams}
        setLeagueTeams={setLeagueTeams}
        metric={metric}
        setMetric={setMetric}
        metricItems={metricItems}
        setMetricItems={setMetricItems}
        duration={duration}
        setDuration={setDuration}
        game={game}
        setGame={setGame}
        season={season}
        setSeason={setSeason}
        value={value}
        setValue={setValue}
        comparison={comparison}
        setComparison={setComparison}
        placingPrediction={placingPrediction}
        comparisonPrediction={comparisonPrediction}
        setComparisonPrediction={setComparisonPrediction}
      />
      <div>
        <p>Hot Take Start Time</p>
        <DateInput
          date={hotTakeStartTime}
          setDate={setHotTakeStartTime}
          disabled={placingPrediction}
          showTime={true}
        />
      </div>
      <div>
        <p>Hot Take End Time</p>
        <DateInput
          date={hotTakeEndTime}
          setDate={setHotTakeEndTime}
          disabled={placingPrediction}
          showTime={true}
        />
      </div>
      <div>
        <label>
          Add points to hot take?
          <input
            type="checkbox"
            aria-label={'hot-take-points-checkbox'}
            checked={hasPoints}
            onChange={() => {
              setHasPoints(!hasPoints);
            }}
          />
        </label>
        {hasPoints && (
          <div className="hot-take-points-container">
            <label>Agree points: </label>
            <Input
              accessibilityLabel={'agree-points-input'}
              id={'agree-points-input'}
              key={'agree-points-input'}
              value={agreePoints}
              handleOnChange={(val) => {
                if (Number.isInteger(Number(val))) {
                  setAgreePoints(Number(val));
                }
              }}
              error={
                Number.isInteger(Number(agreePoints))
                  ? ''
                  : 'Must enter valid integer'
              }
            />
            <label>Disagree points: </label>
            <Input
              accessibilityLabel={'disagree-points-input'}
              id={'disagree-points-input'}
              key={'disagree-points-input'}
              value={disagreePoints}
              handleOnChange={(val) => {
                if (Number.isInteger(Number(val))) {
                  setDisagreePoints(Number(val));
                }
              }}
              error={
                Number.isInteger(Number(disagreePoints))
                  ? ''
                  : 'Must enter valid integer'
              }
            />
          </div>
        )}
      </div>
      <>
        <Button
          accessibilityLabel="Add Hot Take Button"
          handleOnClick={() => {
            setPlacingPrediction(true);
            setShowModal(true);
          }}
          type="primary"
          text="Add Hot Take"
          disabled={!submitEnabled() || placingPrediction}
          loading={placingPrediction}
        />
        {showModal ? (
          <Modal
            setShowModal={setShowModal}
            submitPrediction={submitPrediction}
            setPlacingPrediction={setPlacingPrediction}
            grouping={getPredictionGrouping(category.id, duration.id)}
            entity={getEntityString(entity)}
            metric={getMetricString(metric?.value, metric?.perGame)}
            comparison={comparison.stringValue}
            value={value}
            date={game && standardizeUTCDate(game.DateTimeUTC)}
            season={season}
            league={league.id}
            createComparisonPrediction={comparisonPrediction}
          />
        ) : null}
      </>
    </div>
  );
};

export default AddHotTake;
