import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  table: {
    maxHeight: '100%',
    border: '1px solid',
    borderColor: 'accent.100',
  },
  thead: {
    fontWeight: 'bold',
    color: 'accent.100',
  },
  th: {
    fontFamily: 'body',
    fontWeight: 'bold',
    bg: 'primary.800',
  },
  tbody: {
    borderColor: 'accent.100',
  },
  td: {
    fontFamily: 'Inter',
    color: 'text.primary',
  },
});

export const TableTheme = defineMultiStyleConfig({ baseStyle });
