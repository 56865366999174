import { VStack } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { Navigate, useLocation } from 'react-router-dom';
import { Spinner } from 'components/Spinner';
import { useAuth } from 'contexts/AuthContext';

type Props = {
  reroutePath: string;
  element: JSX.Element;
};

export const RequireAdmin = ({ reroutePath, element }: Props) => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.loading) {
    return (
      <VStack h="full" w="full" align="center" justify="center" gap="10px">
        <Text>Loading Admin Console...</Text>
        <Spinner />
      </VStack>
    );
  }

  if (!auth.isAdmin) {
    // Redirect the user to the provided route, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page in the future.
    return <Navigate to={reroutePath} state={{ from: location }} replace />;
  }

  return element;
};
