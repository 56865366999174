import './style.css';
import { useNavigate } from 'react-router-dom';
import { getApiUrls } from 'config/api';
import { type PoolSummary } from 'types/pools';
import { getDateTime } from 'utils/date';

type PoolPreviewCardProps = {
  pool: PoolSummary;
};

export const PoolPreviewCard = ({ pool }: PoolPreviewCardProps) => {
  const navigate = useNavigate();
  const encodedPoolIdentifier = encodeURIComponent(
    `${pool.goLiveTime}#${pool.poolId}`
  );
  const { SHARE_BASE_URL } = getApiUrls();

  return (
    <div className="pool-preview-container">
      <div
        className="pool-preview-click-area"
        onClick={() => {
          navigate('/pool/' + encodedPoolIdentifier);
        }}
      >
        <p className="pool-title"> {pool.poolTitle} </p>
        {pool.promotionInfo && (
          <p className="pool-promotion">$ promotional pool $</p>
        )}
        <div>
          <p className="pool-details">
            # of predictions: {pool.totalPredictions}
          </p>
        </div>
        <div>
          <p className="pool-details">
            open time: {getDateTime(new Date(pool.goLiveTime))}
            <br />
            close time: {getDateTime(new Date(pool.poolCloseTime))}
          </p>
          {pool.winner && (
            <p className="pool-details"> winner user id: {pool.winner}</p>
          )}
        </div>
      </div>
      <div onClick={() => null}>
        <p>
          Pool Share Link:{' '}
          {SHARE_BASE_URL + '/pool/' + pool.goLiveTime + '#' + pool.poolId}
        </p>
      </div>
    </div>
  );
};

export default PoolPreviewCard;
