import { type TournamentStatusOption } from 'types/tournaments';

export const TOURNAMENT_STATUS_OPTIONS: TournamentStatusOption[] = [
  {
    label: 'Open',
    value: { id: 'OPEN' },
  },
  {
    label: 'Inactive',
    value: { id: 'INACTIVE' },
  },
  {
    label: 'Closed',
    value: { id: 'CLOSED' },
  },
  {
    label: 'Finished',
    value: { id: 'FINISHED' },
  },
  {
    label: 'Hidden Open',
    value: { id: 'HIDDENOPEN' },
  },
];
