import './style.css';

type UserMetricValueBoxProps = {
  metricName: string;
  metricValue: string;
};

export const UserMetricValueBox = ({
  metricName,
  metricValue,
}: UserMetricValueBoxProps) => {
  return (
    <div className="metric-container">
      <p className="metric-name">{metricName}</p>
      <p className="metric-value">{metricValue}</p>
    </div>
  );
};

export default UserMetricValueBox;
