import { type ReactNode } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { SidebarNav } from 'components/SidebarNav';
import { useAuth } from 'contexts/AuthContext';

import './style.css';

const NavigationWrapper = ({ children }: { children: ReactNode }) => {
  const { isAdmin, firebaseUser, loading } = useAuth();

  if (!firebaseUser || !isAdmin || loading) {
    return (
      <Box h="100vh" w="100vw" overflow="scroll">
        {children}
      </Box>
    );
  }
  return (
    <HStack alignItems="flex-start" h="100vh" w="100%">
      <SidebarNav />
      <Box h="100vh" w="100%" overflow="scroll">
        {children}
      </Box>
    </HStack>
  );
};

export { NavigationWrapper };
