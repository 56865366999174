import './style.css';
import UserMetricValueBox from './components/UserMetricValueBox';
import { FullScreenSpinner } from 'components/Spinner';
import useUserMetrics from 'hooks/useUserMetrics';

export const UserMetrics = () => {
  const {
    userMetrics,
    userMetricsLoading,
    userMetricsError,
    isFetchingUserMetrics,
  } = useUserMetrics();

  if (userMetricsLoading || isFetchingUserMetrics) {
    return <FullScreenSpinner />;
  }

  if (!userMetricsError) {
    return (
      <div>
        <div className="metrics-row">
          <p className="metrics-title">Active Users</p>
          <div className="metrics-squares">
            <UserMetricValueBox
              metricName={'In Past Day'}
              metricValue={
                userMetrics?.activeUserStats.dailyActiveUsers?.toString() || '-'
              }
            />
            <UserMetricValueBox
              metricName={'In Past Week'}
              metricValue={
                userMetrics?.activeUserStats.weeklyActiveUsers?.toString() ||
                '-'
              }
            />
            <UserMetricValueBox
              metricName={'In Past Month'}
              metricValue={
                userMetrics?.activeUserStats.monthlyActiveUsers?.toString() ||
                '-'
              }
            />
          </div>
        </div>
        <div className="metrics-row">
          <p className="metrics-title">Total Subscriptions</p>
          <div className="metrics-squares">
            <UserMetricValueBox
              metricName={'Total (Active and Expired)'}
              metricValue={
                userMetrics?.subscriptionStats.totalSubs?.toString() || '-'
              }
            />
            <UserMetricValueBox
              metricName={'Active Apple'}
              metricValue={
                userMetrics?.subscriptionStats.appleSubCount?.toString() || '-'
              }
            />
            <UserMetricValueBox
              metricName={'Active Stripe'}
              metricValue={
                userMetrics?.subscriptionStats.stripeSubCount?.toString() || '-'
              }
            />
          </div>
        </div>
        <div className="metrics-row">
          <p className="metrics-title">Expired Subscriptions</p>
          <div className="metrics-squares">
            <UserMetricValueBox
              metricName={'Total'}
              metricValue={
                userMetrics?.subscriptionStats.expiredSubs?.toString() || '-'
              }
            />
            <UserMetricValueBox
              metricName={'Expired In Past Day'}
              metricValue={
                userMetrics?.subscriptionStats.expiredPastDay?.toString() || '-'
              }
            />
            <UserMetricValueBox
              metricName={'Expired In Past Week'}
              metricValue={
                userMetrics?.subscriptionStats.expiredPastWeek?.toString() ||
                '-'
              }
            />
            <UserMetricValueBox
              metricName={'Expired In Past Month'}
              metricValue={
                userMetrics?.subscriptionStats.expiredPastMonth?.toString() ||
                '-'
              }
            />
          </div>
        </div>
        <div className="metrics-row">
          <p className="metrics-title">Subscription Trial Info</p>
          <div className="metrics-squares">
            <UserMetricValueBox
              metricName={'In Trial (Total)'}
              metricValue={
                userMetrics?.subscriptionStats.subsInTrial?.toString() || '-'
              }
            />
            <UserMetricValueBox
              metricName={'In Trial (3 Mo)'}
              metricValue={
                userMetrics?.subscriptionStats.activeThreeMonthTrials?.toString() ||
                '-'
              }
            />
            <UserMetricValueBox
              metricName={'In Trial (1 Mo)'}
              metricValue={
                userMetrics?.subscriptionStats.activeOneMonthTrials?.toString() ||
                '-'
              }
            />
            <UserMetricValueBox
              metricName={'In Trial (Canceled)'}
              metricValue={
                userMetrics?.subscriptionStats.canceledInTrial?.toString() ||
                '-'
              }
            />
            <UserMetricValueBox
              metricName={'Renewed After Trial'}
              metricValue={
                userMetrics?.subscriptionStats.renewedAfterTrial?.toString() ||
                '-'
              }
            />
          </div>
        </div>
        <div className="metrics-row">
          <p className="metrics-title"> Users with expired/canceled subs: </p>
          {userMetrics?.subscriptionStats.expiringSubscriptions.length == 0 ? (
            <div> No users to display. </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>UserId</th>
                  <th>Email</th>
                  <th>Expired</th>
                </tr>
              </thead>
              <tbody>
                {userMetrics?.subscriptionStats.expiringSubscriptions.map(
                  (user, index) => {
                    return (
                      <tr key={index}>
                        <td>{index}</td>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.username}</td>
                        <td>{user.userId}</td>
                        <td>{user.email}</td>
                        <td>{user.expired.toString()}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="metrics-row">
          <p className="metrics-title">Average Users Per Pool</p>
          <div className="metrics-squares">
            <UserMetricValueBox
              metricName={'Regular'}
              metricValue={
                userMetrics?.poolStats.usersPerRegPool.toString() || '-'
              }
            />
            <UserMetricValueBox
              metricName={'Promotional'}
              metricValue={
                userMetrics?.poolStats.usersPerPromoPool.toString() || '-'
              }
            />
          </div>
        </div>
        <div className="datadog-link">
          <a
            className="link-text"
            href="https://app.datadoghq.com/dashboard/qwp-zva-cnk/user-metrics-dashboard"
            target="_blank"
            rel="noreferrer"
          >
            Click to open Datadog Dashboard
          </a>
        </div>
      </div>
    );
  } else {
    return <p>Error Loading User Metrics.</p>;
  }
};

export default UserMetrics;
