import './style.css';
import { useNavigate } from 'react-router-dom';
import { getApiUrls } from 'config/api';
import { type TournamentSummary } from 'types/tournaments';
import { getDateTime } from 'utils/date';

type TournamentPreviewCardProps = {
  tournament: TournamentSummary;
};

export const TournamentPreviewCard = ({
  tournament,
}: TournamentPreviewCardProps) => {
  const navigate = useNavigate();
  const { SHARE_BASE_URL } = getApiUrls();

  return (
    <div className="tournament-preview-container">
      <div
        className="tournament-preview-click-area"
        onClick={() => {
          navigate('/tournament/' + tournament.tournamentId);
        }}
      >
        <p className="tournament-title"> {tournament.tournamentTitle} </p>
        <div>
          <p className="tournament-details">
            Max # of participants: {tournament.maxParticipants}
          </p>
        </div>
        <div>
          <p className="tournament-details">
            open time: {getDateTime(new Date(tournament.goLiveTime))}
            <br />
            close time: {getDateTime(new Date(tournament.closeTime))}
          </p>
        </div>
      </div>
      <div onClick={() => null}>
        <p>
          Tournament Share Link:
          {SHARE_BASE_URL +
            '/tournament/' +
            tournament.goLiveTime +
            '#' +
            tournament.tournamentId}
        </p>
      </div>
    </div>
  );
};

export default TournamentPreviewCard;
