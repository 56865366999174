import React from 'react';

import { type MetricOption } from 'types/addPrediction';
import { type Player, type Team } from 'types/sportsdata';
import { setTeamLabel } from 'utils/addPred';
import { isNflPlayer, isPlayer } from 'utils/player';
import { isCollegeTeam } from 'utils/teams';

export const renderTeamItem = (item: Team) => {
  if (isCollegeTeam(item)) {
    return <div aria-label={`${setTeamLabel(item)}`}>{setTeamLabel(item)}</div>;
  }
  return (
    <div
      aria-label={`${item.City} ${item.Name}`}
    >{`${item.City} ${item.Name}`}</div>
  );
};
export const renderMetricItem = (item: MetricOption) => {
  return <div aria-label={`${item.label}`}>{`${item.label}`}</div>;
};

export const renderEntityItem = (item: Player | Team) => {
  if (isPlayer(item)) {
    return renderPlayerItem(item);
  } else {
    return renderTeamItem(item);
  }
};

const renderPlayerItem = (item: Player) => {
  const playerLabel = isNflPlayer(item)
    ? `${item.FirstName} ${item.LastName} #${item.Number} [${item.Team}]`
    : `${item.FirstName} ${item.LastName} #${item.Jersey} [${item.Team}]`;
  return <div aria-label={`${playerLabel}`}>{playerLabel}</div>;
};
