import { useMutation } from '@tanstack/react-query';
import {
  inviteUsersToCommunity,
  respondToCommunityRequest,
  takeActionOnUser,
  type CommunityDetailsBody,
  type CommunityRequestBody,
  type TakeUserAction,
} from 'api/community';

export const useCommunityUserActions = (input: { communityId: string }) => {
  const { communityId } = input;

  const {
    mutateAsync: updateCommunityDetails,
    ...updateCommunityDetailsMutation
  } = useMutation({
    mutationFn: async (input: CommunityDetailsBody) => {
      await updateCommunityDetails(input);
    },
  });

  const { mutateAsync: respondToRequest, ...respondToRequestMutation } =
    useMutation({
      mutationFn: async (input: CommunityRequestBody) => {
        await respondToCommunityRequest(communityId, input);
      },
    });

  const { mutateAsync: inviteUsers, ...inviteUsersMutation } = useMutation({
    mutationFn: async (input: { userIds: string[] }) => {
      await inviteUsersToCommunity(communityId, input);
    },
  });

  const { mutateAsync: handleBlockStatus, ...blockStatusMutation } =
    useMutation({
      mutationFn: async (input: {
        userId: string;
        reaction: TakeUserAction;
      }) => {
        await takeActionOnUser({ communityId, ...input });
      },
    });

  return {
    updateCommunityDetails,
    updateCommunityDetailsMutation,
    respondToRequest,
    respondToRequestMutation,
    inviteUsers,
    inviteUsersMutation,
    handleBlockStatus,
    blockStatusMutation,
  };
};
