import { useQuery } from '@tanstack/react-query';
import {
  getCommunityDetails,
  getCommunityUsers,
  type GetCommunityDetailsOutput,
  type GetCommunityUserOption,
} from 'api/community';
import type { User } from 'types/user';

type UseCommunityDetailsInput = {
  communityId: string;
  userFilter: GetCommunityUserOption;
};

export const useCommunityDetails = (input: UseCommunityDetailsInput) => {
  const { communityId, userFilter } = input;

  const { data: community, ...communityQuery } =
    useQuery<GetCommunityDetailsOutput>({
      queryKey: ['getCommunityDetails', communityId],
      queryFn: async () => await getCommunityDetails(communityId),
    });

  const { data: usersResult, ...usersQuery } = useQuery<{ users: User[] }>({
    queryKey: ['getCommunityUsers', communityId, userFilter],
    queryFn: async () =>
      await getCommunityUsers({ communityId, filter: userFilter }),
  });

  return { community, communityQuery, usersResult, usersQuery };
};
