import axios from 'axios';

import { getApiUrls } from 'config/api';
import { type PostHotTake } from 'types/hotTakes';

const { ADMIN_API_URL } = getApiUrls();

export const postHotTake = async (body: PostHotTake) => {
  const { data } = await axios.post(`${ADMIN_API_URL}/admin/hotTake`, body);
  return data;
};

export const getHotTakes = async (pageParam = '', includeAll = false) => {
  let url = `${ADMIN_API_URL}/admin/hotTakes`;
  url = url + `?includeAll=${includeAll}`;
  if (pageParam) {
    url = url + `&cursor=${pageParam}`;
  }
  const { data } = await axios.get(url);
  return data.object;
};

export const deactivateHotTake = async (encodedHotTakeIdentifier: string) => {
  const url = `${ADMIN_API_URL}/admin/hotTake/${encodedHotTakeIdentifier}/deactivate`;
  const { data } = await axios.patch(url);
  return data;
};
