import React, { useEffect, useState } from 'react';

// TODO [#79]: Refactor AddPredictionForm to use Wizard
import { SeasonSelector as RadioSeasonSelector } from 'components/AddPredictionForm/components/SeasonSelector';
import { type WizardStepProps } from 'screens/AddPrediction/types';
import { type Season } from 'types/sportsdata';
import { getSeasonsForLeague } from 'utils/addPred';

export const SeasonSelector = ({
  predContent,
  setPredContent,
}: WizardStepProps) => {
  // Manage Prediction Content State
  const [season, setSeason] = useState<Season | undefined>(predContent.season);

  useEffect(() => {
    setPredContent({
      ...predContent,
      season,
    });
  }, [season]);

  return (
    <div className="form-dropdown-container">
      <p>Select Season</p>
      {/*
        TODO [#79]: Refactor component to manage all season selection logic when transitioning away
        from the AddPredictionForm components to the Wizard
      */}
      <RadioSeasonSelector
        disabled={false}
        seasons={getSeasonsForLeague(predContent.league)}
        onSeasonSelect={setSeason}
        selectedSeason={season}
      />
    </div>
  );
};
