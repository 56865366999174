import { useState } from 'react';
import { useParams } from 'react-router-dom';

import './style.css';
import 'screens/AddHotTake/style.css';
import { useNavigate } from 'react-router-dom';
import PoolPredictionsList from './components/PoolPredictionsList';
import { postRound } from 'api/tournaments';
import { AddPredictionForm } from 'components/AddPredictionForm';
import { DateInput } from 'components/AddPredictionForm/components/DateInput';
import { type CreateComparisonPrediction } from 'components/AddPredictionForm/types';
import { Button } from 'components/Button';
import {
  CATEGORY_OPTIONS,
  COMPARISON_OPTIONS,
  DURATION_OPTIONS,
  LEAGUE_OPTIONS,
} from 'constants/options';
import { type MetricOption } from 'types/addPrediction';
import {
  type PostPoolPrediction,
  type PostPoolPredictionBase,
} from 'types/pools';
import {
  type Entity,
  type Game,
  type League,
  type Season,
  type Team,
} from 'types/sportsdata';
import { type PostRound } from 'types/tournaments';
import {
  getComparisonPredictionContent,
  getFuturePredContent,
  getGameDetails,
  getGameOutcomePredContent,
  getPredictionType,
  getSeasonDetails,
  getSeasonOutcomePredContent,
  getStatPredContent,
  isValidSpread,
} from 'utils/addPred';

export const AddRound = () => {
  const navigate = useNavigate();

  const { tournamentIdentifier, currentRound, maxParticipants, authorUserId } =
    useParams();

  // Prediction Content
  const [league, setLeague] = useState(LEAGUE_OPTIONS[0].value);
  const [leagueTeams, setLeagueTeams] = useState<Team[]>([]);
  const [entity, setEntity] = useState<Entity>();
  const [category, setCategory] = useState(CATEGORY_OPTIONS[0].value);
  const [duration, setDuration] = useState(DURATION_OPTIONS[0].value);
  const [metric, setMetric] = useState<MetricOption>();
  const [metricItems, setMetricItems] = useState<MetricOption[]>([]);
  const [value, setValue] = useState<string>('');
  const [game, setGame] = useState<Game>();
  const [comparison, setComparison] = useState(COMPARISON_OPTIONS[0].value);
  const [season, setSeason] = useState<Season>();
  const [isTournamentRoundTieBreaker, setIsTournamentRoundTieBreaker] =
    useState<boolean>(false);
  const [roundHasTieBreaker, setRoundHasTieBreaker] = useState<boolean>(false);
  const [comparisonPrediction, setComparisonPrediction] =
    useState<CreateComparisonPrediction>({
      // Initialize the duration for both predictions to game this will need to change
      // when we have more than 2 comparison predictions
      predictions: {
        '1': {
          duration: {
            id: 'game',
          },
        },
        '2': {
          duration: {
            id: 'game',
          },
        },
      },
    } as CreateComparisonPrediction);

  // Page Status
  const [placingPrediction, setPlacingPrediction] = useState(false);
  const [placingRound, setPlacingRound] = useState(false);

  // Round Content
  const [roundPredictions, setRoundPredictions] = useState<
    PostPoolPrediction[]
  >([]);
  const tournamentId = decodeURIComponent(String(tournamentIdentifier));
  const numberOfWinners = Math.round(Number(maxParticipants) / 2);
  const [roundGoLiveTime, setRoundGoLiveTime] = useState<Date>(
    new Date(Date.now())
  );
  const [roundCloseTime, setRoundCloseTime] = useState<Date>(
    new Date(Date.now())
  );

  const [leagues, setLeagues] = useState<Set<League>>(new Set());

  const comparisonPredictionSubmitEnabled = () => {
    const comparisonPredictions = comparisonPrediction.predictions;
    let enabled = true;
    for (const childPrediction of Object.values(comparisonPredictions)) {
      enabled = !!(
        childPrediction?.entity &&
        childPrediction?.metric?.value &&
        (childPrediction?.game || childPrediction?.season) &&
        comparison
      );
      if (!enabled) {
        return enabled;
      }
    }
    return enabled;
  };

  const submitPredictionEnabled = () => {
    if (roundPredictions.length > 24) {
      return false;
    }
    const predictionType = getPredictionType(
      category.id,
      duration.id,
      entity as Entity
    );
    switch (predictionType) {
      case 'PlayerGameStatistic':
      case 'TeamGameStatistic':
        return !!(entity && metric?.value && comparison && value && game);
      case 'PlayerSeasonStatistic':
      case 'TeamSeasonStatistic':
      case 'TeamSeasonOutcome':
        return !!(entity && metric?.value && comparison && value && season);
      case 'TeamGameOutcome':
        if (metric?.value === 'spread') {
          return !!(entity && metric?.value && isValidSpread(value) && game);
        } else {
          return !!(entity && metric?.value && game);
        }
      case 'PlayerSeasonFuture':
      case 'TeamSeasonFuture':
        return !!(entity && metric?.value);
      case 'Comparison':
        return comparisonPredictionSubmitEnabled();
      default:
        return false;
    }
  };

  const submitRoundEnabled = () => {
    return (
      roundPredictions.length > 0 &&
      roundPredictions.length < 25 &&
      roundGoLiveTime &&
      roundCloseTime &&
      authorUserId &&
      Number(maxParticipants) &&
      Number(numberOfWinners) &&
      roundHasTieBreaker &&
      tournamentId
    );
  };

  const getPredPostBody = (): PostPoolPrediction => {
    const predictionType = getPredictionType(
      category.id,
      duration.id,
      entity as Entity
    );

    const postBodyBase: PostPoolPredictionBase = {
      predictionType,
      league: league.id,
      isTournamentRoundTieBreaker: isTournamentRoundTieBreaker,
    };

    let content;
    let gameDetails;
    let seasonDetails;
    let postBody: PostPoolPrediction;
    switch (predictionType) {
      case 'PlayerGameStatistic':
      case 'TeamGameStatistic':
        content = getStatPredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          comparison,
          value,
          leagueTeams,
        });
        gameDetails = getGameDetails(game as Game);
        postBody = {
          ...postBodyBase,
          content,
          gameDetails,
        };
        break;
      case 'PlayerSeasonStatistic':
      case 'TeamSeasonStatistic':
        content = getStatPredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          comparison,
          value,
          leagueTeams,
        });
        seasonDetails = getSeasonDetails(season as Season);
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'TeamGameOutcome':
        content = getGameOutcomePredContent({
          team: entity as Team,
          metric: metric as MetricOption,
          value,
          leagueTeams,
          game: game as Game,
        });
        gameDetails = getGameDetails(game as Game);
        postBody = {
          ...postBodyBase,
          content,
          gameDetails,
        };
        break;
      case 'TeamSeasonOutcome':
        content = getSeasonOutcomePredContent({
          team: entity as Team,
          metric: metric as MetricOption,
          comparison,
          value,
        });
        seasonDetails = getSeasonDetails(season as Season);
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'PlayerSeasonFuture':
      case 'TeamSeasonFuture':
        seasonDetails = getSeasonDetails(season as Season);
        content = getFuturePredContent({
          entity: entity as Entity,
          metric: metric as MetricOption,
          leagueTeams,
          seasonId: season?.seasonId || '',
          league: league.id as League,
        });
        postBody = {
          ...postBodyBase,
          content,
          seasonDetails,
        };
        break;
      case 'Comparison':
        content = getComparisonPredictionContent({
          comparisonPrediction: comparisonPrediction,
          comparison: comparison,
          leagueTeams: leagueTeams,
          league: league.id as League,
        });
        postBody = {
          ...postBodyBase,
          content,
        };
        break;
    }
    return postBody;
  };

  const submitRound = async () => {
    if (!authorUserId) {
      console.error('Error adding round: no author for round');
      return;
    }
    const postBody: PostRound = {
      tournamentIdentifier: tournamentId,
      poolTitle:
        'tournament ' + tournamentId.split('#')[1] + ' round ' + currentRound,
      predictions: roundPredictions,
      maxParticipants: Number(maxParticipants),
      goLiveTime: roundGoLiveTime,
      poolCloseTime: roundCloseTime,
      authorUserId: authorUserId,
      numberOfWinners: Number(numberOfWinners),
    };

    postBody.privacyStatus = 'hidden';

    // TODO[#56]: Optimistic Mutation?
    setPlacingRound(true);
    try {
      await postRound(postBody);
    } catch (err) {
      console.error('Error adding round.', err);
    }
    // TODO[#57]: Invalidate Round query once view pools its completed.
    setPlacingRound(false);
    navigate('/viewTournaments');
  };

  const addRoundPrediction = async () => {
    setPlacingPrediction(true);
    const postBody = getPredPostBody();
    if (isTournamentRoundTieBreaker) {
      setRoundHasTieBreaker(true);
    }
    const newPredictions = roundPredictions.concat(postBody);
    const newLeagues = leagues.add(league.id);
    setRoundPredictions(newPredictions);
    setLeagues(newLeagues);
    setPlacingPrediction(false);
  };

  const clearAllFields = async () => {
    setSeason(undefined);
    setEntity(undefined);
    setMetric(undefined);
    setIsTournamentRoundTieBreaker(false);
  };

  return (
    <div className="add-pool-container">
      <h2>Add Round (Pool) to Tournament</h2>
      <h3>Add Prediction for the Round</h3>
      <AddPredictionForm
        isForTournament={true}
        isTournamentRoundTieBreaker={isTournamentRoundTieBreaker}
        setIsTournamentRoundTieBreaker={setIsTournamentRoundTieBreaker}
        roundHasTieBreaker={roundHasTieBreaker}
        league={league}
        setLeague={setLeague}
        category={category}
        setCategory={setCategory}
        entity={entity}
        setEntity={setEntity}
        leagueTeams={leagueTeams}
        setLeagueTeams={setLeagueTeams}
        metric={metric}
        setMetric={setMetric}
        metricItems={metricItems}
        setMetricItems={setMetricItems}
        duration={duration}
        setDuration={setDuration}
        game={game}
        setGame={setGame}
        season={season}
        setSeason={setSeason}
        value={value}
        setValue={setValue}
        comparison={comparison}
        setComparison={setComparison}
        placingPrediction={placingPrediction}
        comparisonPrediction={comparisonPrediction}
        setComparisonPrediction={setComparisonPrediction}
      />
      <>
        <Button
          accessibilityLabel="Add Round Prediction Button"
          handleOnClick={() => {
            addRoundPrediction();
            clearAllFields();
          }}
          type="primary"
          text="Add Prediction to Round"
          disabled={!submitPredictionEnabled() || placingPrediction}
          loading={placingPrediction}
        />
        <Button
          accessibilityLabel="Clear Round Predictions Button"
          handleOnClick={() => {
            setRoundPredictions([]);
            setRoundHasTieBreaker(false);
          }}
          type="primary"
          text="Clear Predictions from Round"
          disabled={roundPredictions.length == 0}
        />
      </>
      <div>
        <h3>Current Predictions in Round</h3>
        <PoolPredictionsList predictions={roundPredictions} />
      </div>
      <div>
        <p>Round Go Live Time</p>
        <DateInput
          date={roundGoLiveTime}
          setDate={setRoundGoLiveTime}
          disabled={placingRound}
          showTime={true}
        />
      </div>
      <div>
        <p>Round Close Time</p>
        <DateInput
          date={roundCloseTime}
          setDate={setRoundCloseTime}
          disabled={placingRound}
          showTime={true}
        />
      </div>
      <div>
        <p>Max # Of Participants: {maxParticipants}</p>
      </div>
      <div>
        <p>Number of Winners: {numberOfWinners}</p>
      </div>
      <Button
        accessibilityLabel="Create Round Button"
        handleOnClick={() => {
          setPlacingRound(true);
          submitRound();
          clearAllFields();
        }}
        type="primary"
        text="Create Round"
        disabled={!submitRoundEnabled() || placingRound}
        loading={placingRound}
      />
    </div>
  );
};

export default AddRound;
