import { isGamePred, isSeasonPred } from './prediction';
import { type Prediction } from 'types/prediction';

type DateTimeFormatOptions = {
  weekday?: 'long' | 'short' | 'narrow';
  year?: 'numeric' | '2-digit';
  month?: 'long' | 'short' | 'narrow' | 'numeric' | '2-digit';
  day?: 'numeric' | '2-digit';
};

const dateOptions: DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export function getDateTime(date: Date): string {
  return date.toLocaleDateString() + ' @ ' + date.toLocaleTimeString();
}

/* Returns date in format Fri, July 8, 2022 */
export function dateToDateStringLong(date: Date): string {
  return date.toLocaleDateString(undefined, dateOptions);
}

/* Returns date in format 7/8/2022 from createdAt dates */
export function createdAtToDateStringShort(dateString: string): string {
  const date = new Date(+dateString * 1000);
  return date.toLocaleDateString('en-US') + ' @ ' + date.toLocaleTimeString();
}

/* Returns date in format 7/8/2022 from string */
export function predToDateStringShort(prediction: Prediction): string {
  let dateString = '';
  if (isGamePred(prediction)) {
    dateString = stringToDateStringShort(prediction.gameDetails?.gameStartTime);
  } else if (isSeasonPred(prediction)) {
    dateString = stringToDateStringShort(
      prediction.seasonDetails?.seasonEndDate
    );
  }
  return dateString;
}

export function stringToDateStringShort(date: string): string {
  return new Date(date).toLocaleDateString('en-US');
}

export const getSportsDataDateString = (date: Date): string => {
  const etDate = new Date(
    date.toLocaleString('en-US', {
      timeZone: 'America/New_York',
    })
  );

  const year = etDate.getFullYear();
  const day = etDate.getDate();
  const month = etDate
    .toLocaleString('en-us', { month: 'short' })
    .toUpperCase();

  return `${year}-${month}-${day}`;
};
