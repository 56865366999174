const CFB_TEAM_METRICS = [
  // TOUCHDOWN METRICS
  {
    label: 'Score',
    description: 'Total team score',
    value: 'Score',
  },
  {
    label: 'Passing Touchdowns',
    description: 'Total passing touchdowns scored',
    value: 'PassingTouchdowns',
  },
  {
    label: 'Rushing Touchdowns',
    description: 'Total rushing touchdowns scored',
    value: 'RushingTouchdowns',
  },
  {
    label: 'Receiving Touchdowns',
    description: 'Total receiving touchdowns scored',
    value: 'ReceivingTouchdowns',
  },
  // RUSHING METRICS
  {
    label: 'Rushing Attempts',
    description: 'Number of rushing attempts',
    value: 'RushingAttempts',
  },
  {
    label: 'Rushing Yards',
    description: 'Number of rushing yards',
    value: 'RushingYards',
  },
  {
    label: 'Rushing Yards Per Attempt',
    description: 'Average rushing yards gained per attempt',
    value: 'RushingYardsPerAttempt',
  },
  {
    label: 'Rushing Touchdowns',
    description: 'Rushing touchdowns scored',
    value: 'RushingTouchdowns',
  },
  // PASSING METRICS
  {
    label: 'Passing Attempts',
    description: 'Number of passes thrown',
    value: 'PassingAttempts',
  },
  {
    label: 'Passing Completions',
    description: 'Number of pass completions',
    value: 'PassingCompletions',
  },
  {
    label: 'Passing Yards',
    description: 'Number of passing yards',
    value: 'PassingYards',
  },
  {
    label: 'Passing Touchdowns',
    description: 'Number of passing touchdowns thrown',
    value: 'PassingTouchdowns',
  },
  {
    label: 'Passing Interceptions',
    description: 'Interceptions thrown',
    value: 'PassingInterceptions',
  },
  {
    label: 'Passing Yards Per Attempt',
    description: 'Average passing yards gained per attempt',
    value: 'PassingYardsPerAttempt',
  },
  {
    label: 'Passing Yards Per Completion',
    description: 'Average passing yards gained per completion',
    value: 'PassingYardsPerCompletion',
  },
  {
    label: 'Passing Completion Percentage',
    description: 'Percentage of passes that were completed',
    value: 'PassingCompletionPercentage',
  },
  {
    label: 'Passer Rating',
    description: 'Total Passing Rating',
    value: 'PassingRating',
  },
  // DOWN AND DISTANCE METRICS
  {
    label: 'First Downs',
    description: 'Total first downs',
    value: 'FirstDowns',
  },
  {
    label: 'Third Down Attempts',
    description: 'Third down attempts',
    value: 'ThirdDownAttempts',
  },
  {
    label: 'Third Down Conversions',
    description: 'Third down conversions',
    value: 'ThirdDownConversions',
  },
  {
    label: 'Fourth Down Attempts',
    description: 'Fourth down attempts',
    value: 'FourthDownAttempts',
  },
  {
    label: 'Fourth Down Conversions',
    description: 'Fourth down conversions',
    value: 'FourthDownConversions',
  },
  {
    label: 'Penalties',
    description: 'Penalties Committed',
    value: 'Penalties',
  },
  {
    label: 'Penalty Yards',
    description: 'Penalty yards enforced against team',
    value: 'PenaltyYards',
  },
  //  UNFORTUNATE OFFENSIVE METRICS
  {
    label: 'Fumbles',
    description: 'Fumbles',
    value: 'Fumbles',
  },
  {
    label: 'Fumbles Lost',
    description: 'Number of fumbles recovered by opponent',
    value: 'FumblesLost',
  },
  {
    label: 'Tackles For Loss',
    description:
      "Tackles behind the opponent's line of scrimmage (sacks are also credited as a tackle for loss)",
    value: 'TacklesForLoss',
  },
  // PUNT METRICS
  {
    label: 'Punts',
    description: 'Number of punts',
    value: 'Punts',
  },
  {
    label: 'Total Punt Yards',
    description: 'Total punt yards',
    value: 'PuntYards',
  },
  {
    label: 'Punt Yards Average',
    description: 'Average number of yards per punt',
    value: 'PuntAverage',
  },
  {
    label: 'Longest Punt',
    description: 'Longest punt yards',
    value: 'PuntLong',
  },
  // EXTRA POINT METRICS
  {
    label: 'Extra Points Attempted',
    description: 'Extra points attempted',
    value: 'ExtraPointsAttempted',
  },
  {
    label: 'Extra Points Made',
    description: 'Extra points made',
    value: 'ExtraPointsMade',
  },
  // FIELD GOAL METRICS
  {
    label: 'Field Goals Attempted',
    description: 'Field goals attempted',
    value: 'FieldGoalsAttempted',
  },
  {
    label: 'Field Goals Made',
    description: 'Number of successful field goal attempts',
    value: 'FieldGoalsMade',
  },
  {
    label: 'Field Goal Percentage',
    description: 'Total field goal percentage',
    value: 'FieldGoalPercentage',
  },
  // PUNT METRICS
  {
    label: 'Punt Returns',
    description: 'Number of punt return attempts',
    value: 'PuntReturns',
  },
  {
    label: 'Punt Return Yards',
    description: 'Total return yards on punts',
    value: 'PuntReturnYards',
  },
  {
    label: 'Punt Returns For Touchdowns',
    description: 'Punts returned for touchdown',
    value: 'PuntReturnTouchdowns',
  },
  {
    label: 'Longest Punt Return (Yards)',
    description: 'Longest punt return (in yards)',
    value: 'PuntReturnLong',
  },
  // KICK RETURN METRICS
  {
    label: 'Kickoff Returns',
    description: 'Kickoff returns',
    value: 'KickReturns',
  },
  {
    label: 'Kickoff Return Yards',
    description: 'Kickoff Return Yards',
    value: 'KickReturnYards',
  },
  {
    label: 'Kicks Returned For Touchdowns',
    description: 'Number of kicks returned for a touchdown',
    value: 'KickReturnTouchdowns',
  },
  {
    label: 'Longest Kick Return (Yards)',
    description: 'Longest kick return',
    value: 'KickReturnLong',
  },
  // DEFENSIVE METRICS
  {
    label: 'Interception Return Yards',
    description: 'Return yards from interceptions',
    value: 'InterceptionReturnYards',
  },
  {
    label: 'Solo Tackles',
    description: 'Solo, unassisted tackles',
    value: 'SoloTackles',
  },
  {
    label: 'Assisted Tackles',
    description: 'Assisted tackles (also called a half tackle)',
    value: 'AssistedTackles',
  },
  {
    label: 'Defensive Sacks',
    description: 'Sacks of the opposing quarterback',
    value: 'Sacks',
  },
  {
    label: 'Defensive Passes Defended',
    description: 'Passes defended or batted down',
    value: 'PassesDefended',
  },
  {
    label: 'Fumbles Recovered',
    description: 'Fumbles recovered that resulted in change of possession',
    value: 'FumblesRecovered',
  },
  {
    label: 'Fumble Returned For Touchdowns',
    description: 'Fumbles returned for touchdowns',
    value: 'FumbleReturnTouchdowns',
  },
  {
    label: 'Interception Returned For Touchdowns',
    description: 'Defensive interceptions returned for touchdowns',
    value: 'InterceptionReturnTouchdowns',
  },
];

export { CFB_TEAM_METRICS };
