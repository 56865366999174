const MLB_BASE_METRICS = [
  { label: 'At Bats', value: 'AtBats' },
  { label: 'Runs', value: 'Runs' },
  { label: 'Hits', value: 'Hits' },
  { label: 'Total Bases', value: 'TotalBases' },
  { label: 'Singles', value: 'Singles' },
  { label: 'Doubles', value: 'Doubles' },
  { label: 'Triples', value: 'Triples' },
  { label: 'Home Runs', value: 'HomeRuns' },
  { label: 'Grand Slams', value: 'GrandSlams' },
  { label: 'Runs Batted In', value: 'RunsBattedIn' },
  { label: 'Walks', value: 'Walks' },
  { label: 'Intentional Walks', value: 'IntentionalWalks' },
  { label: 'Outs', value: 'Outs' },
  { label: 'Strikeouts', value: 'Strikeouts' },
  { label: 'Stolen Bases', value: 'StolenBases' },
  { label: 'Caught Stealing', value: 'CaughtStealing' },
  { label: 'Batting Average', value: 'BattingAverage' },
  { label: 'On Base Percentage', value: 'OnBasePercentage' },
  { label: 'Weighted On Base Percentage', value: 'WeightedOnBasePercentage' },
  { label: 'Slugging Percentage', value: 'SluggingPercentage' },
  { label: 'On Base Plus Slugging', value: 'OnBasePlusSlugging' },
  { label: 'Fly Outs', value: 'FlyOuts' },
  { label: 'Ground Outs', value: 'GroundOuts' },
  { label: 'Line Outs', value: 'LineOuts' },
  { label: 'Pop Outs', value: 'PopOuts' },
  { label: 'Plate Appearances', value: 'PlateAppearances' },
  { label: 'Hit By Pitch', value: 'HitByPitch' },
  { label: 'Sacrifices', value: 'Sacrifices' },
  { label: 'Sacrifice Flies', value: 'SacrificeFlies' },
  {
    label: 'Batting Average On Balls In Play',
    value: 'BattingAverageOnBallsInPlay',
  },
  { label: 'Ground Into Double Play', value: 'GroundIntoDoublePlay' },
  { label: 'Pitches Seen', value: 'PitchesSeen' },
  { label: 'Reached On Error', value: 'ReachedOnError' },
  { label: 'Errors', value: 'Errors' },
];

const MLB_PITCHING_METRICS = [
  { label: 'Innings Pitched', value: 'InningsPitchedDecimal' },
  { label: 'Hits Allowed', value: 'PitchingHits' },
  { label: 'Runs allowed', value: 'PitchingRuns' },
  { label: 'Earned Runs Allowed', value: 'PitchingEarnedRuns' },
  { label: 'Singles Allowed', value: 'PitchingSingles' },
  { label: 'Doubles Allowed', value: 'PitchingDoubles' },
  { label: 'Triples Allowed', value: 'PitchingTriples' },
  { label: 'Grand Slams Allowed', value: 'PitchingGrandSlams' },
  { label: 'Home Runs Allowed', value: 'PitchingHomeRuns' },
  { label: 'Pitches Thrown', value: 'PitchesThrown' },
  { label: 'Strikes Thrown', value: 'PitchesThrownStrikes' },
  { label: 'Strikeouts Pitched', value: 'PitchingStrikeouts' },
  { label: 'Total Outs Pitched', value: 'TotalOutsPitched' },
  {
    label: 'Walks+Hits Per Innings Pitched',
    value: 'WalksHitsPerInningsPitched',
  },
  { label: 'Saves', value: 'Saves' },
  { label: 'Left On Base', value: 'LeftOnBase' },
  {
    label: 'Fielding Independent Pitching',
    value: 'FieldingIndependentPitching',
  },
  {
    label: 'Batting Average Against',
    value: 'PitchingBattingAverageAgainst',
  },

  { label: 'Pitching Hit By Pitch', value: 'PitchingHitByPitch' },
  { label: 'Pitching Sacrifices', value: 'PitchingSacrifices' },
  { label: 'Pitching Sacrifice Flies', value: 'PitchingSacrificeFlies' },
  {
    label: 'Pitching Ground Into Double Play',
    value: 'PitchingGroundIntoDoublePlay',
  },
  { label: 'Pitching Complete Games', value: 'PitchingCompleteGames' },
  { label: 'Pitching Shut Outs', value: 'PitchingShutOuts' },
  { label: 'Pitching No Hitters', value: 'PitchingNoHitters' },
  { label: 'Pitching Perfect Games', value: 'PitchingPerfectGames' },
  { label: 'Pitching Plate Appearances', value: 'PitchingPlateAppearances' },
  { label: 'Pitching Total Bases', value: 'PitchingTotalBases' },
  { label: 'Pitching Fly Outs', value: 'PitchingFlyOuts' },
  { label: 'Pitching Ground Outs', value: 'PitchingGroundOuts' },
  { label: 'Pitching Line Outs', value: 'PitchingLineOuts' },
  { label: 'Pitching Pop Outs', value: 'PitchingPopOuts' },
  { label: 'Pitching Intentional Walks', value: 'PitchingIntentionalWalks' },
  { label: 'Pitching Reached On Error', value: 'PitchingReachedOnError' },
  {
    label: 'Pitching Catchers Interference',
    value: 'PitchingCatchersInterference',
  },
  { label: 'Pitching Balls In Play', value: 'PitchingBallsInPlay' },
  { label: 'Pitching On Base Percentage', value: 'PitchingOnBasePercentage' },
  {
    label: 'Pitching Slugging Percentage',
    value: 'PitchingSluggingPercentage',
  },
  {
    label: 'Pitching On Base Plus Slugging',
    value: 'PitchingOnBasePlusSlugging',
  },
  {
    label: 'Pitching Batting Average On Balls In Play',
    value: 'PitchingBattingAverageOnBallsInPlay',
  },
  {
    label: 'Pitching Weighted On Base Percentage',
    value: 'PitchingWeightedOnBasePercentage',
  },
  { label: 'Pitching Double Plays', value: 'PitchingDoublePlays' },
  { label: 'Pitching Inning Started', value: 'PitchingInningStarted' },
  { label: 'Pitching Holds', value: 'PitchingHolds' },
  { label: 'Pitching Blown Saves', value: 'PitchingBlownSaves' },
];

const MLB_ALL_METRICS = [...MLB_BASE_METRICS, ...MLB_PITCHING_METRICS];

const MLB_TEAM_METRICS = [...MLB_BASE_METRICS, ...MLB_PITCHING_METRICS];
const MLB_PITCHER_METRICS = [...MLB_PITCHING_METRICS, ...MLB_BASE_METRICS];
const MLB_PLAYER_METRICS = MLB_BASE_METRICS;

export {
  MLB_ALL_METRICS,
  MLB_TEAM_METRICS,
  MLB_PITCHER_METRICS,
  MLB_PLAYER_METRICS,
};
