import React, { type Dispatch } from 'react';

import { LEAGUE_OPTIONS } from 'constants/options';
import { type LeagueOption } from 'types/addPrediction';

import './selector.css';

type LeagueSelectorProps = {
  league: LeagueOption;
  setLeague: Dispatch<React.SetStateAction<LeagueOption>>;
};

export const LeagueSelector = ({ league, setLeague }: LeagueSelectorProps) => {
  return (
    <div className="selector-container">
      <p className="selector-label"> Select League: {league.label}</p>
      <div className="options-row">
        {LEAGUE_OPTIONS.map((leagueOption) => {
          return (
            <button
              key={leagueOption.value.id}
              className={
                league.value.id === leagueOption.value.id
                  ? 'option-selected'
                  : 'option'
              }
              onClick={() => setLeague(leagueOption)}
            >
              {leagueOption.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
