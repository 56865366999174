import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  getImageUploadUrl,
  type GetImageUploadUrlInput,
  type GetImageUploadUrlOutput,
} from 'api/image';

type UseUploadImageInput = GetImageUploadUrlInput;

/**
 * Hook to facilitate uploading an image to an S3 bucket.
 * Handles query to the API for an image upload URL, and then uploads the image to S3.
 * @param input The input to get the image upload URL.
 * @returns handleUploadImage: Single function to handle upload an image to S3.
 * Accepts an image URI, returns the image key which should be persisted to the entity associated with the image.
 */
export const useUploadImageToS3 = (input: UseUploadImageInput) => {
  const { refetch: fetchImageUploadUrl } = useQuery<
    GetImageUploadUrlOutput,
    Error
  >({
    queryKey: ['getImageUploadUrl'],
    queryFn: async () => await getImageUploadUrl(input),
    enabled: false, //Disabling allows us to call refetch manually
  });

  const handleUploadImage: (
    imageUri: string
  ) => Promise<{ imageKey: string } | undefined> = async (imageUri: string) => {
    try {
      const imageUrlResponse = await fetchImageUploadUrl();
      if (imageUrlResponse.isSuccess) {
        await uploadImage(imageUrlResponse.data.uploadUrl, imageUri);
        return { imageKey: imageUrlResponse.data.imageKey };
      } else {
        console.error(
          'Error fetching image upload url from API.',
          imageUrlResponse.error ?? undefined
        );
        throw new Error('Error fetching image upload url');
      }
    } catch (error) {
      // eslint-disable-next-line max-len
      console.error(
        'There was an issue uploading your image. Please try again, and if the issue persists, contact support for help. Error: ',
        error
      );
    }
    return;
  };

  return { handleUploadImage };
};

const uploadImage = async (uploadUrl: string, imageUri: string) => {
  try {
    const resp = await fetch(imageUri);
    const arrayBuffer = await resp.arrayBuffer();

    const instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];
    const response = await instance.put(uploadUrl, arrayBuffer, {
      headers: {
        Accept: '*/*',
        'Content-Type': '',
      },
    });

    if (response.status !== 200) {
      console.error(
        'Error response from executing image upload to S3.',
        response
      );
      throw new Error(
        'Error response from executing image upload to S3.' +
          response.statusText
      );
    }
  } catch (error) {
    console.error('Error in function call for uploading image to S3: ' + error);
    throw new Error('Error uploading image: ' + error);
  }
};
