import {
  keepPreviousData,
  useInfiniteQuery,
  type InfiniteData,
  type QueryKey,
} from '@tanstack/react-query';
import { searchCommunities } from 'api/community';
import { type Community } from 'types/community';

export type CommunitySearchResult = {
  communities: Community[];
  isLoading: boolean;
  cursor?: string;
};

export default function useSearchCommunities(searchQuery?: string) {
  const queryResponse = useInfiniteQuery<
    CommunitySearchResult,
    Error,
    InfiniteData<CommunitySearchResult>,
    QueryKey,
    string | undefined
  >({
    queryKey: ['communitySearch', searchQuery],
    queryFn: async ({ pageParam = '' }) =>
      await searchCommunities(searchQuery || '', pageParam),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage?.cursor;
    },
    enabled: !!searchQuery,
    placeholderData: keepPreviousData,
  });

  return {
    searchCommunitiesResults: queryResponse.data?.pages,
    searchCommunitiesLoading: queryResponse.isLoading,
    searchCommunitiesError: queryResponse.isError,
    refetchCommunitiesResults: queryResponse.refetch,
    hasNextPage: queryResponse.hasNextPage,
    fetchNextPage: queryResponse.fetchNextPage,
    isFetching: queryResponse.isFetching,
    isFetchingNextPage: queryResponse.isFetchingNextPage,
  };
}
