import React, { useEffect } from 'react';

import { type Season } from 'types/sportsdata';
import { isValidSeason } from 'utils/addPred';

type SeasonSelectorProps = {
  seasons: Season[];
  disabled: boolean;
  onSeasonSelect: (season: Season | undefined) => void;
  selectedSeason: Season | undefined;
};

export const SeasonSelector = ({
  seasons,
  disabled,
  onSeasonSelect,
  selectedSeason,
}: SeasonSelectorProps) => {
  useEffect(() => {
    const validIndex = seasons?.findIndex((season) => isValidSeason(season));
    if (seasons && validIndex != undefined && validIndex != -1) {
      onSeasonSelect(seasons[validIndex]);
    } else {
      onSeasonSelect(undefined);
    }
  }, [seasons]);

  return (
    <div>
      {seasons.map((season, index) => {
        return (
          <div key={index}>
            <label key={index}>
              <input
                type="radio"
                value={season.seasonId}
                checked={season.seasonId === selectedSeason?.seasonId}
                onChange={() => {
                  onSeasonSelect(season);
                }}
                disabled={!isValidSeason(season) || disabled}
              />
              {season.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};
