import { useEffect, useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button';
import { PredictionString } from 'components/PredictionString';
import { FullScreenSpinner } from 'components/Spinner';
import { UserSearch } from 'components/UserSearch';
import useChallengeAllStats from 'hooks/useChallengeAllStats';
import { type ChallengeStats, type LedgerSummary } from 'types/challengeAll';
import { type UserSummary } from 'types/user';
import { createdAtToDateStringShort } from 'utils/date';
import {
  getComparisonFromPrediction,
  getEntityStringFromPred,
  getMetricFromPrediction,
  getPredGroupingFromType,
  getPredictedValueFromPrediction,
} from 'utils/prediction';

type ChallengeAllCardProps = {
  challengeStats: ChallengeStats;
};

const ChallengeAllCard = ({ challengeStats }: ChallengeAllCardProps) => {
  const challenge = challengeStats.challenge;
  const staticPred = challenge.staticPrediction;
  const type = staticPred.type;
  return (
    <div className="box">
      <div className="prediction">
        <PredictionString
          grouping={getPredGroupingFromType(type)}
          entity={getEntityStringFromPred(type, staticPred.content)}
          metric={getMetricFromPrediction(type, staticPred.content)}
          comparison={getComparisonFromPrediction(type, staticPred.content)}
          value={getPredictedValueFromPrediction(type, staticPred.content)}
          date={
            staticPred.gameDetails
              ? new Date(staticPred.gameDetails.gameStartTime)
              : undefined
          }
          season={
            staticPred.seasonDetails ? staticPred.seasonDetails : undefined
          }
          league={staticPred.league}
          comparisonPredictionContent={staticPred.content}
          comparisonPredictionType={type}
        />
      </div>
      <div className="column-flex">
        <p className="challenge-stats-column">
          Pending: {challengeStats.stats.Pending}
          <br />
          Accepts: {challengeStats.stats.Accepted}
          <br />
          Declines: {challengeStats.stats.Declined}
          <br />
          Canceled: {challengeStats.stats.Canceled}
          <br />
          Voided: {challengeStats.stats.Voided}
        </p>
        <p className="challenge-info-column">
          Challenge Name:{' '}
          {challenge.adminChallengeDetails.challengeName
            ? challenge.adminChallengeDetails.challengeName
            : 'N/A'}
          <br />
          Created at: {createdAtToDateStringShort(challenge.createdAt)}
          <br />
          Points: {challenge.challengePoints}
          <br />
          Admin Challenge:{' '}
          {challenge.adminChallengeDetails.isAdminChallenge.toString()}
        </p>
      </div>
    </div>
  );
};

type LedgerSummaryCardProps = {
  ledgerSummery: LedgerSummary;
};

const LedgerSummaryCard = ({ ledgerSummery }: LedgerSummaryCardProps) => {
  return (
    <p className="user-ledger-summary">
      UserId: {ledgerSummery.userId}
      <br />
      Points: {ledgerSummery.totalPointDifferential}
      <br />
      Record: {ledgerSummery.wins}-{ledgerSummery.losses}-{ledgerSummery.voids}
    </p>
  );
};

export const ChallengeAllStats = () => {
  // defaults to show current Open pools first
  const [challenger, setChallenger] = useState<UserSummary>();

  const navigate = useNavigate();

  const { stats, statsLoading, statsError, refetchStats, isFetching } =
    useChallengeAllStats(challenger?.userId);

  useEffect(() => {
    if (challenger) {
      refetchStats();
    }
  }, [challenger]);

  if (statsLoading || isFetching) {
    return <FullScreenSpinner />;
  }

  if (!statsError) {
    return (
      <div className="view-stats-container">
        <div style={{ display: 'flex' }}>
          <Button
            accessibilityLabel="add-challenge-button"
            type="primary"
            text="Challenge EVERYBODY"
            handleOnClick={() => navigate('/addChallenge')}
          />
        </div>
        <h2 className="view-stats-text">Challenge All Stats</h2>
        {stats?.challengesWithStats.length == 0 ? (
          <div> No challenges to display.</div>
        ) : (
          stats?.challengesWithStats.map((challenge) => (
            <div key={challenge.challenge.challengeId}>
              <ChallengeAllCard challengeStats={challenge} />
            </div>
          ))
        )}
        <h2 className="view-stats-text">User Ledger Summaries</h2>
        <div>
          <p>Ledger summaries between all users and challenger: </p>
          <UserSearch
            chosenAuthor={challenger}
            setChosenAuthor={setChallenger}
          />
          {!stats?.ledgerSummaries || stats?.ledgerSummaries?.length == 0 ? (
            <div> No ledger summaries to display.</div>
          ) : (
            stats?.ledgerSummaries?.map((ledgerSummary) => (
              <div key={ledgerSummary.userId}>
                <LedgerSummaryCard ledgerSummery={ledgerSummary} />
              </div>
            ))
          )}
        </div>
      </div>
    );
  } else {
    return <p>Error Loading Challenge All Stats.</p>;
  }
};

export default ChallengeAllStats;
