import { type Season } from 'types/sportsdata';

export const NBA_SEASONS: Season[] = [
  {
    seasonId: '2025PRE',
    seasonYear: '2025',
    seasonEndDate: '2024-10-20T00:00:00Z',
    label: '2024-2025 Pre Season',
    league: 'nba',
  },
  {
    seasonId: '2025REG',
    seasonYear: '2025',
    seasonEndDate: '2025-04-14T00:00:00Z',
    label: '2024-2025 Regular Season',
    league: 'nba',
  },
];

export const CURRENT_NBA_FUTURES_SEASON: Season = {
  seasonId: '2025',
  seasonYear: '2025',
  seasonEndDate: '2025-07-01T00:00:00Z',
  label: 'NBA 2024-2025 Season',
  league: 'nba',
};
