import axios from 'axios';
import { getApiUrls } from 'config/api';

const { ADMIN_API_URL } = getApiUrls();

export const getUserMetrics = async () => {
  const url = `${ADMIN_API_URL}/admin/userMetrics`;
  const { data } = await axios.get(url);
  return data.object;
};
