import axios from 'axios';

import { getApiUrls } from 'config/api';
import { type ChallengeAllPostBody } from 'screens/AddPrediction/types';

const { ADMIN_API_URL } = getApiUrls();

export const postChallengeAll = async (body: ChallengeAllPostBody) => {
  const { data } = await axios.post(
    `${ADMIN_API_URL}/admin/challengeAll`,
    body
  );
  return data;
};

export const getChallengeAllStats = async (userId = '') => {
  let url = `${ADMIN_API_URL}/admin/challengeAllStats`;
  if (userId) {
    url = url + `?userId=${userId}`;
  }
  const { data } = await axios.get(url);
  return data.object;
};
