import { useState } from 'react';
import { Flex, Select, Text } from '@chakra-ui/react';
import { useEnvironment, type Environment } from 'contexts/EnvContext';

export const EnvironmentMenu: React.FC = () => {
  const { environment, setEnvironment } = useEnvironment();
  const [selectedValue, setSelectedValue] = useState<Environment>(environment);

  const onSelectEnv = (env: Environment) => {
    setSelectedValue(env);
    setEnvironment(env);
    // Force re-render to reflect the environment change
    window.location.reload();
  };

  return (
    <>
      {environment === 'Prod' ? (
        <Flex
          w="100%"
          bg="error.dark"
          h="45px"
          align="center"
          justify="flex-start"
          pl="16px"
        >
          <Text fontWeight={500} fontSize="16px">
            Production
          </Text>
        </Flex>
      ) : (
        <Select
          value={selectedValue}
          onChange={(event) => {
            onSelectEnv(event.target.value as Environment);
          }}
          color="text.primary"
          fontWeight={500}
          fontSize="16px"
          bg={selectedValue === 'Preview' ? 'green.500' : 'yellow.500'}
          borderWidth={0}
          borderRadius={0}
          h="45px"
        >
          <option value="Preview">Preview</option>
          <option value="Testflight">TestFlight</option>
        </Select>
      )}
    </>
  );
};
