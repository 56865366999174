import { type MetricOption } from 'types/addPrediction';

export const NHL_PLAYER_FUTURES: MetricOption[] = [
  {
    value: 'HartMemorialTrophyWinner',
    label: 'Hart Memorial Trophy Winner (MVP)',
  },
  {
    value: 'VezinaTrophyWinner',
    label: 'Vezina Trophy Winner (Best Goaltender)',
  },
  {
    value: 'RocketRichardTrophyWinner',
    label: 'Rocket Richard Trophy Winner (Leading Goal Scorer)',
  },
  {
    value: 'CalderTrophyWinner',
    label: 'Calder Trophy Winner (Rookie of the Year)',
  },
  {
    value: 'JamesNorrisTrophyWinner',
    label: 'James Norris Trophy Winner (Def. Player of the Year)',
  },
  {
    value: 'ConnSmytheTrophyWinner',
    label: 'Conn Smythe Trophy Winner (Playoff MVP)',
  },
  {
    value: 'ArtRossTrophyWinner',
    label: 'Art Ross Trophy Winner (Most Points)',
  },
];
