import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'admin-web-client',
  env: process.env.REACT_APP_ENV,
  version: '0.1.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

export const datadogInit = (): void => {
  try {
    datadogRum.startSessionReplayRecording();
  } catch {
    console.error('Something went wrong while reporting metrics');
  }
};
