import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Dropdown } from '../components/Dropdown';
import { GameSelector } from '../components/GameSelector';
import { renderEntityItem, renderMetricItem } from '../components/RenderItems';
import { SeasonSelector } from '../components/SeasonSelector';
import { type CreateComparisonPrediction } from '../types';
import {
  type ComparisonValue,
  type DurationValue,
  type MetricOption,
} from 'types/addPrediction';
import {
  type Entity,
  type Game,
  type League,
  type Player,
  type Season,
  type Team,
} from 'types/sportsdata';
import {
  filterEntities,
  filterMetrics,
  getMetricItems,
  getSeasonsForLeague,
  getTeamId,
  metricIsCompatible,
  setEntityLabel,
  setMetricLabel,
} from 'utils/addPred';
import { getComparisonString } from 'utils/prediction';

type ComparisonPredictionFormProps = {
  placingPrediction: boolean;
  league: League;
  entityArray: (Player | Team)[];
  entitiesLoading: boolean;
  entitiesError: boolean;
  metricItems: MetricOption[];
  comparison: ComparisonValue;
  setComparison: Dispatch<SetStateAction<ComparisonValue>>;
  comparisonPrediction: CreateComparisonPrediction | null;
  setComparisonPrediction: Dispatch<SetStateAction<CreateComparisonPrediction>>;
};

export const ComparisonForm = ({
  league,
  placingPrediction,
  entityArray,
  entitiesLoading,
  metricItems,
  comparison,
  setComparison,
  comparisonPrediction,
  setComparisonPrediction,
}: ComparisonPredictionFormProps) => {
  const [seasons, setSeasons] = useState<Season[]>(getSeasonsForLeague(league));

  const prevMetric1 = useRef<MetricOption>();
  const prevMetric2 = useRef<MetricOption>();

  const prevEntity1 = useRef<Entity | undefined>();
  const prevEntity2 = useRef<Entity | undefined>();

  useEffect(() => {
    // HANDLE METRIC CHANGE
    const currMetric1 = comparisonPrediction?.predictions[1]?.metric;
    const currMetric2 = comparisonPrediction?.predictions[2]?.metric;

    const metricItems1 = comparisonPrediction?.predictions[1].metricItems;
    const metricItems2 = comparisonPrediction?.predictions[2].metricItems;

    // First prediction metric has been set
    if (currMetric1 !== prevMetric1.current && !currMetric2) {
      // Set second predictions metric
      if (
        !comparisonPrediction?.predictions[2].entity ||
        metricIsCompatible(metricItems2, currMetric1)
      ) {
        child2Helpers.setMetric(currMetric1);
      }
    }
    // Second prediction metric has been set
    else if (currMetric2 !== prevMetric2.current && !currMetric1) {
      // Set first predictions metric
      if (
        !comparisonPrediction?.predictions[1].entity ||
        metricIsCompatible(metricItems1, currMetric2)
      ) {
        child1Helpers.setMetric(currMetric2);
      }
    }

    // HANDLE ENTITY CHANGE
    const currEntity1 = comparisonPrediction?.predictions[1]?.entity;
    const currEntity2 = comparisonPrediction?.predictions[2]?.entity;

    // First prediction entity has been updated while a metric is loaded
    if (
      currEntity1 !== prevEntity1.current &&
      currMetric1 &&
      !metricIsCompatible(metricItems1, currMetric1)
    ) {
      child2Helpers.setMetric(undefined);
    }
    // Second prediction entity has been updated while a metric is loaded
    else if (
      currEntity2 !== prevEntity2.current &&
      currMetric2 &&
      !metricIsCompatible(metricItems2, currMetric2)
    ) {
      child2Helpers.setMetric(undefined);
    }

    return () => {
      // Set Ref Values
      prevMetric1.current = currMetric1;
      prevMetric2.current = currMetric2;

      prevEntity1.current = currEntity1;
      prevEntity2.current = currEntity2;
    };
  }, [comparisonPrediction]);

  useEffect(() => {
    setSeasons(getSeasonsForLeague(league));
    child1Helpers.clear();
    child2Helpers.clear();
  }, [league]);

  const setComparisonPredictionEntityHelper = (
    item: Entity | undefined,
    childToUpdate: string
  ) => {
    if (item) {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        // Currently when we initialize the comparison prediction we initialize it with the first
        // two child predictions with their duration ids set to game. This is to mirror how the other
        // forms look when they initially render. When we update comparison predictions to have more than
        // 2 predictions we will need to initialize each child prediction in these update funcs.
        if (predictions[childToUpdate] == undefined) {
          predictions[childToUpdate] = {};
        }
        predictions[childToUpdate].entity = item;
        metricItems = getMetricItems(league, 'comparison', item);
        predictions[childToUpdate].metricItems = metricItems;
        return { ...current, predictions };
      });
    } else {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        predictions[childToUpdate].entity = undefined;
        predictions[childToUpdate].metricItems = [];
        predictions[childToUpdate].metric = undefined;
        return { ...current, predictions };
      });
    }
  };

  const setComparisonPredictionMetricHelper = (
    item: MetricOption | undefined,
    childToUpdate: string
  ) => {
    if (item) {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        // Initialize child if it does not exist
        if (predictions[childToUpdate] == undefined) {
          predictions[childToUpdate] = {};
        }
        predictions[childToUpdate].metric = item;
        return { ...current, predictions };
      });
    } else {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        predictions[childToUpdate].metric = undefined;
        return { ...current, predictions };
      });
    }
  };

  const setComparisonPredictionDurationHelper = (
    item: DurationValue | undefined,
    childToUpdate: string
  ) => {
    if (item) {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        // Initialize child if it does not exist
        if (predictions[childToUpdate] == undefined) {
          predictions[childToUpdate] = {};
        }
        const pred = predictions[childToUpdate];
        pred.duration = item;
        predictions[childToUpdate].metric = undefined;
        metricItems = getMetricItems(
          league,
          'comparison',
          predictions[childToUpdate].entity,
          item?.id
        );
        predictions[childToUpdate].metricItems = metricItems;
        return { ...current, predictions };
      });
    }
  };

  const setComparisonPredictionGameHelper = (
    item: Game | undefined,
    childToUpdate: string
  ) => {
    if (item) {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        // Initialize child if it does not exist
        if (predictions[childToUpdate] == undefined) {
          predictions[childToUpdate] = {};
        }
        predictions[childToUpdate].game = item;
        predictions[childToUpdate].season = undefined;
        return { ...current, predictions };
      });
    } else {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        predictions[childToUpdate].game = undefined;
        return { ...current, predictions };
      });
    }
  };

  const setComparisonPredictionSeasonHelper = (
    item: Season | undefined,
    childToUpdate: string
  ) => {
    if (item) {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        // Initialize child if it does not exist
        if (predictions[childToUpdate] == undefined) {
          predictions[childToUpdate] = {};
        }
        predictions[childToUpdate].season = item;
        predictions[childToUpdate].game = undefined;
        return { ...current, predictions };
      });
    } else {
      setComparisonPrediction((current) => {
        const predictions = { ...current.predictions };
        predictions[childToUpdate].season = undefined;
        return { ...current, predictions };
      });
    }
  };

  const getHelpers = (index: string) => {
    return {
      setEntity: (item: Entity | undefined) =>
        setComparisonPredictionEntityHelper(item, index),
      setMetric: (item: MetricOption | undefined) =>
        setComparisonPredictionMetricHelper(item, index),
      setDuration: (item: DurationValue | undefined) =>
        setComparisonPredictionDurationHelper(item, index),
      setGame: (item: Game | undefined) =>
        setComparisonPredictionGameHelper(item, index),
      setSeason: (item: Season | undefined) =>
        setComparisonPredictionSeasonHelper(item, index),
      clear: () => {
        setComparisonPredictionEntityHelper(undefined, index);
        setComparisonPredictionMetricHelper(undefined, index);
        setComparisonPredictionDurationHelper(undefined, index);
      },
    };
  };

  const child1Helpers = getHelpers('1');
  const child2Helpers = getHelpers('2');

  return (
    <div>
      <div>
        <p>Selection 1: Player/Team</p>
        <Dropdown
          accessibilityLabel="Selection 1 Entity Picker"
          placeholder="Select Player or Team"
          items={entityArray}
          value={comparisonPrediction?.predictions['1']?.entity}
          renderItem={renderEntityItem}
          onItemSelect={child1Helpers.setEntity}
          filterFn={filterEntities}
          setItemLabel={setEntityLabel}
          clearDisabled={placingPrediction}
          loading={entitiesLoading}
          showItemsOnClick={false}
        />
      </div>
      <div>
        <p> Select Duration 1</p>
        <label>
          <input
            type="radio"
            value="game"
            checked={
              comparisonPrediction?.predictions['1']?.duration?.id === 'game'
            }
            onChange={() => child1Helpers.setDuration({ id: 'game' })}
          />
          Game
        </label>
        <label>
          <input
            type="radio"
            value="season"
            checked={
              comparisonPrediction?.predictions['1']?.duration?.id === 'season'
            }
            onChange={() => child1Helpers.setDuration({ id: 'season' })}
          />
          Season
        </label>
      </div>
      {comparisonPrediction?.predictions['1']?.duration?.id == 'game' ? (
        <GameSelector
          league={league}
          teamId={
            getTeamId(comparisonPrediction?.predictions['1']?.entity) || ''
          }
          disabled={placingPrediction}
          onGameSelect={child1Helpers.setGame}
          selectedGame={comparisonPrediction?.predictions['1']?.game}
        />
      ) : (
        <SeasonSelector
          seasons={seasons}
          disabled={placingPrediction}
          onSeasonSelect={child1Helpers.setSeason}
          selectedSeason={comparisonPrediction?.predictions['1']?.season}
        />
      )}
      <div>
        <p> Select Metric 1 </p>
        <Dropdown
          accessibilityLabel="Metric 1 Picker"
          placeholder="Select Metric 1"
          items={comparisonPrediction?.predictions['1']?.metricItems || []}
          value={comparisonPrediction?.predictions['1']?.metric}
          renderItem={renderMetricItem}
          onItemSelect={child1Helpers.setMetric}
          filterFn={filterMetrics}
          setItemLabel={setMetricLabel}
          clearDisabled={placingPrediction}
          showItemsOnClick={true}
        />
      </div>
      <div>
        <label>
          <input
            type="radio"
            value=">"
            checked={comparison.id === '>'}
            onChange={() =>
              setComparison({
                id: '>',
                operator: '>',
                stringValue: getComparisonString('>'),
              })
            }
          />
          greater than
        </label>
        <label>
          <input
            type="radio"
            value="<"
            checked={comparison.id === '<'}
            onChange={() =>
              setComparison({
                id: '<',
                operator: '<',
                stringValue: getComparisonString('<'),
              })
            }
          />
          less than
        </label>
        <label>
          <input
            type="radio"
            value="="
            checked={comparison.id === '='}
            onChange={() =>
              setComparison({
                id: '=',
                operator: '=',
                stringValue: getComparisonString('='),
              })
            }
          />
          equal to
        </label>
      </div>
      <div>
        <p>Selection 2: Player/Team</p>
        <Dropdown
          accessibilityLabel="Selection 2 Entity Picker"
          placeholder="Select Player or Team"
          items={entityArray}
          value={comparisonPrediction?.predictions['2']?.entity}
          renderItem={renderEntityItem}
          onItemSelect={child2Helpers.setEntity}
          filterFn={filterEntities}
          setItemLabel={setEntityLabel}
          clearDisabled={placingPrediction}
          loading={entitiesLoading}
          showItemsOnClick={false}
        />
      </div>
      <div>
        <p> Select Duration 2</p>
        <label>
          <input
            type="radio"
            value="game"
            checked={
              comparisonPrediction?.predictions['2']?.duration?.id === 'game'
            }
            onChange={() => child2Helpers.setDuration({ id: 'game' })}
          />
          Game
        </label>
        <label>
          <input
            type="radio"
            value="season"
            checked={
              comparisonPrediction?.predictions['2']?.duration?.id === 'season'
            }
            onChange={() => child2Helpers.setDuration({ id: 'season' })}
          />
          Season
        </label>
      </div>
      {comparisonPrediction?.predictions['2']?.duration?.id == 'game' ? (
        <GameSelector
          league={league}
          teamId={
            getTeamId(comparisonPrediction?.predictions['2']?.entity) || ''
          }
          disabled={placingPrediction}
          onGameSelect={child2Helpers.setGame}
          selectedGame={comparisonPrediction?.predictions['2']?.game}
        />
      ) : (
        <SeasonSelector
          seasons={seasons}
          disabled={placingPrediction}
          onSeasonSelect={child2Helpers.setSeason}
          selectedSeason={comparisonPrediction?.predictions['2']?.season}
        />
      )}
      <div>
        <p> Select Metric 2 </p>
        <Dropdown
          accessibilityLabel="Metric Picker 2"
          placeholder="Select Metric 2"
          items={comparisonPrediction?.predictions['2']?.metricItems || []}
          value={comparisonPrediction?.predictions['2']?.metric}
          renderItem={renderMetricItem}
          onItemSelect={child2Helpers.setMetric}
          filterFn={filterMetrics}
          setItemLabel={setMetricLabel}
          clearDisabled={placingPrediction}
          showItemsOnClick={true}
        />
      </div>
    </div>
  );
};
