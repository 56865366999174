const NHL_BASE_METRICS = [
  {
    label: 'Goals',
    value: 'Goals',
  },
  {
    label: 'Assists',
    value: 'Assists',
  },
  {
    label: 'Shots On Goal',
    value: 'ShotsOnGoal',
  },
  {
    label: 'Power Play Goals',
    value: 'PowerPlayGoals',
  },
  {
    label: 'Short-handed Goals',
    value: 'ShortHandedGoals',
  },
  {
    label: 'Empty Net Goals',
    value: 'EmptyNetGoals',
  },
  {
    label: 'Power Play Assists',
    value: 'PowerPlayAssists',
  },
  {
    label: 'Short-handed Assists',
    value: 'ShortHandedAssists',
  },
  {
    label: 'Hat Tricks',
    value: 'HatTricks',
  },
  {
    label: 'Shootout Goals',
    value: 'ShootoutGoals',
  },
  {
    label: '+/-',
    value: 'PlusMinus',
  },
  {
    label: 'Penalty Minutes',
    value: 'PenaltyMinutes',
  },
  {
    label: 'Blocks',
    value: 'Blocks',
  },
  {
    label: 'Hits',
    value: 'Hits',
  },
  {
    label: 'Takeaways',
    value: 'Takeaways',
  },
  {
    label: 'Giveaways',
    value: 'Giveaways',
  },
  {
    label: 'Faceoffs Won',
    value: 'FaceoffsWon',
  },
  {
    label: 'Faceoffs Lost',
    value: 'FaceoffsLost',
  },
  {
    label: 'Shifts',
    value: 'Shifts',
  },
  {
    label: 'Bench Penalty Minutes',
    value: 'BenchPenaltyMinutes',
  },
];

const NHL_GOALTENDING_METRICS = [
  {
    label: 'Goaltending Minutes',
    value: 'GoaltendingMinutes',
  },
  {
    label: 'Goaltending Seconds',
    value: 'GoaltendingSeconds',
  },
  {
    label: 'Goaltending Shots Against',
    value: 'GoaltendingShotsAgainst',
  },
  {
    label: 'Goaltending Goals Against',
    value: 'GoaltendingGoalsAgainst',
  },
  {
    label: 'Goaltending Saves',
    value: 'GoaltendingSaves',
  },
  {
    label: 'Goaltending Wins',
    value: 'GoaltendingWins',
  },
  {
    label: 'Goaltending Losses',
    value: 'GoaltendingLosses',
  },
  {
    label: 'Goaltending Shutouts',
    value: 'GoaltendingShutouts',
  },
  {
    label: 'Goaltending Overtime Losses',
    value: 'GoaltendingOvertimeLosses',
  },
];

const NHL_POINTS_METRIC = [
  {
    label: 'Points',
    value: 'Points',
  },
];

const NHL_ALL_METRICS = [
  ...NHL_BASE_METRICS,
  ...NHL_GOALTENDING_METRICS,
  ...NHL_POINTS_METRIC,
];

const NHL_TEAM_METRICS = [...NHL_BASE_METRICS, ...NHL_GOALTENDING_METRICS];

const NHL_PLAYER_METRICS = [...NHL_BASE_METRICS, ...NHL_POINTS_METRIC];

export { NHL_ALL_METRICS, NHL_TEAM_METRICS, NHL_PLAYER_METRICS };
