import axios from 'axios';

import { getApiUrls } from 'config/api';
import {
  type Game,
  type League,
  type Player,
  type Team,
} from 'types/sportsdata';

const { API_URL } = getApiUrls();

export const getTeams = async (league: League): Promise<Team[]> => {
  const { data } = await axios.get(`${API_URL}/sportsdata/${league}/teams`);
  return data.object;
};

export const getPlayers = async (
  league: League,
  team?: string
): Promise<Player[]> => {
  let url = `${API_URL}/sportsdata/${league}/players`;
  if (team) {
    url = `${url}?team=${team}`;
  }
  const { data } = await axios.get(url);
  return data.object;
};

export const getSchedule = async (
  league: League,
  dateString: string,
  teamId?: string
): Promise<Game[]> => {
  let url = `${API_URL}/sportsdata/${league}/schedule/${dateString}`;
  if (teamId) {
    url = `${url}?team=${teamId}`;
  }

  const { data } = await axios.get(url);
  return data.object;
};
