import { type SyntheticEvent, useState } from 'react';

import { Flex, Heading, useToast, Image, Card } from '@chakra-ui/react';
import {
  type ConfirmationResult,
  getAuth,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ConfirmationInputForm, PhoneInputForm } from './components/InputForms';
import { Spinner } from 'components/Spinner';
import { useAuth } from 'contexts/AuthContext';
import type { FirebaseError } from 'firebase/app';
import { errorToast } from 'utils/toasts';

import './style.css';

const auth = getAuth();
export const LoginPage = () => {
  const [confirmation, setConfirmation] = useState<ConfirmationResult>();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const toast = useToast();

  const authContext = useAuth();

  const sendVerificationText = async () => {
    if (authContext.recaptchaVerifier) {
      try {
        const e164PhoneNumber = parsePhoneNumber(phoneNumber, 'US').number;
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          e164PhoneNumber,
          authContext.recaptchaVerifier
        );
        setConfirmation(confirmationResult);
      } catch (error: unknown) {
        authContext.recaptchaVerifier.render().then(function (widgetId) {
          window.grecaptcha.reset(widgetId);
        });
        const firebaseError = error as FirebaseError;
        if (firebaseError.code === 'auth/invalid-app-credential') {
          toast(errorToast('Incorrect login. Please try again.'));
        } else {
          toast(errorToast('Something went wrong!'));
        }
      }
    } else {
      // This will only happen if there is an initial issue with creating the recaptcha object.
      // The recaptchaVerifier is instantiated on the initial load of the auth context.
      toast(errorToast('Something went wrong!'));
    }
  };

  const confirmVerification = async () => {
    try {
      await confirmation?.confirm(verificationCode);
      const recaptchaContainer = document.getElementById('recaptcha-container');
      if (recaptchaContainer) {
        recaptchaContainer.style.display = 'None';
      }
      authContext.recaptchaVerifier?.render();
    } catch {
      toast(errorToast('Something went wrong!'));
    }
  };

  const handlePhoneSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    await sendVerificationText();
  };

  const handleVerifySubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    await confirmVerification();
  };

  return (
    <Flex
      h="100vh"
      w="100%"
      flexDir="column"
      justify="flex-start"
      align="center"
    >
      <Flex justify="flex-start" flexDir="column" flexGrow={1}>
        <Flex flexDir="row" alignItems="center" mt="100px">
          <Image
            width="300"
            height="150"
            // eslint-disable-next-line @typescript-eslint/no-require-imports
            src={require('assets/vlted_logo_white.png')}
            alt="logo"
          />
          <Heading
            color="text.primary"
            size="xl"
            textAlign="center"
            fontWeight={800}
          >
            Admin Console
          </Heading>
        </Flex>
        <Flex
          w="100%"
          flexDir="column"
          flexGrow={1}
          align="flex-start"
          justify="flex-start"
          mt="100px"
        >
          {authContext.loading ? (
            <Spinner />
          ) : (
            <Card
              w="100%"
              borderColor="accent.100"
              borderWidth="1px"
              backgroundColor="neutral.800"
              py="30px"
              px="20px"
              gap="15px"
              alignItems="center"
            >
              <Heading
                color="text.primary"
                size="md"
                alignSelf="center"
                fontWeight={500}
              >
                Login
              </Heading>
              {!confirmation ? (
                <PhoneInputForm
                  handleSubmit={handlePhoneSubmit}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                />
              ) : (
                <ConfirmationInputForm
                  handleSubmit={handleVerifySubmit}
                  handleResendCode={sendVerificationText}
                  verificationCode={verificationCode}
                  setVerificationCode={setVerificationCode}
                />
              )}
            </Card>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
