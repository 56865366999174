import { type MetricOption } from 'types/addPrediction';

export const CBB_TEAM_FUTURES: MetricOption[] = [
  {
    value: 'FirstPlaceConference',
    label: 'First Place in their conference',
  },
  {
    value: 'LastPlaceConference',
    label: 'Last place in their conference',
  },
  {
    value: '2ndPlaceConference',
    label: '2nd Place in their conference',
  },
  {
    value: '3rdPlaceConference',
    label: '3rd Place in their conference',
  },
  {
    value: '4thPlaceConference',
    label: '4th Place in their conference',
  },
  {
    value: '5thPlaceConference',
    label: '5th Place in their conference',
  },
  {
    value: '6thPlaceConference',
    label: '6th Place in their conference',
  },
  {
    value: 'TopHalfConference',
    label: 'Finish in the Top Half of their conference',
  },
  {
    value: 'BottomHalfConference',
    label: 'Finish in the Bottom Half of their conference',
  },
  {
    value: 'QuarterFinalsConference',
    label: 'Quarter-Finals Appearance in their conference tournament',
  },
  {
    value: 'SemiFinalsConference',
    label: 'Semi-Finals appearance in their conference tournament',
  },
  {
    value: 'FinalsConference',
    label: 'Finals appearance in their conference tournament',
  },
  {
    value: 'ConferenceChampion',
    label: 'Conference Tournament Champion',
  },
  {
    value: 'NcaaTournamentBid',
    label: 'NCAA Tournament bid',
  },
  {
    value: 'NcaaTournamentRound64',
    label: 'Reach the Round of 64',
  },
  {
    value: 'NcaaTournamentRound32',
    label: 'Reach the Round of 32',
  },
  {
    value: 'NcaaTournamentRound16',
    label: 'Reach the Sweet 16',
  },
  {
    value: 'NcaaTournamentRound8',
    label: 'Reach the Elite 8',
  },
  {
    value: 'NcaaTournamentRound4',
    label: 'Reach the Final 4',
  },
  {
    value: 'NcaaTournamentFinals',
    label: 'Reach the NCAA Finals',
  },
  {
    value: 'NcaaTournamentChampion',
    label: 'NCAA Tournament Champion',
  },
];
