import './style.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/Button';
import { FullScreenSpinner } from 'components/Spinner';
import usePoolTemplateDetails from 'hooks/usePoolTemplateDetails';
import PoolPredictionPreview from 'screens/PoolDetails/components/PoolPredictionPreview';
import { type PoolPrediction } from 'types/pools';
import { getDateTime } from 'utils/date';

export const PoolTemplateDetails = () => {
  const navigate = useNavigate();
  const { poolTemplateIdentifier } = useParams();

  const {
    poolTemplate,
    poolTemplateLoading,
    poolTemplateError,
    isFetchingPoolTemplate,
  } = usePoolTemplateDetails(poolTemplateIdentifier || '');

  if (poolTemplateLoading || isFetchingPoolTemplate) {
    return <FullScreenSpinner />;
  }

  if (!poolTemplateError && poolTemplate) {
    const authorData = poolTemplate.poolTemplate.authorData;
    return (
      <div className="pool-template-details-container">
        <div>
          <p className="pool-template-title">
            {' '}
            {poolTemplate.poolTemplate.poolTemplateTitle}{' '}
          </p>
          <p className="pool-template-details">
            {' '}
            Status: {poolTemplate.poolTemplate.poolTemplateStatus}{' '}
          </p>
          <div>
            <p className="pool-template-details">
              # of predictions: {poolTemplate.poolTemplate.totalPredictions}
              <br /># of times used:{' '}
              {poolTemplate.poolTemplate.templateUsedCount}
            </p>
          </div>
          <div>
            <p className="pool-template-details">
              template open time:{' '}
              {getDateTime(new Date(poolTemplate.poolTemplate.goLiveTime))}
              <br />
              template close time:{' '}
              {getDateTime(
                new Date(poolTemplate.poolTemplate.poolTemplateCloseTime)
              )}
              <br />
              pool close time:{' '}
              {getDateTime(new Date(poolTemplate.poolTemplate.poolCloseTime))}
            </p>
          </div>
          <div>
            <p className="pool-template-details">
              pool author: {authorData.firstName} {authorData.lastName} (@
              {authorData.username})
            </p>
            <p>Leagues: {poolTemplate.poolTemplate.leagues}</p>
          </div>
        </div>
        <div className="predictions-container">
          <p className="pool-title"> predictions in pool: </p>
          {poolTemplate.poolPredictions?.length == 0 ? (
            <div> No predictions to display. </div>
          ) : (
            poolTemplate.poolPredictions.map(
              (prediction: PoolPrediction, index: number) => (
                <div className="pool-prediction" key={index}>
                  <PoolPredictionPreview prediction={prediction} />
                </div>
              )
            )
          )}
        </div>
        <Button
          accessibilityLabel="go-back-button"
          type="primary"
          text="Go Back"
          handleOnClick={() => navigate('/viewPoolTemplates')}
        />
      </div>
    );
  } else {
    return (
      <div>
        <p>Error Loading Pool Template.</p>
        <Button
          accessibilityLabel="go-back-button"
          type="primary"
          text="Go Back"
          handleOnClick={() => navigate('/viewPoolTemplates')}
        />
      </div>
    );
  }
};

export default PoolTemplateDetails;
