import React, { useRef } from 'react';

import { Button } from 'components/Button';

type ModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  submitAnnouncement: () => void;
  announcementTitle: string;
  announcementMessage: string;
  setCreatingAnnouncement: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Modal = ({
  setShowModal,
  submitAnnouncement,
  announcementTitle,
  announcementMessage,
  setCreatingAnnouncement,
}: ModalProps) => {
  // close the modal when clicking outside the modal.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const closeModal = (e: any) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  return (
    <div className="container" ref={modalRef} onClick={closeModal}>
      <div className="modal">
        <h2>Announcement Preview</h2>
        <div>
          <p>Annoucnement Title: {announcementTitle}</p>
          <p>Announcement Message: {announcementMessage}</p>
        </div>
        <Button
          accessibilityLabel="create announcement button"
          type="primary"
          text="Create Announcement"
          handleOnClick={() => {
            submitAnnouncement();
            setCreatingAnnouncement(false);
            setShowModal(false);
          }}
        />
        <Button
          accessibilityLabel="cancel announcement button"
          type="secondary"
          handleOnClick={() => {
            setCreatingAnnouncement(false);
            setShowModal(false);
          }}
          text="Cancel"
        />
      </div>
    </div>
  );
};
