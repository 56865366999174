import { type PoolStatusOption } from 'types/pools';

export const POOL_TEMPLATE_STATUS_OPTIONS: PoolStatusOption[] = [
  {
    label: 'Open',
    value: { id: 'OPEN' },
  },
  {
    label: 'Inactive',
    value: { id: 'INACTIVE' },
  },
];
