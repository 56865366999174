import {
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Tr,
  VStack,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CommunityPlaceholder } from 'assets';
import { Spinner } from 'components/Spinner';
import { useGetCommunities } from 'hooks/useGetCommunities';

export const Communities = () => {
  const navigate = useNavigate();
  const { communities, communitiesQuery } = useGetCommunities();

  const navigateToCommunity = (communityId: string) => {
    navigate(`/community/${communityId}`);
  };

  return (
    <VStack h="full" gap="20px" padding="20px">
      <Button
        id="create-community-button"
        onClick={() => navigate('/createCommunity')}
        flexShrink={0}
      >
        Create Community
      </Button>
      {communitiesQuery.isLoading ? (
        <Spinner />
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th color="text.primary">Name</Th>
              <Th color="text.primary">Users</Th>
              <Th color="text.primary">Privacy</Th>
              <Th color="text.primary">Verified?</Th>
            </Tr>
          </Thead>
          <Tbody>
            {communities?.pages[0].communities.map((community) => (
              <Tr
                key={community.communityId}
                w="full"
                _hover={{ bg: 'primary.400' }}
                _active={{ bg: 'primary.500' }}
                cursor="pointer"
                onClick={() => navigateToCommunity(community.communityId)}
              >
                <Td w="full" py="12px">
                  <HStack gap="16px">
                    <Image
                      src={community.logoUrl ?? CommunityPlaceholder}
                      boxSize="40px"
                      m="0"
                    />
                    <Text>{community.title}</Text>
                  </HStack>
                </Td>
                <Td>{community.numberOfMembers}</Td>
                <Td>{community.privacy}</Td>
                <Td>{community.verifiedCommunity ? 'Yes' : 'No'}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </VStack>
  );
};
