import React, { useEffect, useState } from 'react';

import { COMPARISON_OPTIONS } from 'constants/options';
import { type WizardStepProps } from 'screens/AddPrediction/types';
import { type ComparisonOption } from 'types/addPrediction';

export const ComparisonSelector = ({
  predContent,
  setPredContent,
}: WizardStepProps) => {
  // Manage Prediction Content Stat
  const [comparison, setComparison] = useState<ComparisonOption>(
    predContent.comparison || COMPARISON_OPTIONS[0]
  );

  useEffect(() => {
    setPredContent({
      ...predContent,
      comparison,
    });
  }, [comparison]);

  return (
    <div className="form-dropdown-container">
      <p>Select Comparison Value</p>
      <div className="options-row">
        {COMPARISON_OPTIONS.map((comparisonOption) => {
          return (
            <button
              key={comparisonOption.value.id}
              className={
                comparison.value.id === comparisonOption.value.id
                  ? 'option-selected'
                  : 'option'
              }
              onClick={() => setComparison(comparisonOption)}
            >
              {comparisonOption.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
