import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { chakraTheme } from 'chakra/theme';
import { datadogInit } from 'config/datadog';
import { AuthProvider } from 'contexts/AuthContext';
import { EnvironmentProvider } from 'contexts/EnvContext';
import { Router } from 'router';
import { NavigationWrapper } from 'router/NavigationWrapper';
import ErrorBoundary from 'screens/ErrorBoundary';
import '@fontsource/inter/800.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';

datadogInit();
function App() {
  const twelveHoursInMs = 1000 * 60 * 60 * 12;
  // disables queries from refetching unless page is refreshed or 12 hours pass
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twelveHoursInMs,
      },
    },
  });
  return (
    <div className="App">
      <ErrorBoundary silent={false}>
        <BrowserRouter>
          <ChakraProvider theme={chakraTheme}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <EnvironmentProvider>
                  <NavigationWrapper>
                    <Router />
                  </NavigationWrapper>
                </EnvironmentProvider>
              </AuthProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ChakraProvider>
        </BrowserRouter>
        <div id="recaptcha-container"></div>
      </ErrorBoundary>
    </div>
  );
}

export default App;
