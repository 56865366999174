const NFL_TEAM_METRICS = [
  {
    label: 'Score',
    description: 'Total team score',
    value: 'Score',
  },
  {
    label: 'Total Touchdowns',
    description: 'Total touchdowns scored',
    value: 'Touchdowns',
  },
  // TODO [#567]
  // Missing in sportsdata response for team stats currently
  // {
  //   label: 'Point Differential',
  //   description: "This team's total point differential",
  //   value: 'PointDifferential',
  // },
  {
    label: 'Offensive Plays',
    description: 'Number of offensive plays run',
    value: 'OffensivePlays',
  },
  {
    label: 'Offensive Yards',
    description: 'Number of offensive yards gained',
    value: 'OffensiveYards',
  },
  {
    label: 'Offensive Yards Per Play',
    description: 'Average yards gained per offensive play',
    value: 'OffensiveYardsPerPlay',
  },
  {
    label: 'Rushing Attempts',
    description: 'Number of rushing attempts',
    value: 'RushingAttempts',
  },
  {
    label: 'Rushing Yards',
    description: 'Number of rushing yards',
    value: 'RushingYards',
  },
  {
    label: 'Rushing Yards Per Attempt',
    description: 'Average rushing yards gained per attempt',
    value: 'RushingYardsPerAttempt',
  },
  {
    label: 'Rushing Touchdowns',
    description: 'Rushing touchdowns scored',
    value: 'RushingTouchdowns',
  },
  {
    label: 'Passing Attempts',
    description: 'Number of passes thrown',
    value: 'PassingAttempts',
  },
  {
    label: 'Passing Dropbacks',
    description: 'The number of times the offense dropped back to pass',
    value: 'PassingDropbacks',
  },
  {
    label: 'Passing Completions',
    description: 'Number of pass completions',
    value: 'PassingCompletions',
  },
  {
    label: 'Passing Yards',
    description: 'Number of passing yards',
    value: 'PassingYards',
  },
  {
    label: 'Passing Touchdowns',
    description: 'Number of passing touchdowns thrown',
    value: 'PassingTouchdowns',
  },
  {
    label: 'Passing Interceptions',
    description: 'Interceptions thrown',
    value: 'PassingInterceptions',
  },
  {
    label: 'Passing Yards Per Attempt',
    description: 'Average passing yards gained per attempt',
    value: 'PassingYardsPerAttempt',
  },
  {
    label: 'Passing Yards Per Completion',
    description: 'Average passing yards gained per completion',
    value: 'PassingYardsPerCompletion',
  },
  {
    label: 'Completion Percentage',
    description: 'Percentage of passes that were completed',
    value: 'CompletionPercentage',
  },
  {
    label: 'Passer Rating',
    description: 'Passer Rating',
    value: 'PasserRating',
  },
  {
    label: 'First Downs',
    description: 'Total first downs',
    value: 'FirstDowns',
  },
  {
    label: 'First Downs By Rushing',
    description: 'Total rushing first downs',
    value: 'FirstDownsByRushing',
  },
  {
    label: 'First Downs By Passing',
    description: 'Total passing first downs',
    value: 'FirstDownsByPassing',
  },
  {
    label: 'First Downs By Penalty',
    description: "Total first downs by opponent's penalty",
    value: 'FirstDownsByPenalty',
  },
  {
    label: 'Third Down Attempts',
    description: 'Third down attempts',
    value: 'ThirdDownAttempts',
  },
  {
    label: 'Third Down Conversions',
    description: 'Third down conversions',
    value: 'ThirdDownConversions',
  },
  {
    label: 'Third Down Percentage',
    description: 'Third down percentage',
    value: 'ThirdDownPercentage',
  },
  {
    label: 'Fourth Down Attempts',
    description: 'Fourth down attempts',
    value: 'FourthDownAttempts',
  },
  {
    label: 'Fourth Down Conversions',
    description: 'Fourth down conversions',
    value: 'FourthDownConversions',
  },
  {
    label: 'Fourth Down Percentage',
    description: 'Fourth down percentage',
    value: 'FourthDownPercentage',
  },
  {
    label: 'Red Zone Attempts',
    description: 'Red zone opportunities',
    value: 'RedZoneAttempts',
  },
  {
    label: 'Red Zone Conversions',
    description: 'Red zone opportunities converted to touchdowns',
    value: 'RedZoneConversions',
  },
  {
    label: 'Red Zone Percentage',
    description:
      'Percentage of red zone opportunities converted into touchdowns',
    value: 'RedZonePercentage',
  },
  // TODO [#374]: Make this a custom metric that we handle. Right now the input
  // would be a pretty complicated to parse and compare.
  // {
  //   label: 'Time Of Possession',
  //   description: 'Time of possession',
  //   value: 'TimeOfPossession',
  // },
  {
    label: 'Return Yards',
    description: 'Total punt and defensive return yards',
    value: 'ReturnYards',
  },
  {
    label: 'Penalties',
    description: 'Penalties Committed',
    value: 'Penalties',
  },
  {
    label: 'Penalty Yards',
    description: 'Penalty yards enforced against team',
    value: 'PenaltyYards',
  },
  {
    label: 'Fumbles',
    description: 'Fumbles',
    value: 'Fumbles',
  },
  {
    label: 'Fumbles Lost',
    description: 'Number of fumbles recovered by opponent',
    value: 'FumblesLost',
  },
  {
    label: 'Times Sacked',
    description: 'Number of sacks allowed by Team',
    value: 'TimesSacked',
  },
  {
    label: 'Times Sacked Yards Lost',
    description: 'Number of yards lost as a result of sacks allowed',
    value: 'TimesSackedYards',
  },
  {
    label: 'Quarterback Hits (includes sacks)',
    description: 'Number of times the opposing QB was hit (includes sacks)',
    value: 'QuarterbackHits',
  },
  {
    label: 'Tackles For Loss',
    description:
      "Tackles behind the opponent's line of scrimmage (sacks are also credited as a tackle for loss)",
    value: 'TacklesForLoss',
  },
  {
    label: 'Caused Safeties',
    description:
      'Defensive safeties (sacks in end zone, solo tackles' +
      'in end zone, blocked kicks that went out of bounds in the end zone)',
    value: 'Safeties',
  },
  {
    label: 'Punts',
    description: 'Number of punts',
    value: 'Punts',
  },
  {
    label: 'Total Punt Yards',
    description: 'Total punt yards',
    value: 'PuntYards',
  },
  {
    label: 'Punt Yards Average',
    description: 'Average number of yards per punt',
    value: 'PuntAverage',
  },
  {
    label: 'Giveaways',
    description: 'Number of giveaways',
    value: 'Giveaways',
  },
  {
    label: 'Takeaways',
    description: 'Number of takeaways',
    value: 'Takeaways',
  },
  {
    label: 'Turnover Differential',
    description: 'Number of takeaways minus giveaways',
    value: 'TurnoverDifferential',
  },
  {
    label: 'QB Hits Differential',
    description:
      "The differential of hits on the opposing quarterback minus hits on own team's quarterback",
    value: 'QuarterbackHitsDifferential',
  },
  {
    label: 'Tackles For Loss Differential',
    description:
      "The differential of tackles for loss minus opponent's tackles for loss",
    value: 'TacklesForLossDifferential',
  },
  {
    label: 'QB Sacks Differential',
    description:
      "The Team's sack differential (similar to a turnover differential)",
    value: 'QuarterbackSacksDifferential',
  },
  {
    label: 'Tackles For Loss Percentage',
    description:
      'Percentage of running plays defended that resulted in tackle for loss',
    value: 'TacklesForLossPercentage',
  },
  {
    label: 'QB Hits Percentage',
    description:
      'Percentage of opposing quarterback drop backs that resulted hitting the quarterback',
    value: 'QuarterbackHitsPercentage',
  },
  {
    label: 'Times Sacked Percentage',
    description: 'Percentage of drop backs that resulted in a sack',
    value: 'TimesSackedPercentage',
  },
  {
    label: 'Kickoffs',
    description: 'Number of kickoffs',
    value: 'Kickoffs',
  },
  {
    label: 'Kickoffs In End Zone',
    description: 'Number of kickoffs that went into the end zone',
    value: 'KickoffsInEndZone',
  },
  {
    label: 'Kickoff Touchbacks',
    description: 'Number of kickoffs that resulted in touchbacks',
    value: 'KickoffTouchbacks',
  },
  {
    label: 'Punts Had Blocked',
    description: 'Number of punts that were blocked',
    value: 'PuntsHadBlocked',
  },
  {
    label: 'Extra Point Kicking Attempts',
    description: 'Extra point kicks attempted',
    value: 'ExtraPointKickingAttempts',
  },
  {
    label: 'Extra Point Kicks Made',
    description: 'Extra point kicks made',
    value: 'ExtraPointKickingConversions',
  },
  {
    label: 'Extra Points Had Blocked',
    description: 'Extra point kick attempts that were blocked',
    value: 'ExtraPointsHadBlocked',
  },
  {
    label: 'Extra Point Passing Attempts',
    description: 'Two point conversion passing attempts',
    value: 'ExtraPointPassingAttempts',
  },
  {
    label: 'Extra Point Passing Conversions',
    description: 'Two point conversion passing conversions',
    value: 'ExtraPointPassingConversions',
  },
  {
    label: 'Extra Point Rushing Attempts',
    description: 'Two point conversion rushing attempts',
    value: 'ExtraPointRushingAttempts',
  },
  {
    label: 'Extra Point Rushing Conversions',
    description: 'Two point conversion rushing conversions',
    value: 'ExtraPointRushingConversions',
  },
  {
    label: 'Field Goal Attempts',
    description: 'Field goals attempted',
    value: 'FieldGoalAttempts',
  },
  {
    label: 'Field Goals Made',
    description: 'Number of successful field goal attempts',
    value: 'FieldGoalsMade',
  },
  {
    label: 'Field Goals Had Blocked',
    description: 'Field goal attempts that were blocked',
    value: 'FieldGoalsHadBlocked',
  },
  {
    label: 'Punt Returns',
    description: 'Number of punt return attempts',
    value: 'PuntReturns',
  },
  {
    label: 'Punt Return Yards',
    description: 'Total return yards on punts',
    value: 'PuntReturnYards',
  },
  {
    label: 'Kickoff Returns',
    description: 'Kickoff returns',
    value: 'KickReturns',
  },
  {
    label: 'Kickoff Return Yards',
    description: 'Kickoff Return Yards',
    value: 'KickReturnYards',
  },
  {
    label: 'Defensive Interceptions',
    description: 'Defensive interceptions',
    value: 'InterceptionReturns',
  },
  {
    label: 'Interception Return Yards',
    description: 'Return yards from interceptions',
    value: 'InterceptionReturnYards',
  },
  {
    label: 'Solo Tackles',
    description: 'Solo, unassisted tackles',
    value: 'SoloTackles',
  },
  {
    label: 'Assisted Tackles',
    description: 'Assisted tackles (also called a half tackle)',
    value: 'AssistedTackles',
  },
  {
    label: 'Defensive Sacks',
    description: 'Sacks of the opposing quarterback',
    value: 'Sacks',
  },
  {
    label: 'Defensive Sack Yards',
    description: 'Yards lost as a result of sacking the opposing quarterback',
    value: 'SackYards',
  },
  {
    label: 'Defensive Passes Defended',
    description: 'Passes defended or batted down',
    value: 'PassesDefended',
  },
  {
    label: 'Defensive Fumbles Forced',
    description: 'Number of fumbles forced',
    value: 'FumblesForced',
  },
  {
    label: 'Fumbles Recovered',
    description: 'Fumbles recovered that resulted in change of possession',
    value: 'FumblesRecovered',
  },
  {
    label: 'Fumble Return Yards',
    description: 'Return yards from fumble recoveries',
    value: 'FumbleReturnYards',
  },
  {
    label: 'Fumble Returned For Touchdowns',
    description: 'Fumbles returned for touchdowns',
    value: 'FumbleReturnTouchdowns',
  },
  {
    label: 'Interception Returned For Touchdowns',
    description: 'Defensive interceptions returned for touchdowns',
    value: 'InterceptionReturnTouchdowns',
  },
  {
    label: 'Blocked Field Goals + Punts',
    description: "Total number of opponent's kicks that were blocked",
    value: 'BlockedKicks',
  },
  {
    label: 'Punt Returns For Touchdowns',
    description: 'Punts returned for touchdown',
    value: 'PuntReturnTouchdowns',
  },
  {
    label: 'Longest Punt Return (Yards)',
    description: 'Longest punt return (in yards)',
    value: 'PuntReturnLong',
  },
  {
    label: 'Kicks Returned For Touchdowns',
    description: 'Number of kicks returned for a touchdown',
    value: 'KickReturnTouchdowns',
  },
  {
    label: 'Longest Kick Return (Yards)',
    description: 'Longest kick return',
    value: 'KickReturnLong',
  },
  {
    label: 'Blocked Field Goal + Punt Return yards',
    description: 'Blocked kick recovery return yards',
    value: 'BlockedKickReturnYards',
  },
  {
    label: 'Blocked Kick Returned For Touchdowns',
    description: 'Blocked kick recovery return touchdowns',
    value: 'BlockedKickReturnTouchdowns',
  },
  {
    label: 'Field Goal Return Yards',
    description: 'Field goal return yards (excluding blocked field goals)',
    value: 'FieldGoalReturnYards',
  },
  {
    label: 'Field Goals Returned For Touchdowns',
    description: 'Field goal return touchdowns (excluding blocked field goals)',
    value: 'FieldGoalReturnTouchdowns',
  },
  {
    label: 'Two Point Conversion Returns',
    description: 'Two point conversion returns for two points.',
    value: 'TwoPointConversionReturns',
  },
];

const OFF_PASSING_METRICS = [
  {
    label: 'Passing Attempts',
    description: 'Number of passes thrown',
    value: 'PassingAttempts',
  },
  {
    label: 'Passing Completions',
    description: 'Number of pass completions',
    value: 'PassingCompletions',
  },
  {
    label: 'Passing Yards',
    description: 'Number of passing yards',
    value: 'PassingYards',
  },
  {
    label: 'Passing Completion Percentage',
    description: 'Percentage of passes that were completed',
    value: 'PassingCompletionPercentage',
  },
  {
    label: 'Passing Yards Per Attempt',
    description: 'Average passing yards gained per attempt',
    value: 'PassingYardsPerAttempt',
  },
  {
    label: 'Passing Yards Per Completion',
    description: 'Average passing yards gained per completion',
    value: 'PassingYardsPerCompletion',
  },
  {
    label: 'Passing Touchdowns',
    description: 'Number of passing touchdowns thrown',
    value: 'PassingTouchdowns',
  },
  {
    label: 'Passing Interceptions',
    description: 'Interceptions thrown',
    value: 'PassingInterceptions',
  },
  {
    label: 'Passer Rating',
    description: 'Passer rating',
    value: 'PassingRating',
  },
  {
    label: 'Longest Completion',
    description: 'Longest passing completion',
    value: 'PassingLong',
  },
  {
    label: 'Times Sacked',
    description: 'Number of times sacked',
    value: 'PassingSacks',
  },
  {
    label: 'Sacked Loss Yards',
    description: 'Yards lost as a result of being sacked',
    value: 'PassingSackYards',
  },
  {
    label: 'Two Point Conversion Passes',
    description: 'Successful two point conversion passes',
    value: 'TwoPointConversionPasses',
  },
];

const OFF_RUSHING_METRICS = [
  {
    label: 'Rushing Attempts',
    description: 'Number of rushing attempts',
    value: 'RushingAttempts',
  },
  {
    label: 'Rushing Yards',
    description: 'Number of rushing yards',
    value: 'RushingYards',
  },
  {
    label: 'Rushing Yards Per Attempt',
    description: 'Average rushing yards gained per attempt',
    value: 'RushingYardsPerAttempt',
  },
  {
    label: 'Rushing Touchdowns',
    description: 'Rushing touchdowns scored',
    value: 'RushingTouchdowns',
  },
  {
    label: 'Longest Rush',
    description: 'Longest rush',
    value: 'RushingLong',
  },
  {
    label: 'Two Point Conversion Runs',
    description: 'Successful two point conversion runs',
    value: 'TwoPointConversionRuns',
  },
];

const OFF_RECEIVING_METRICS = [
  {
    label: 'Receiving Targets',
    description: 'Number of times targeted by passer',
    value: 'ReceivingTargets',
  },
  {
    label: 'Receptions',
    description: 'Number of receptions',
    value: 'Receptions',
  },
  {
    label: 'Receiving Yards',
    description: 'Total receiving yards',
    value: 'ReceivingYards',
  },
  {
    label: 'Receiving Yards Per Reception',
    description: 'Average yards gained per reception',
    value: 'ReceivingYardsPerReception',
  },
  {
    label: 'Receiving Touchdowns',
    description: 'Number of receiving touchdowns',
    value: 'ReceivingTouchdowns',
  },
  {
    label: 'Longest Reception',
    description: 'Longest reception',
    value: 'ReceivingLong',
  },
  {
    label: 'Reception Percentage',
    description: 'Percentage of ReceivingTargets convert into Receptions',
    value: 'ReceptionPercentage',
  },
  {
    label: 'Receiving Yards Per Target',
    description: 'Average yards gained per ReceivingTargets',
    value: 'ReceivingYardsPerTarget',
  },
  {
    label: 'Two Point Conversion Receptions',
    description: 'Successful two point conversion receptions',
    value: 'TwoPointConversionReceptions',
  },
];

const OFF_BASE_METRICS = [
  {
    label: 'Total Touchdowns',
    description: 'Total touchdowns scored',
    value: 'TotalTouchdowns',
  },
  {
    label: 'Offensive Snaps Played',
    description: 'The number of snaps this player played on offense',
    value: 'OffensiveSnapsPlayed',
  },
  {
    label: 'Safeties Allowed',
    description:
      'Safeties allowed (tackled in end zone, sacked in end zone, ' +
      'ran out of bounds in end zone, or committed a penalty in end ' +
      'zone, e.g. Intentional Grounding or Offensive Holding)',
    value: 'SafetiesAllowed',
  },
];

const OFF_DEFENSIVE_METRICS = [
  {
    label: 'Fumbles',
    description: 'Fumbles',
    value: 'Fumbles',
  },
  {
    label: 'Fumbles Lost',
    description: 'Number of fumbles recovered by opponent',
    value: 'FumblesLost',
  },
  {
    label: 'Fumbles (Team Recovers)',
    description: 'Own team recovers fumble (did not result in a turnover)',
    value: 'FumblesOwnRecoveries',
  },
  {
    label: 'Fumbles Out of Bounds',
    description:
      'Fumbles by this player that went out of bounds (no one was awarded the recovery)',
    value: 'FumblesOutOfBounds',
  },
  // TODO [##516]
  // Removed since this is only in Player Game Stats not Player Season Stats
  // {
  //   label: 'Offensive Fumble Recovery Touchdowns',
  //   description: 'Touchdowns scored by an offensive player recovering a fumble',
  //   value: 'OffensiveFumbleRecoveryTouchdowns',
  // },
  {
    label: 'Offensive Solo Tackles',
    description: 'Solo tackles when playing offense (after a turnover)',
    value: 'MiscSoloTackles',
  },
  {
    label: 'Offensive Assisted Tackles',
    description: 'Assisted tackles when playing offense (after a turnover)',
    value: 'MiscAssistedTackles',
  },
  {
    label: 'Offensive Fumbles Forced (Post Turnover)',
    description: 'Fumbles forced after a turnover on offensive plays',
    value: 'MiscFumblesForced',
  },
  {
    label: 'Offensive Fumbles Recovered (Post Turnover)',
    description: 'Fumbles recovered after a turnover on offensive plays',
    value: 'MiscFumblesRecovered',
  },
];

const DEF_METRICS = [
  {
    label: 'Total Tackles',
    description: 'Sum of SoloTackles and AssistedTackles',
    value: 'Tackles',
  },
  {
    label: 'Solo Tackles',
    description: 'Solo, unassisted tackles',
    value: 'SoloTackles',
  },
  {
    label: 'Assisted Tackles',
    description: 'Assisted tackles (also called a half tackle)',
    value: 'AssistedTackles',
  },
  {
    label: 'Tackles For Loss',
    description:
      "Tackles behind the opponent's line of scrimmage (sacks are also credited as a tackle for loss)",
    value: 'TacklesForLoss',
  },
  {
    label: 'Defensive Sacks',
    description: 'Sacks of the opposing quarterback',
    value: 'Sacks',
  },
  {
    label: 'Defensive Sack Yards',
    description: 'Yards lost as a result of sacking the opposing quarterback',
    value: 'SackYards',
  },
  {
    label: 'Quarterback Hits (includes sacks)',
    description:
      'Number of times hitting an opposing quarterback (this includes sacks)',
    value: 'QuarterbackHits',
  },
  {
    label: 'Interceptions',
    description: 'Number of interceptions',
    value: 'Interceptions',
  },
  {
    label: 'Interception Return Yards',
    description: 'Return yards from interceptions',
    value: 'InterceptionReturnYards',
  },
  {
    label: 'Interception Returned For Touchdowns',
    description: 'Return touchdowns from interceptions',
    value: 'InterceptionReturnTouchdowns',
  },
  {
    label: 'Defensive Passes Defended',
    description: 'Passes defended or batted down',
    value: 'PassesDefended',
  },
  {
    label: 'Defensive Fumbles Forced',
    description: 'Number of fumbles forced',
    value: 'FumblesForced',
  },
  {
    label: 'Fumbles Recovered',
    description: 'Fumbles recovered that resulted in change of possession',
    value: 'FumblesRecovered',
  },
  {
    label: 'Fumble Return Yards',
    description: 'Return yards from fumble recoveries',
    value: 'FumbleReturnYards',
  },
  {
    label: 'Fumble Returned For Touchdowns',
    description: 'Return touchdowns from fumble recoveries',
    value: 'FumbleReturnTouchdowns',
  },
  {
    label: 'Caused Safeties',
    description:
      'Defensive safeties (sacks in end zone, solo tackles in end zone, ' +
      'blocked kicks that went out of bounds in the end zone)',
    value: 'Safeties',
  },
  {
    label: 'Defensive Snaps Played',
    description: 'The number of snaps this player played on defense',
    value: 'DefensiveSnapsPlayed',
  },
  {
    label: 'Two Point Conversion Returns',
    description: 'Two point conversions returned for two points',
    value: 'TwoPointConversionReturns',
  },
];

const ST_PUNT_METRICS = [
  {
    label: 'Punts',
    description: 'Number of punts',
    value: 'Punts',
  },
  {
    label: 'Total Punt Yards',
    description: 'Total number of punt yards',
    value: 'PuntYards',
  },
  {
    label: 'Punt Yards Average',
    description: 'Average number of yards per punt',
    value: 'PuntAverage',
  },
  {
    label: 'Longest Punt',
    description: 'Longest punt',
    value: 'PuntLong',
  },
  {
    label: 'Punt Touchbacks',
    description: 'Punts by this player that were touchbacks',
    value: 'PuntTouchbacks',
  },
  {
    label: 'Punt Inside 20',
    description:
      'Punts by this player that were downed inside the 20 yard line',
    value: 'PuntInside20',
  },
  {
    label: 'Punts Had Blocked',
    description: 'Number of punts that were blocked',
    value: 'PuntsHadBlocked',
  },
];

const ST_FIELD_GOAL_METRICS = [
  {
    label: 'Field Goals Attempted',
    description: 'Number of field goal attempts',
    value: 'FieldGoalsAttempted',
  },
  {
    label: 'Field Goals Made',
    description: 'Number of successful field goal attempts',
    value: 'FieldGoalsMade',
  },
  {
    label: 'Longest Field Goal Made',
    description: 'Longest successful field goal attempt',
    value: 'FieldGoalsLongestMade',
  },
  {
    label: 'Field Goal Percentage',
    description: 'Percentage of Field Goal attempts that were successful',
    value: 'FieldGoalPercentage',
  },
  {
    label: 'Field Goals Made: 0-19 Yards',
    description: 'Field goals made of 0-19yards',
    value: 'FieldGoalsMade0to19',
  },
  {
    label: 'Field Goals Made: 20-29 Yards',
    description: 'Field goals made of 20-29yards',
    value: 'FieldGoalsMade20to29',
  },
  {
    label: 'Field Goals Made: 30-39 Yards',
    description: 'Field goals made of 30-39yards',
    value: 'FieldGoalsMade30to39',
  },
  {
    label: 'Field Goals Made: 40-49 Yards',
    description: 'Field goals made of 40-49yards',
    value: 'FieldGoalsMade40to49',
  },
  {
    label: 'Field Goals Made: 50+ Yards',
    description: 'Field goals made of 50+ yards',
    value: 'FieldGoalsMade50Plus',
  },
  {
    label: 'Field Goals Had Blocked',
    description: 'Field goal attempts that were blocked',
    value: 'FieldGoalsHadBlocked',
  },
];

const ST_EXTRA_POINT_METRICS = [
  {
    label: 'Extra Points Attempted',
    description: 'Extra point kicks attempted',
    value: 'ExtraPointsAttempted',
  },
  {
    label: 'Extra Points Made',
    description: 'Number of successful extra points',
    value: 'ExtraPointsMade',
  },
  {
    label: 'Extra Points Had Blocked',
    description: 'Extra point kick attempts that were blocked',
    value: 'ExtraPointsHadBlocked',
  },
];

const ST_MISC_RETURN_METRICS = [
  {
    label: 'Field Goals Returned For Touchdowns',
    description: 'Field goals that this player returned for touchdowns',
    value: 'FieldGoalReturnTouchdowns',
  },
  {
    label: 'Blocked Field Goal + Punt Return yards',
    description: 'Blocked kick recovery return yards',
    value: 'BlockedKickReturnYards',
  },
  {
    label: 'Blocked Field Goals + Punts',
    description: "Total number of opponent's kicks that were blocked",
    value: 'BlockedKicks',
  },
  {
    label: 'Field Goal Return Yards',
    description: 'Field goal return yards (excluding blocked field goals)',
    value: 'FieldGoalReturnYards',
  },
];

const ST_PUNT_RETURN_METRICS = [
  {
    label: 'Punt Returns',
    description: 'Number of punt return attempts',
    value: 'PuntReturns',
  },
  {
    label: 'Punt Return Yards',
    description: 'Total return yards on punts',
    value: 'PuntReturnYards',
  },
  {
    label: 'Punt Return Yards Per Attempt',
    description: 'Average yards gained per punt return',
    value: 'PuntReturnYardsPerAttempt',
  },
  {
    label: 'Longest Punt Return (Yards)',
    description: 'Longest punt return',
    value: 'PuntReturnLong',
  },
  {
    label: 'Punt Returns For Touchdowns',
    description: 'Number of touchdowns on punt returns',
    value: 'PuntReturnTouchdowns',
  },
  {
    label: 'Punt Return Fair Catches',
    description: 'Fair catches made on punts',
    value: 'PuntReturnFairCatches',
  },
];

const ST_KICK_RETURN_METRICS = [
  {
    label: 'Kickoff Returns',
    description: 'Kickoff returns',
    value: 'KickReturns',
  },
  {
    label: 'Kickoff Return Yards',
    description: 'Kickoff Return Yards',
    value: 'KickReturnYards',
  },
  {
    label: 'Kick Return ',
    description: 'Average yards gained per kick return',
    value: 'KickReturnYardsPerAttempt',
  },
  {
    label: 'Longest Kick Return (Yards)',
    description: 'Longest kick return',
    value: 'KickReturnLong',
  },
  {
    label: 'Kicks Returned For Touchdowns',
    description: 'Number of touchdowns on kick returns',
    value: 'KickReturnTouchdowns',
  },
  {
    label: 'Kick Return Fair Catches',
    description: 'Fair catches made on kickoffs',
    value: 'KickReturnFairCatches',
  },
  {
    label: 'Blocked Kick Returned For Touchdowns',
    description: 'Blocked kick recovery return touchdowns',
    value: 'BlockedKickReturnTouchdowns',
  },
];

const ST_RETURN_METRICS = [
  ...ST_PUNT_RETURN_METRICS,
  ...ST_KICK_RETURN_METRICS,
  ...ST_MISC_RETURN_METRICS,
];

const ST_DEF_BASE_METRICS = [
  {
    label: 'Special Teams Snaps Played',
    description: 'The number of snaps this player played on special teams',
    value: 'SpecialTeamsSnapsPlayed',
  },
  {
    label: 'Special Teams Forced Fumbles',
    description: 'Fumbles forced on special teams plays',
    value: 'SpecialTeamsFumblesForced',
  },
  {
    label: 'Special Teams Fumbles Recovered',
    description: 'Fumbles recovered on special teams plays',
    value: 'SpecialTeamsFumblesRecovered',
  },
  {
    label: 'Special Teams Solo Tackles',
    description: 'Solo tackles on kick and punt plays',
    value: 'SpecialTeamsSoloTackles',
  },
  {
    label: 'Special Teams Assisted Tackles',
    description: 'Assisted tackles on kick and punt plays',
    value: 'SpecialTeamsAssistedTackles',
  },
];

// METRIC GROUPINGS:
// POSITION_CATEGORY: ST + POSITION: P
const NFL_PUNTER_METRICS = [
  ...ST_PUNT_METRICS,
  ...ST_DEF_BASE_METRICS,
  ...ST_FIELD_GOAL_METRICS,
  ...ST_EXTRA_POINT_METRICS,
];

// POSITION_CATEGORY: ST + POSITION: K
const NFL_KICKER_METRICS = [
  ...ST_FIELD_GOAL_METRICS,
  ...ST_EXTRA_POINT_METRICS,
  ...ST_DEF_BASE_METRICS,
  ...ST_PUNT_METRICS,
];

// POSITION_CATEGORY: ST + POSITION: OTHER
const NFL_OTHER_ST_METRICS = [...ST_RETURN_METRICS, ...ST_DEF_BASE_METRICS];

// POSITION_CATEGORY: OFF + POSITION: QB
const NFL_QB_METRICS = [
  ...OFF_PASSING_METRICS,
  ...OFF_BASE_METRICS,
  ...OFF_RUSHING_METRICS,
  ...OFF_DEFENSIVE_METRICS,
  ...OFF_RECEIVING_METRICS,
];

// POSITION_CATEGORY: OFF + POSITION: RB
const NFL_RB_METRICS = [
  ...OFF_RUSHING_METRICS,
  ...OFF_BASE_METRICS,
  ...OFF_RECEIVING_METRICS,
  ...OFF_DEFENSIVE_METRICS,
  ...OFF_PASSING_METRICS,
  ...ST_RETURN_METRICS,
  ...ST_DEF_BASE_METRICS,
];

// POSITION_CATEGORY: OFF + POSITION: WR
const NFL_WR_METRICS = [
  ...OFF_RECEIVING_METRICS,
  ...OFF_BASE_METRICS,
  ...OFF_RUSHING_METRICS,
  ...OFF_DEFENSIVE_METRICS,
  ...OFF_PASSING_METRICS,
  ...ST_RETURN_METRICS,
  ...ST_DEF_BASE_METRICS,
];

// POSITION_CATEGORY: OFF  + POSITION: OTHER
const NFL_OTHER_OFF_METRICS = [
  ...OFF_BASE_METRICS,
  ...OFF_RUSHING_METRICS,
  ...OFF_RECEIVING_METRICS,
  ...OFF_DEFENSIVE_METRICS,
  ...OFF_PASSING_METRICS,
  ...ST_RETURN_METRICS,
  ...ST_DEF_BASE_METRICS,
];

// POSITION CATEGORY: DEF
const NFL_DEFENSE_METRICS = [
  ...DEF_METRICS,
  ...ST_DEF_BASE_METRICS,
  ...ST_RETURN_METRICS,
];

const NFL_ALL_METRICS = [
  ...NFL_TEAM_METRICS,
  ...OFF_PASSING_METRICS,
  ...OFF_RUSHING_METRICS,
  ...OFF_RECEIVING_METRICS,
  ...OFF_BASE_METRICS,
  ...OFF_DEFENSIVE_METRICS,
  ...DEF_METRICS,
  ...ST_PUNT_METRICS,
  ...ST_FIELD_GOAL_METRICS,
  ...ST_EXTRA_POINT_METRICS,
  ...ST_RETURN_METRICS,
  ...ST_DEF_BASE_METRICS,
];

export {
  NFL_TEAM_METRICS,
  NFL_QB_METRICS,
  NFL_WR_METRICS,
  NFL_RB_METRICS,
  NFL_OTHER_OFF_METRICS,
  NFL_DEFENSE_METRICS,
  NFL_PUNTER_METRICS,
  NFL_KICKER_METRICS,
  NFL_OTHER_ST_METRICS,
  NFL_ALL_METRICS,
};
