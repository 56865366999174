import { type MetricOption } from 'types/addPrediction';

export const MLB_PLAYER_FUTURES: MetricOption[] = [
  {
    value: 'mlbMVP',
    label: 'MVP',
  },
  {
    value: 'mlbRookieOfTheYear',
    label: 'Rookie of the Year',
  },
  {
    value: 'mlbCyYoung',
    label: 'CY Young Award',
  },
  {
    value: 'mlbHomeRunDerbyWinner',
    label: 'Home Run Derby Winner',
  },
  {
    value: 'mlbHomeRunDerbyInvite',
    label: 'Invitation to the Home Run Derby',
  },
  {
    value: 'mlbMostHRs',
    label: 'Most Home Runs',
  },
  {
    value: 'mlbMostHits',
    label: 'Most Hits',
  },
  {
    value: 'mlbMostTriples',
    label: 'Most Triples',
  },
  {
    value: 'mlbMostDoubles',
    label: 'Most Doubles',
  },
  {
    value: 'mlbMostSingles',
    label: 'Most Singles',
  },
  {
    value: 'mlbMostRBIs',
    label: 'Most RBIs',
  },
  {
    value: 'mlbMostRunsScored',
    label: 'Most Runs Scored',
  },
  {
    value: 'mlbMostTimesWalked',
    label: 'Most Times Walked',
  },
  {
    value: 'mlbMostTimesStruckOut',
    label: 'Most Times Struck Out (Batter)',
  },
  {
    value: 'mlbMostStolenBases',
    label: 'Most Stolen Bases',
  },
  {
    value: 'mlbHighestBattingAverage',
    label: 'Highest Batting Average',
  },
  {
    value: 'mlbMostStrikeouts',
    label: 'Most Strikeouts (Pitcher)',
  },
  {
    value: 'mlbMostWins',
    label: 'Most Wins (Pitcher)',
  },
  {
    value: 'mlbMostWalks',
    label: 'Most Walks (Pitcher)',
  },
  {
    value: 'mlbMostSaves',
    label: 'Most Saves (Pitcher)',
  },
  {
    value: 'mlbLowestERA',
    label: 'Lowest ERA',
  },
  {
    value: 'mlbHighestERA',
    label: 'Highest ERA',
  },
];
