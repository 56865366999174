import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { AddPredictionWizard } from './components/AddPredictionWizard';
import { ChallengeInputDetails } from './components/ChallengeDetailsInput';
import { CategorySelector } from './components/steps/CategorySelector';
import { DurationSelector } from './components/steps/DurationSelector';
import { LeagueSelector } from './components/steps/LeagueSelector';
import {
  type ChallengeAllPostBody,
  type ChallengeContent,
  type PredInfo,
} from './types';
import { getChallengeAllPred } from './utils';
import { postChallengeAll } from 'api/challenge';
import {
  CATEGORY_OPTIONS,
  DURATION_OPTIONS,
  LEAGUE_OPTIONS,
} from 'constants/options';

import './style.css';

type AddPredictionProps = {
  type: 'ChallengeAll';
};

export const AddPrediction = ({ type }: AddPredictionProps) => {
  const navigate = useNavigate();
  const [placingPrediction, setPlacingPrediction] = useState(false);

  // General Prediction State
  const [category, setCategory] = useState(CATEGORY_OPTIONS[0]);
  const [duration, setDuration] = useState(DURATION_OPTIONS[0]);
  const [league, setLeague] = useState(LEAGUE_OPTIONS[0]);

  const [predictionInfo, setPredictionInfo] = useState<PredInfo>({
    category: category.value.id,
    duration: duration.value.id,
    league: league.value.id,
  });

  const [lhsDuration, setLhsDuration] = useState(DURATION_OPTIONS[0]);
  const [lhsPredInfo, setLhsPredInfo] = useState<PredInfo>({
    category: 'statistic',
    league: league.value.id,
    duration: lhsDuration.value.id,
  });

  const [rhsDuration, setRhsDuration] = useState(DURATION_OPTIONS[0]);
  const [rhsPredInfo, setRhsPredInfo] = useState<PredInfo>({
    category: 'statistic',
    league: league.value.id,
    duration: rhsDuration.value.id,
  });

  // Update main pred content
  useEffect(() => {
    const newPred = {
      ...predictionInfo,
    };

    // Clear potentially unmatched attributes
    if (
      league.value.id !== predictionInfo.league ||
      category.value.id !== predictionInfo.category
    ) {
      delete newPred['entity'];
      delete newPred['metric'];
      delete newPred['value'];
      delete newPred['game'];
      delete newPred['season'];
    }

    setPredictionInfo({
      ...newPred,
      category: category.value.id,
      duration: category.value.id === 'future' ? 'season' : duration.value.id,
      league: league.value.id,
    });
  }, [category, duration, league]);

  // Update LHS compare pred
  useEffect(() => {
    setLhsPredInfo({
      ...lhsPredInfo,
      league: league.value.id,
      duration: lhsDuration.value.id,
    });
  }, [league, lhsDuration]);

  // Update RHS compare pred
  useEffect(() => {
    setRhsPredInfo({
      ...rhsPredInfo,
      league: league.value.id,
      duration: rhsDuration.value.id,
    });
  }, [league, rhsDuration]);

  // Challenge Information
  const [challengeContent, setChallengeContent] = useState<ChallengeContent>({
    isAdminChallenge: true,
    challengePoints: 0,
  });

  const submitChallenge = useCallback(async () => {
    const prediction = getChallengeAllPred(
      predictionInfo,
      lhsPredInfo,
      rhsPredInfo
    );
    const body: ChallengeAllPostBody = {
      challengerId: challengeContent.challengerId || '', // NOTE: We might need to check this beforeHand
      prediction,
      challengePoints: challengeContent.challengePoints,
      adminChallengeDetails: {
        isAdminChallenge: challengeContent.isAdminChallenge,
        challengeName: challengeContent.challengeName,
      },
    };

    setPlacingPrediction(true);
    try {
      await postChallengeAll(body);
      navigate(0);
    } catch (err) {
      console.error('Error adding challenge.', err);
    }
    setPlacingPrediction(false);
  }, [challengeContent, predictionInfo, lhsPredInfo, rhsPredInfo]);

  return (
    <div className="add-prediction-container">
      <h2 style={{ padding: '0px 12px' }}>Add Prediction: {type}</h2>

      <h3 style={{ margin: '0px' }}>Challenge Content</h3>
      <div className="type-specific-container">
        {/* NOTE: Using a ternary here while this is only accessible from the challenge all link. */}
        {type == 'ChallengeAll' ? (
          <ChallengeInputDetails
            challengeContent={challengeContent}
            setChallengeContent={setChallengeContent}
          />
        ) : null}
      </div>
      <hr style={{ width: '99%' }} />
      <h3 style={{ margin: '0px' }}>Prediction Content</h3>
      <div className="template-category-choices">
        <CategorySelector category={category} setCategory={setCategory} />
        {category.value.id == 'comparison' ? (
          <div className="comparison-duration-container">
            <div className="comparison-duration-selector">
              <p>Prediction 1:</p>
              <DurationSelector
                duration={lhsDuration}
                setDuration={setLhsDuration}
              />
            </div>
            <div className="comparison-duration-selector">
              <p>Prediction 2:</p>
              <DurationSelector
                duration={rhsDuration}
                setDuration={setRhsDuration}
              />
            </div>
          </div>
        ) : null}
        {category.value.id != 'future' && category.value.id != 'comparison' ? (
          <DurationSelector duration={duration} setDuration={setDuration} />
        ) : null}
      </div>
      <div>
        <LeagueSelector league={league} setLeague={setLeague} />
      </div>
      <AddPredictionWizard
        predictionInfo={predictionInfo}
        setPredictionInfo={setPredictionInfo}
        lhsPredictionInfo={lhsPredInfo}
        setLhsPredictionInfo={setLhsPredInfo}
        rhsPredictionInfo={rhsPredInfo}
        setRhsPredictionInfo={setRhsPredInfo}
      />
      <button onClick={submitChallenge} disabled={placingPrediction}>
        Submit
      </button>
    </div>
  );
};
