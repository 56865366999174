import './style.css';

export type ButtonProps = {
  accessibilityLabel: string;
  type: 'primary' | 'secondary' | 'tertiary';
  text: string;
  handleOnClick?: () => void;
  formName?: string;
  disabled?: boolean;
  loading?: boolean;
};

export const Button = ({
  accessibilityLabel,
  type,
  text,
  handleOnClick,
  formName,
  disabled,
  loading,
}: ButtonProps) => {
  const buttonClassName = `${type}-button`;
  const textClassName = disabled
    ? `${type}-button-text-disabled`
    : `${type}-button-text`;

  return (
    <button
      id={accessibilityLabel}
      className={buttonClassName}
      aria-label={accessibilityLabel}
      onClick={handleOnClick}
      disabled={disabled || loading}
      type={formName ? 'submit' : 'button'}
      form={formName}
    >
      <div className={textClassName}>{text}</div>
    </button>
  );
};
