import { type Dispatch, type SetStateAction } from 'react';

import { ComparisonSelector } from './components/steps/ComparisonSelector';
import { EntitySelector } from './components/steps/EntitySelector';
import { GameSelector } from './components/steps/GameSelector';
import { MetricSelector } from './components/steps/MetricSelector';
import { SeasonSelector } from './components/steps/SeasonSelector';
import { ValueSelector } from './components/steps/ValueSelector';
import { type ChallengeAllPrediction, type PredInfo } from './types';
import { type CreateComparisonPrediction } from 'components/AddPredictionForm/types';
import { type ComparisonOption, type MetricOption } from 'types/addPrediction';
import {
  type PredictionContent,
  type PredictionGrouping,
} from 'types/prediction';
import { type Entity, type Game, type Team } from 'types/sportsdata';
import {
  getComparisonPredictionContent,
  getFuturePredContent,
  getGameDetails,
  getGameOutcomePredContent,
  getPredictionGrouping,
  getPredictionType,
  getSeasonDetails,
  getSeasonOutcomePredContent,
  getStatPredContent,
} from 'utils/addPred';

export const getPlaceholderStrings = (grouping: PredictionGrouping) => {
  let entityPlaceholder;
  let comparisonPlaceholder;
  let valuePlaceholder;
  let metricPlaceholder;
  let durationPlaceholder;
  switch (grouping) {
    case 'GameStatistic':
      entityPlaceholder = 'Player/Team';
      comparisonPlaceholder = 'comparison';
      valuePlaceholder = 'value';
      metricPlaceholder = 'stat';
      durationPlaceholder = 'date of game';
      break;
    case 'SeasonStatistic':
      entityPlaceholder = 'Player/Team';
      comparisonPlaceholder = 'comparison';
      valuePlaceholder = 'value';
      metricPlaceholder = 'stat';
      durationPlaceholder = 'season';
      break;
    case 'GameOutcome':
      entityPlaceholder = 'Team';
      comparisonPlaceholder = 'comparison';
      valuePlaceholder = 'value';
      metricPlaceholder = 'outcome';
      durationPlaceholder = 'date of game';
      break;
    case 'SeasonOutcome':
      entityPlaceholder = 'Team';
      comparisonPlaceholder = 'comparison';
      valuePlaceholder = 'value';
      metricPlaceholder = 'outcome';
      durationPlaceholder = 'season';
      break;
    case 'SeasonFuture':
      entityPlaceholder = 'Player/Team';
      metricPlaceholder = 'future';
      durationPlaceholder = 'season';
      break;
    case 'Comparison':
      comparisonPlaceholder = 'comparison';
  }

  return {
    entityPlaceholder,
    comparisonPlaceholder,
    valuePlaceholder,
    metricPlaceholder,
    durationPlaceholder,
  };
};

export const getChallengeAllPred = (
  predInfo: PredInfo,
  lhsPredInfo: PredInfo,
  rhsPredInfo: PredInfo
): ChallengeAllPrediction => {
  const { category, duration, entity, game, league, season } = predInfo;
  const pred: ChallengeAllPrediction = {
    predictionType: getPredictionType(category, duration, entity as Entity),
    league: league,
    gameDetails: game ? getGameDetails(game) : undefined,
    seasonDetails: season ? getSeasonDetails(season) : undefined,
    content: getPredContent(predInfo, lhsPredInfo, rhsPredInfo),
  };

  if (duration === 'season' || category === 'future') {
    delete pred.gameDetails;
  } else if (category === 'comparison') {
    delete pred.gameDetails;
    delete pred.seasonDetails;
  } else {
    delete pred.seasonDetails;
  }

  return pred;
};

export const getPredContent = (
  predInfo: PredInfo,
  lhsPredInfo: PredInfo,
  rhsPredInfo: PredInfo
): PredictionContent => {
  const {
    category,
    comparison,
    duration,
    entity,
    game,
    league,
    leagueTeams,
    metric,
    season,
    value,
  } = predInfo;
  const predType = getPredictionType(category, duration, entity as Entity);
  const comparisonPred = {
    predictions: {
      '1': {
        duration: {
          id: lhsPredInfo.duration,
        },
        entity: lhsPredInfo.entity,
        metric: lhsPredInfo.metric,
        game: lhsPredInfo.game,
        season: lhsPredInfo.season,
      },
      '2': {
        duration: {
          id: rhsPredInfo.duration,
        },
        entity: rhsPredInfo.entity,
        metric: rhsPredInfo.metric,
        game: rhsPredInfo.game,
        season: rhsPredInfo.season,
      },
    },
  } as CreateComparisonPrediction;

  switch (predType) {
    case 'PlayerGameStatistic':
    case 'TeamGameStatistic':
      return getStatPredContent({
        entity: entity as Entity,
        metric: metric as MetricOption,
        comparison: (comparison as ComparisonOption).value,
        value: value || '',
        leagueTeams: leagueTeams || [],
      });
    case 'PlayerSeasonStatistic':
    case 'TeamSeasonStatistic':
      return getStatPredContent({
        entity: entity as Entity,
        metric: metric as MetricOption,
        comparison: (comparison as ComparisonOption).value,
        value: value || '',
        leagueTeams: leagueTeams || [],
      });
    case 'TeamGameOutcome':
      return getGameOutcomePredContent({
        team: entity as Team,
        metric: metric as MetricOption,
        value: value || '',
        game: game as Game,
        leagueTeams: leagueTeams || [],
      });

    case 'TeamSeasonOutcome':
      return getSeasonOutcomePredContent({
        team: entity as Team,
        metric: metric as MetricOption,
        comparison: (comparison as ComparisonOption).value,
        value: value || '',
      });
    case 'PlayerSeasonFuture':
    case 'TeamSeasonFuture':
      return getFuturePredContent({
        entity: entity as Entity,
        metric: metric as MetricOption,
        seasonId: season?.seasonId || '',
        league,
        leagueTeams: leagueTeams || [],
      });
    case 'Comparison':
      return getComparisonPredictionContent({
        comparisonPrediction: comparisonPred,
        comparison: (comparison as ComparisonOption).value,
        league,
        leagueTeams: lhsPredInfo.leagueTeams || [],
      });
  }
};

export const getWizardSteps = (
  predContent: PredInfo,
  setPredContent: Dispatch<SetStateAction<PredInfo>>,
  comp?: string
) => {
  const GAME_STAT_STEPS = [
    <EntitySelector
      key="game_stat_entity"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <MetricSelector
      key="game_stat_metric"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <ComparisonSelector
      key="game_stat_comparison"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <ValueSelector
      key="game_stat_value"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <GameSelector
      key="game_stat_game"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
  ];

  const SEASON_STAT_STEPS = [
    <EntitySelector
      key="season_stat_entity"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <MetricSelector
      key="season_stat_metric"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <ComparisonSelector
      key="season_stat_comparison"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <ValueSelector
      key="season_stat_value"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <SeasonSelector
      key="season_stat_season"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
  ];

  const GAME_OUTCOME_STEPS =
    predContent.metric?.value == 'spread'
      ? [
          <EntitySelector
            key="game_outcome_entity"
            predContent={predContent}
            setPredContent={setPredContent}
          />,
          <MetricSelector
            key="game_outcome_metric"
            predContent={predContent}
            setPredContent={setPredContent}
          />,
          <ValueSelector
            key="game_outcome_value"
            predContent={predContent}
            setPredContent={setPredContent}
          />,
          <GameSelector
            key="game_outcome_season"
            predContent={predContent}
            setPredContent={setPredContent}
          />,
        ]
      : [
          <EntitySelector
            key="game_outcome_entity"
            predContent={predContent}
            setPredContent={setPredContent}
          />,
          <MetricSelector
            key="game_outcome_metric"
            predContent={predContent}
            setPredContent={setPredContent}
          />,
          <GameSelector
            key="game_outcome_season"
            predContent={predContent}
            setPredContent={setPredContent}
          />,
        ];

  const SEASON_OUTCOME_STEPS = [
    <EntitySelector
      key="season_outcome_entity"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <MetricSelector
      key="season_outcome_metric"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <ComparisonSelector
      key="season_outcome_comp_"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <ValueSelector
      key="season_outcome_value"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <SeasonSelector
      key="season_outcome_season"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
  ];

  const SEASON_FUTURE_STEPS = [
    <EntitySelector
      key="season_future_entity"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <MetricSelector
      key="season_outcome_metric"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <SeasonSelector
      key="season_outcome_season"
      predContent={predContent}
      setPredContent={setPredContent}
    />,
  ];

  const GAME_STAT_COMPARE_STEPS = [
    <EntitySelector
      key={`game_stat_entity_${comp}`}
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <MetricSelector
      key={`game_stat_metric_${comp}`}
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <GameSelector
      key={`game_stat_game_${comp}`}
      predContent={predContent}
      setPredContent={setPredContent}
    />,
  ];

  const SEASON_STAT_COMPARE_STEPS = [
    <EntitySelector
      key={`season_stat_entity_${comp}`}
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <MetricSelector
      key={`season_stat_metric_${comp}`}
      predContent={predContent}
      setPredContent={setPredContent}
    />,
    <SeasonSelector
      key={`season_stat_season_${comp}`}
      predContent={predContent}
      setPredContent={setPredContent}
    />,
  ];

  switch (getPredictionGrouping(predContent.category, predContent.duration)) {
    case 'GameStatistic':
      return comp ? GAME_STAT_COMPARE_STEPS : GAME_STAT_STEPS;
    case 'SeasonStatistic':
      return comp ? SEASON_STAT_COMPARE_STEPS : SEASON_STAT_STEPS;
    case 'GameOutcome':
      return GAME_OUTCOME_STEPS;
    case 'SeasonOutcome':
      return SEASON_OUTCOME_STEPS;
    case 'SeasonFuture':
      return SEASON_FUTURE_STEPS;
    default:
      return [
        <div key="default">
          If you see this... message the engineering team
        </div>,
      ];
  }
};
