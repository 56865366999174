import axios from 'axios';

import { getApiUrls } from 'config/api';
import { type PostPool, type PostPoolTemplate } from 'types/pools';

const { ADMIN_API_URL, API_URL } = getApiUrls();

export const postPool = async (body: PostPool) => {
  const { data } = await axios.post(`${ADMIN_API_URL}/admin/pool`, body);
  return data;
};

export const postPoolTemplate = async (body: PostPoolTemplate) => {
  const { data } = await axios.post(
    `${ADMIN_API_URL}/admin/poolTemplate`,
    body
  );
  return data;
};

export const getPools = async (status?: string, pageParam = '') => {
  let url = `${API_URL}/pools?queryLimit=50`;
  if (status) {
    url = url + `&status=${status}`;
  }
  if (pageParam) {
    url = url + `&cursor=${pageParam}`;
  }
  const { data } = await axios.get(url);
  return data.object;
};

export const getPoolTemplates = async (status?: string) => {
  const url = `${API_URL}/pools/templates?status=${status}`;
  const { data } = await axios.get(url);
  return data.object;
};

export const getPool = async (poolIdentifier: string) => {
  const encodedPoolIdentifier = encodeURIComponent(poolIdentifier);
  const url = `${API_URL}/pools/${encodedPoolIdentifier}`;
  const { data } = await axios.get(url);
  return data.object;
};

export const getPoolTemplate = async (poolTemplateIdentifier: string) => {
  const encodedPoolTemplateIdentifier = encodeURIComponent(
    poolTemplateIdentifier
  );
  const url = `${API_URL}/pools/template/${encodedPoolTemplateIdentifier}`;
  const { data } = await axios.get(url);
  return data.object;
};

export const getPoolParticipantAvg = async (userId: string) => {
  const url = `${ADMIN_API_URL}/admin/participantAvg/authorId/${userId}`;
  const { data } = await axios.get(url);
  return data.object;
};

export const deletePool = async (poolId: string) => {
  const url = `${ADMIN_API_URL}/admin/pool/${poolId}`;
  const { data } = await axios.delete(url);
  return data;
};
