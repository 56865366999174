import {
  keepPreviousData,
  useInfiniteQuery,
  type InfiniteData,
  type QueryKey,
} from '@tanstack/react-query';
import { getPools } from 'api/pools';
import { type PoolsResponse, type PoolStatus } from 'types/pools';

export default function usePools(poolStatus?: PoolStatus) {
  const queryResponse = useInfiniteQuery<
    PoolsResponse,
    Error,
    InfiniteData<PoolsResponse>,
    QueryKey,
    string | undefined
  >({
    queryKey: ['pools'],
    queryFn: async ({ pageParam }) => await getPools(poolStatus, pageParam),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage?.cursor;
    },
    placeholderData: keepPreviousData,
  });

  if (queryResponse.isError) {
    console.error('Error getting pools: ', queryResponse.error);
  }

  return {
    pools: queryResponse.data?.pages,
    poolsLoading: queryResponse.isLoading,
    poolsError: queryResponse.isError,
    refetchPools: queryResponse.refetch,
    hasNextPage: queryResponse.hasNextPage,
    fetchNextPage: queryResponse.fetchNextPage,
    isFetching: queryResponse.isFetching,
    isFetchingNextPage: queryResponse.isFetchingNextPage,
  };
}
