import { useEffect, useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { PoolPreviewCard } from './components/PoolPreviewCard';
import { POOL_STATUS_OPTIONS } from './constants/options';
import { Button } from 'components/Button';
import { FullScreenSpinner } from 'components/Spinner';
import usePools from 'hooks/usePools';

export const Pools = () => {
  // defaults to show current Open pools first
  const [poolStatus, setPoolStatus] = useState(POOL_STATUS_OPTIONS[0]);

  const navigate = useNavigate();
  const {
    pools,
    poolsLoading,
    poolsError,
    refetchPools,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = usePools(poolStatus.value.id);

  useEffect(() => {
    refetchPools();
  }, [poolStatus.value.id]);

  if (poolsLoading || isFetching || isFetchingNextPage) {
    return <FullScreenSpinner />;
  }

  if (!poolsError) {
    return (
      <div className="view-pools-container">
        <div style={{ display: 'flex' }}>
          <Button
            accessibilityLabel="add-pool-button"
            type="primary"
            text="Add Pool"
            handleOnClick={() => navigate('/addPool')}
          />
        </div>
        <h2 className="view-pools-text">View Pools</h2>
        <div>
          <label>
            <input
              type="radio"
              value={POOL_STATUS_OPTIONS[0].label}
              checked={poolStatus == POOL_STATUS_OPTIONS[0]}
              onChange={() => {
                setPoolStatus(POOL_STATUS_OPTIONS[0]);
              }}
            />
            Open Pools
          </label>
          <label>
            <input
              type="radio"
              value={POOL_STATUS_OPTIONS[1].label}
              checked={poolStatus == POOL_STATUS_OPTIONS[1]}
              onChange={() => {
                setPoolStatus(POOL_STATUS_OPTIONS[1]);
              }}
            />
            Upcoming Pools
          </label>
          <label>
            <input
              type="radio"
              value={POOL_STATUS_OPTIONS[2].label}
              checked={poolStatus == POOL_STATUS_OPTIONS[2]}
              onChange={() => {
                setPoolStatus(POOL_STATUS_OPTIONS[2]);
              }}
            />
            Closed Pools
          </label>
          <label>
            <input
              type="radio"
              value={POOL_STATUS_OPTIONS[3].label}
              checked={poolStatus == POOL_STATUS_OPTIONS[3]}
              onChange={() => {
                setPoolStatus(POOL_STATUS_OPTIONS[3]);
              }}
            />
            Finished Pools
          </label>
          <label>
            <input
              type="radio"
              value={POOL_STATUS_OPTIONS[4].label}
              checked={poolStatus == POOL_STATUS_OPTIONS[4]}
              onChange={() => {
                setPoolStatus(POOL_STATUS_OPTIONS[4]);
              }}
            />
            Hidden Open Pools
          </label>
        </div>
        {pools?.map((group, index) => (
          <div key={index}>
            {group?.pools.length == 0 ? (
              <div> No Pools to display.</div>
            ) : (
              group?.pools.map((pool) => (
                <PoolPreviewCard key={pool.poolId} pool={pool} />
              ))
            )}
          </div>
        ))}
        {hasNextPage && (
          <Button
            accessibilityLabel="load more pools"
            type="primary"
            text="Load More"
            handleOnClick={fetchNextPage}
          />
        )}
      </div>
    );
  } else {
    return <p>Error Loading Pools.</p>;
  }
};

export default Pools;
