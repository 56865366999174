import { useQuery } from '@tanstack/react-query';
import { getPoolTemplates } from 'api/pools';
import { type PoolTemplatesResponse } from 'types/pools';

export default function usePoolTemplates(status: string) {
  const queryResponse = useQuery<PoolTemplatesResponse>({
    queryKey: ['poolTemplates', status],
    queryFn: async () => await getPoolTemplates(status),
  });

  if (queryResponse.isError) {
    console.error('Error getting pool templates: ', queryResponse.error);
  }

  return {
    poolTemplates: queryResponse.data,
    poolTemplatesLoading: queryResponse.isLoading,
    poolTemplatesError: queryResponse.isError,
    refetchPoolTemplates: queryResponse.refetch,
    isFetchingPoolTemplates: queryResponse.isFetching,
  };
}
